import * as React from 'react';
import { FeatureTableProps } from './interface';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { Icon } from '../../../components';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import './styles.less';

const FeatureTable: React.FC<FeatureTableProps> = ({ features, planType }) => {
  const { t } = useTranslation();
  const { isTablet, isMobile } = useMediaQuery();

  const indicateAvailability = (featureInPlan: string, isNonInclusive: boolean) => {
    if (!featureInPlan) {
      return <Icon name="IcoDash" />;
    }

    if (isNonInclusive) {
      return <Icon name="IcoDollarSignSolid" />;
    }

    return <Icon name="IcoCircleCheckSolid" />;
  };

  const getRows = (features) => {
    return features.map(({ name, basic, team, business, isNonInclusive }, key) => {
      return {
        key: key,
        name: t(name),
        basic: indicateAvailability(basic, isNonInclusive),
        team: indicateAvailability(team, isNonInclusive),
        business: indicateAvailability(business, isNonInclusive),
      };
    });
  };

  const getColumns = (title) => {
    if (isTablet || isMobile) {
      return [
        {
          title: title,
          dataIndex: planType,
          width: '5%',
          className: 'default',
          colSpan: 2,
          align: 'left',
        },
        {
          dataIndex: 'name',
          className: 'feature-name',
        },
      ];
    }

    return [
      {
        title: title,
        dataIndex: 'name',
        width: '20%',
        className: 'feature-name',
      },
      {
        dataIndex: 'basic',
        width: '30%',
        className: 'default',
      },
      {
        dataIndex: 'team',
        width: '25%',
        className: 'default',
      },
      {
        dataIndex: 'business',
        width: '30%',
        className: 'emphasis',
      },
    ];
  };

  const allPlanFeaturesTabular = Object.keys(features).map((featureGroupName, key) => {
    const tableColumn = getColumns(t('settings.plan.features.' + featureGroupName));
    const tableRows = getRows(features[featureGroupName]);
    // TODO: remove the ts-ignore and fix the issue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Table key={key} columns={tableColumn} dataSource={tableRows} pagination={false} />;
  });

  return <>{allPlanFeaturesTabular}</>;
};

export default FeatureTable;
