import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Fab } from '@mui/material';
import Add from '../../../../components/icon/icons/IcoAdd';
import { CreateAssetModal } from '../create-asset-modal';
import './styles.less';

export function CreateAssetButton() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Fab className="create-asset-button" variant="extended" onClick={handleOpen} data-testid="create-asset-button" disableRipple>
        <Add />
        {t('library.new_asset_button')}
      </Fab>
      <CreateAssetModal open={open} handleClose={handleClose} />
    </>
  );
}
