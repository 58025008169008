import React, { useCallback, useState, useEffect, useContext } from 'react';
import { AcknowledgmentResponseStatus, ErrorCode } from 'services/socket/SocketEvents';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { SignatureBox, UnSignedSignatureBox, SignatureStatus } from 'services/repositories/interfaces/SignatureRepository';
import UnSignedSignature from 'components/editor/GridDndEditor/Block/Signature/UnsignedSignatureBlock';
import SignedSignature from 'components/editor/GridDndEditor/Block/Signature/SignedSignatureBlock';
import { SaveStatusContext } from './SaveStatusProvider';
import { useSocketClient } from '../../../providers/SocketContext';

export type SignatureSocketWriteOperationPayload = UnSignedSignatureBox;

type HandleSignatureSocketWriteOperation = (params: SignatureSocketWriteOperationPayload) => void;
interface SignatureContextType {
  documentId: string;
  signatures?: SignatureBox[];
  handleSignatureInsert: HandleSignatureSocketWriteOperation;
  handleSignatureRemoval: (signatureId: string) => void;
  handleSignaturePropertyUpdate: HandleSignatureSocketWriteOperation;
  setSignaturesOnMount: (signatures: SignatureBox[]) => void;
  setSelectedSignature: (signatureId: string) => void;
  getSignaturesList: (isGridEnabled: boolean) => React.ReactNode[];
}

export const SignaturesContext = React.createContext<SignatureContextType>({} as SignatureContextType);

interface SignaturesProviderProps {
  documentId: string;
  children: React.ReactNode;
}

export const SignaturesProvider = ({ documentId, children }: SignaturesProviderProps) => {
  const socketClient = useSocketClient();
  const [signatures, setSignatures] = useState<SignatureBox[]>([]);
  const [selectedSignature, setSelectedSignature] = useState<string>('');
  const { setSaveStatus } = useContext(SaveStatusContext);

  const signatureActionAck = ({ status, errorCode }: { status: AcknowledgmentResponseStatus; errorCode: ErrorCode }) => {
    if (status === AcknowledgmentResponseStatus.OK) {
      setSaveStatus(DocumentSaveStatus.SAVED);
    } else {
      setSaveStatus(DocumentSaveStatus.NOT_SAVED, errorCode);
    }
  };

  const handleSignatureInsert = useCallback(
    (signature: UnSignedSignatureBox) => {
      setSaveStatus(DocumentSaveStatus.SAVING);
      socketClient.addSignatureContent({ ...signature }, (response) => {
        signatureActionAck(response);

        setSignatures((prevSignatures) => {
          const { content } = response;

          if (content) signature.signatureBoxId = content.id;

          return [...prevSignatures, { ...signature }];
        });
      });
    },
    [signatures]
  );

  const handleSignatureRemoval = useCallback(
    (signatureId: string) => {
      setSignatures((prevSignatures) => {
        return prevSignatures.filter((signature) => {
          if (signature.signatureBoxId === signatureId) {
            setSaveStatus(DocumentSaveStatus.SAVING);
            socketClient.deleteSignatureContent(signature, (response) => signatureActionAck(response));
          }
          return signature.signatureBoxId !== signatureId;
        });
      });
    },
    [signatures]
  );

  const handleSignaturePropertyUpdate = useCallback(
    (newSignature: UnSignedSignatureBox) => {
      socketClient.updateSignatureContent(newSignature, (response) => signatureActionAck(response));
      setSignatures((prevSignatures) => {
        prevSignatures.map((signature) => {
          if (signature.signatureBoxId === newSignature.signatureBoxId) {
            setSaveStatus(DocumentSaveStatus.SAVING);
            Object.assign(signature, newSignature);
          }
        });
        return prevSignatures;
      });
    },
    [signatures]
  );

  const setSignaturesOnMount = useCallback((data: SignatureBox[]) => {
    setSignatures(data);
  }, []);

  const handleMouseDown = useCallback(() => {
    setSelectedSignature('');
  }, []);

  const handleHotKeys = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;
      if (key === 'Delete' || key === 'Backspace') {
        handleSignatureRemoval(selectedSignature);
      }
    },
    [handleSignatureRemoval, selectedSignature]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleHotKeys);
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('keydown', handleHotKeys);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown, handleHotKeys]);

  const getSignaturesList = useCallback(
    (isGridEnabled: boolean) => {
      const bounds = isGridEnabled ? '.editor__page' : '.fr-box.fr-basic';
      return (signatures ?? []).map(({ signatureBoxId, properties, ...rest }) => {
        return rest.status === SignatureStatus.Signed ? (
          <SignedSignature
            signee={rest.signatureEvent}
            position={properties.position}
            dimensions={properties.dimensions}
            signatureId={signatureBoxId}
            key={signatureBoxId}
            signedDate={rest.signatureEvent.signedDate}
            bounds={bounds}
          />
        ) : (
          <UnSignedSignature
            assignedSignee={rest.assignedSignee}
            position={properties.position}
            dimensions={properties.dimensions}
            signatureId={signatureBoxId}
            key={signatureBoxId}
            handleClick={() => setSelectedSignature(signatureBoxId)}
            bounds={bounds}
          />
        );
      });
    },
    [signatures]
  );

  return (
    <SignaturesContext.Provider
      value={{
        documentId,
        signatures,
        handleSignatureInsert,
        handleSignatureRemoval,
        handleSignaturePropertyUpdate,
        setSignaturesOnMount,
        setSelectedSignature,
        getSignaturesList,
      }}
    >
      {children}
    </SignaturesContext.Provider>
  );
};
