export const defaultRowMeta = {
  __reorder__: 'Row',
};
export const defaultColumnMeta = {
  editable: true,
  headerName: 'Text',
  width: 333,
};

export const initialColumnWidth = 333;

export const cellFocusOutEventKeys = ['Enter', 'Tab'];
