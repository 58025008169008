import { io, Socket, SocketOptions, ManagerOptions } from 'socket.io-client';
import SignatureSocketClient from './SignatureSocketClient';
import { BlockEvents, DocumentSettingsEvents, SignatureEvents } from './SocketEvents';
import { SignatureBox } from 'services/repositories/interfaces/SignatureRepository';
import { SignatureBoxApiResponse } from 'services/repositories/interfaces/SignatureRepository';

import { transformSignatureBoxApiResponse } from 'services/repositories/implementations/ApiSignatureRepository';
import DocumentSettingsSocketClient from './DocumentSettingsSocketClient';
import { DocumentSettingsType } from '../../components/editor/SidePanel/document-settings/DocumentDesignSettings/DocumentSettingsContext';

type AuthenticationObject = {
  [key: string]: any;
};

type GetAllSignaturesOnMountCallback = (data: SignatureBox[]) => void;

export type TSocketCallback = (data: any) => void;

type Events = DocumentSettingsEvents | SignatureEvents | BlockEvents;
class SocketClient {
  #signatureSocketClient: SignatureSocketClient;
  #documentSettingsClient: DocumentSettingsSocketClient;
  readonly #socketClient: Socket;

  #connectionParams: Partial<ManagerOptions & SocketOptions> = {
    autoConnect: false,
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 5,
    auth: {},
    transports: ['websocket'],
  };
  constructor(serverURL: string, authParams: AuthenticationObject) {
    this.#connectionParams.auth = authParams;
    this.#socketClient = io(serverURL, this.#connectionParams);
    this.#signatureSocketClient = new SignatureSocketClient(this);
    this.#documentSettingsClient = new DocumentSettingsSocketClient(this);
  }

  public isConnected(): boolean {
    return this.#socketClient.connected;
  }

  public connect(): void {
    this.#socketClient.connect();
  }

  public disconnect() {
    this.#socketClient.disconnect();
  }

  public getAllSignaturesOnMount(callback: GetAllSignaturesOnMountCallback) {
    this.subscribeOnce(SignatureEvents.SIGNATURE_GET_ALL, (response: SignatureBoxApiResponse[] = []) => {
      const data = response.map((signature) => {
        return transformSignatureBoxApiResponse(signature);
      });
      callback(data);
    });
  }

  public subscribe(event: Events, callback: any): SocketClient {
    this.#socketClient.on(event, callback);
    return this;
  }

  private subscribeOnce(event: Events, callback: any): SocketClient {
    this.#socketClient.once(event, callback);
    return this;
  }

  public publish(event: Events, data: any, callback: (resp: any) => any): SocketClient {
    this.#socketClient.emit(event, data, callback);
    return this;
  }

  public addSignatureContent(data: SignatureBox, callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent(data, SignatureEvents.SIGNATURE_ADD, callback);
  }

  public updateSignatureContent(data: SignatureBox, callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent(data, SignatureEvents.SIGNATURE_UPDATE, callback);
  }

  public deleteSignatureContent(data: SignatureBox, callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent(data, SignatureEvents.SIGNATURE_DELETE, callback);
  }

  public loadDocumentSettings(callback: TSocketCallback) {
    this.#documentSettingsClient.getDocumentSettings('', DocumentSettingsEvents.DOCUMENT_SETTINGS_LOAD, callback);
  }

  public saveDocumentSettings(data: DocumentSettingsType, callback: TSocketCallback) {
    this.#documentSettingsClient.getDocumentSettings(data, DocumentSettingsEvents.DOCUMENT_SETTINGS_SAVE, callback);
  }
}

export default SocketClient;
