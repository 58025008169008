import { Endpoints } from 'utils/endpoints';
import { apiClient } from '../../../api';

class ApiClientRepositoryClass {
  public async getAPIClients(userId: number) {
    return await apiClient.get(Endpoints.getAPIClients(userId));
  }
}

export const ApiClientRepository = new ApiClientRepositoryClass();
