import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DraggableGridBlockWrapper } from 'components/editor/GridDndEditor/DraggableGridBlock/DraggableGridBlockWrapper';
import { BlockStyleSettingsContext } from '../../../../SidePanel/content/designSettings/AdvancedSpacing/BlockSettingsProvider';

import ImageComponent from './ImageComponent';
import { RootState } from '../../../../grid/reduxStore/Store';
import { ImageBlockSettings } from '../../../../grid/reduxStore/saveHandlers';
import { SelectionContext } from 'components/editor/GridDndEditor/SelectedBlockInfoProvider';
import { SidePanelProviderContext } from 'components/editor/SidePanel/content';

import './GridImageBlock.less';

interface GridImageBlockProps {
  blockId: string;
}

export const GridImageBlock = ({ blockId }: GridImageBlockProps) => {
  const blockContent = useSelector((state: RootState) => state.gridBlockReducer.blocksContent[blockId]);
  const { getBlockStyle } = useContext(BlockStyleSettingsContext);
  const { setSelectedBlockIdByWrapper, selectedBlockIdByIcon } = useContext(SelectionContext);
  const { resetAllPanels } = useContext(SidePanelProviderContext);

  const {
    paddingLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    borderColor,
    borderLeft,
    borderRight,
    borderTop,
    borderBottom,
    borderRadius,
    imageAlt,
    opacity,
  } = getBlockStyle(blockId) as unknown as ImageBlockSettings;

  const handleOnMouseDown = (blockId) => {
    if (blockId !== selectedBlockIdByIcon) {
      setSelectedBlockIdByWrapper(blockId);
      resetAllPanels();
    }
  };
  return (
    <DraggableGridBlockWrapper
      key={`grid-image-${blockId}`}
      data-testid={`grid-image-${blockId}`}
      blockId={blockId}
      lockAspectRatio
      onMouseDown={(_, blockId) => handleOnMouseDown(blockId)}
    >
      <ImageComponent
        style={{
          borderTop: `solid ${borderColor} ${borderTop}px`,
          borderBottom: `solid ${borderColor} ${borderBottom}px`,
          borderLeft: `solid ${borderColor} ${borderLeft}px`,
          borderRight: `solid ${borderColor} ${borderRight}px`,
          borderRadius: `${borderRadius}px`,
          paddingLeft: `${paddingLeft}px`,
          paddingTop: `${paddingTop}px`,
          paddingRight: `${paddingRight}px`,
          paddingBottom: `${paddingBottom}px`,
        }}
        currentBlockId={blockId}
        src={blockContent.content}
        imageAlt={imageAlt}
        opacity={opacity}
      />
    </DraggableGridBlockWrapper>
  );
};
