import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_PAGE_HEIGHT } from 'components/editor/pagebreaks/useHandlePageBreaks';
import './styles.less';

interface PageBreaksProps {
  pageBreaks: number;
  leftPosition: number;
}

const PageBreaks: React.FC<PageBreaksProps> = ({ leftPosition, pageBreaks }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {[...Array(pageBreaks)].map((_, index) => {
        const pageNumber = index + 1;

        return (
          <div
            key={index}
            data-testid="pdf-page-break"
            className="pdf-page-break"
            style={{ top: MAX_PAGE_HEIGHT * pageNumber, left: leftPosition }}
          >
            {t('editor.pdf_page_break')}
          </div>
        );
      })}
    </Fragment>
  );
};

export default PageBreaks;
