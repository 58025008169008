import React from 'react';
import { Rnd } from 'react-rnd';
import { useTranslation } from 'react-i18next';

import { dimensions as defaultDimensions, position as defaultPositions } from '../../../signatures/constants';
import { Dimensions, Position } from '../../../shared/interfaces';
import { getSignatureColor, SignatureBaseButton } from '../../../shared/components/SignatureBaseButton';
import { AssignedSignee } from 'services/repositories/interfaces/SignatureRepository';

import './Signature.less';

export interface PreviewSignatureProps {
  signatureId: string;
  assignedSignee?: AssignedSignee | null;
  handleClick?: () => void;
  position?: Position;
  dimensions?: Dimensions;
  bounds?: string;
}

const PreviewUnsignedSignature: React.FunctionComponent<PreviewSignatureProps> = ({
  signatureId,
  assignedSignee = null,
  position = defaultPositions,
  dimensions = defaultDimensions,
  handleClick,
  bounds = '.fr-box.fr-basic',
}) => {
  const { t } = useTranslation();
  const signee = assignedSignee;
  const defaultConfig = { ...position, ...dimensions };

  return (
    <Rnd
      className="unsigned_signature"
      bounds={bounds}
      default={defaultConfig}
      disableDragging
      lockAspectRatio
      minHeight={defaultDimensions.height}
      minWidth={defaultDimensions.width}
      onClick={handleClick}
    >
      <div data-testid="unsigned_signature_draggable" />
      <SignatureBaseButton color={getSignatureColor(signee?.email)} data-signature-id={signatureId}>
        {t('signature')}
      </SignatureBaseButton>
    </Rnd>
  );
};

export default PreviewUnsignedSignature;
