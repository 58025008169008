import { UsedVariable } from '../components/editor/SidePanel/Variables/interfaces';
export function useUsedVariables() {
  const handleUsedVariablesParsing = (
    documentContent: string,
    handleUsedVariablesParsingCallback: (usedVariables: UsedVariable[]) => void
  ) => {
    const getUsedVariablesWorker: Worker = new Worker(
      new URL('../components/editor/SidePanel/Variables/getUsedVariablesWorker.ts', import.meta.url)
    );
    const message = {
      htmlContent: documentContent,
    };

    getUsedVariablesWorker.postMessage(message);
    getUsedVariablesWorker.onmessage = (event) => {
      if (!event.data) {
        handleUsedVariablesParsingCallback([]);
      } else {
        handleUsedVariablesParsingCallback(event.data.usedVariables);
      }
    };
  };

  return { handleUsedVariablesParsing };
}
