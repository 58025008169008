import { SignatureEvents } from './SocketEvents';
import SocketClient, { TSocketCallback } from './SocketClient';
import { SignatureBox } from 'services/repositories/interfaces/SignatureRepository';

export default class SignatureSocketClient {
  private socketClient: SocketClient;
  constructor(socketClient: SocketClient) {
    this.socketClient = socketClient;
  }
  public publishSignatureContent(data: SignatureBox, event: SignatureEvents, callback: TSocketCallback) {
    this.socketClient.publish(event, data, callback);
  }
}
