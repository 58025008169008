import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { GridBlockType } from '../shared/gridBlockType';
import { RootState } from '../grid/reduxStore/Store';
import { SelectionContext } from '../GridDndEditor/SelectedBlockInfoProvider';

export function EditorFroalaToolbar() {
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);
  const blocksContent = useSelector((state: RootState) => state.gridBlockReducer.blocksContent);
  const blockId = selectedBlockIdByWrapper as string;
  const isNotOnlyTextBlockPresent = Object.keys(blocksContent).some((key) => blocksContent[key].type !== GridBlockType.TEXT);
  const selectedBlockType = blockId ? blocksContent[blockId]?.type : null;
  const isSelectedBlockText = selectedBlockType === GridBlockType.TEXT;

  const shouldShowToolbar = !isNotOnlyTextBlockPresent || isSelectedBlockText ? 'active' : 'hidden';

  return <div id="editor-froala-toolbar" style={{ width: '100%' }} className={`editor-froala-toolbar ${shouldShowToolbar}`} />;
}
