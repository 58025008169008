import { Asset } from 'services/library/entities/Asset';
import { contentLibraryClient } from 'services/api';

interface AssetListApiResponse {
  assets: Asset[];
}

interface CreateRequest {
  type: 'TEMPLATE';
  content_id: string;
}

export const apiContentLibraryRepository = {
  list: async () => {
    const response = await contentLibraryClient.get<AssetListApiResponse>('/assets');

    const assetList: Asset[] = response.assets;

    return assetList;
  },

  create: async (request: CreateRequest): Promise<Asset> => {
    return await contentLibraryClient.post<Asset>('/assets', request);
  },
};
