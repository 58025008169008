export default function ImageComponent({
  currentBlockId,
  src,
  imageAlt = '',
  opacity = 1,
  style,
}: {
  currentBlockId: string;
  src: string;
  imageAlt: string | undefined;
  opacity: number | undefined;
  style: React.CSSProperties;
}) {
  const customImageAlt = imageAlt ? imageAlt : `image-${currentBlockId}`;

  return (
    <img
      key={`image-${currentBlockId}`}
      data-testid={`image-${currentBlockId}`}
      src={src}
      alt={customImageAlt}
      className="grid_image_style"
      draggable={false}
      style={{ ...style, opacity: `${opacity}%` }}
    />
  );
}
