import { useState, useContext, useEffect } from 'react';
import { SelectionContext } from '../SelectedBlockInfoProvider';
import { SidePanelProviderContext } from 'components/editor/SidePanel/content';

const useBlockState = (blockId) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { selectedBlockIdByWrapper, setSelectedBlockIdByWrapper } = useContext(SelectionContext);
  const { resetAllPanels } = useContext(SidePanelProviderContext);
  useEffect(() => {
    if (!selectedBlockIdByWrapper) setIsEditMode(false);
  }, [selectedBlockIdByWrapper]);

  const handleOnMouseDown = (e) => {
    const isDoubleClick = e.detail === 2;

    if (selectedBlockIdByWrapper === blockId) {
      if (isDoubleClick) {
        setIsEditMode(true);
      }
    } else {
      setSelectedBlockIdByWrapper(blockId);
      resetAllPanels();
    }
  };

  return { handleOnMouseDown, isEditMode };
};

export default useBlockState;
