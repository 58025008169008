import { EditorContentWrapper } from 'pages/editor/editorContentWrapper';
import { useParams } from 'react-router-dom';

const TemplateEditorPage = () => {
  const { assetId } = useParams<{ assetId: string }>();

  if (!assetId) {
    throw new Error('Asset id missing');
  }

  return <EditorContentWrapper contentId={assetId} />;
};

export default TemplateEditorPage;
