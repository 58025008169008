import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { Form } from 'components/form';
import PreviewUnsignedSignature from '../GridDndEditor/Block/Signature/PreviewUnsignedSignature';
import { Modal, openNotification } from 'components';
import { useSignSignatureMutation } from 'hooks/useSignSignatureMutation';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { AssignedSignee, SignatureBox, SignatureRepository } from 'services/repositories/interfaces/SignatureRepository';
import { ApiSignatureRepository } from 'services/repositories/implementations/ApiSignatureRepository';
import { Dimensions, Position } from '../shared/interfaces';

import './styles.less';

type WithSignatureModalProps = {
  signatureId: string;
  position: Position;
  dimensions: Dimensions;
  documentId: string;
  assignedSignee: AssignedSignee | null;
  setSignatures: React.Dispatch<React.SetStateAction<SignatureBox[]>>;
};

interface FormValue {
  firstName: string;
  lastName: string;
  email: string;
  initials: string;
}

const signatureRepository: SignatureRepository = new ApiSignatureRepository();

const extractAssignedSigneeInitials = ({ firstName, lastName }): string => {
  let initials = '';

  if (firstName) {
    initials += firstName.charAt(0);
  }

  if (lastName.includes('-')) {
    const accountForHyphenatedLastNames = lastName.split('-');
    for (const lastNames of accountForHyphenatedLastNames) {
      initials += lastNames.charAt(0);
    }
  } else if (lastName) {
    initials += lastName.charAt(0);
  }

  return initials;
};

const WithSignatureModal: React.FC<WithSignatureModalProps> = ({
  signatureId,
  position,
  dimensions,
  documentId,
  assignedSignee,
  setSignatures,
}) => {
  const initialSignatureFontSize = 40;
  const minimalSignatureFontSize = 18;
  const initialContainerHeight = 160;
  const PreLineClassName = 'pre-line';

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isTargetVisible, setIsTargetVisible] = useState<boolean>(false);
  const [signatureFontSize, setSignatureFontSize] = useState(initialSignatureFontSize);
  const [formState, setFormState] = useState<FormValue>({
    firstName: assignedSignee?.firstName ?? '',
    lastName: assignedSignee?.lastName ?? '',
    email: assignedSignee?.email ?? '',
    initials: assignedSignee ? extractAssignedSigneeInitials(assignedSignee) : '',
  });

  const { mutate: signSignature } = useSignSignatureMutation(signatureRepository);
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const [form] = Form.useForm<FormValue>();
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const validateFormBeforeSubmit = async () => {
    try {
      await form.validateFields();
      return saveSignature();
    } catch (errorInfo) {
      return errorInfo;
    }
  };

  const saveSignature = useCallback(() => {
    signSignature(
      {
        documentId: documentId,
        signatureBoxId: signatureId,
        firstName: formState.firstName,
        lastName: formState.lastName,
        email: formState.email,
        initials: formState.initials,
      },
      {
        onError: () => {
          openNotification({
            type: 'error',
            title: t('editor.signature.sign_failure_message'),
            description: '',
          });
        },
        onSuccess: async (signedSignature: SignatureBox) => {
          setSignatures((prev) => {
            return prev.map((signature) => {
              return signature.signatureBoxId === signedSignature.signatureBoxId ? signedSignature : signature;
            });
          });
        },
        onSettled: () => {
          setIsOpen(false);
        },
      }
    );
  }, [formState.firstName, formState.lastName, formState.email, formState.initials]);

  const { firstName, lastName, initials } = formState;

  const updateFontSize = () => {
    if (contentRef.current && containerRef.current) {
      const containerHeight = containerRef.current.getBoundingClientRect().height;
      const hasContentRefPreLine = contentRef.current.classList.contains(PreLineClassName) && containerHeight > initialContainerHeight;
      const containerWidth =
        containerRef.current.clientWidth -
        parseInt(window.getComputedStyle(containerRef.current).paddingLeft) * 2 -
        parseInt(window.getComputedStyle(containerRef.current).borderLeftWidth) * 2;
      const contentWidth = contentRef?.current.offsetWidth;
      const adjustedFontSize = (containerWidth / contentWidth) * signatureFontSize;
      let newFontSize = adjustedFontSize < minimalSignatureFontSize || hasContentRefPreLine ? minimalSignatureFontSize : adjustedFontSize;
      newFontSize = Math.min(newFontSize, initialSignatureFontSize);
      setSignatureFontSize(Math.floor(newFontSize));
    }
  };

  useEffect(() => {
    updateFontSize();
    const observer = new MutationObserver(() => {
      updateFontSize();
    });

    if (contentRef.current?.parentNode) {
      observer.observe(contentRef.current.parentNode, {
        childList: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [firstName, lastName]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isTargetVisible) {
          setIsTargetVisible(true);
          updateFontSize();
          observer.unobserve(entry.target);
        }
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, [isOpen]);

  return (
    <>
      <PreviewUnsignedSignature
        assignedSignee={assignedSignee}
        position={position}
        dimensions={dimensions}
        signatureId={signatureId}
        key={signatureId}
        handleClick={() => setIsOpen(true)}
      />
      <Modal
        isVisible={isOpen}
        handleCancel={() => setIsOpen(false)}
        handleOk={validateFormBeforeSubmit}
        affirmText="Sign & Accept"
        cancelText="Cancel"
        className="signature-modal"
        width={isMobile ? '100%' : 600}
        title="Add your signature"
      >
        <Form
          onValuesChange={(value) => {
            setFormState((prev) => {
              return { ...prev, ...value };
            });
          }}
          layout="vertical"
          className="signature-modal-form"
          initialValues={formState}
          form={form}
        >
          <div className="form-input-info-wrapper">
            <Form.Item
              label="First Name"
              name="firstName"
              className="form-input-first-name"
              rules={[{ required: true, whitespace: true, message: 'Please enter a valid name' }]}
            >
              <Input placeholder="First Name" maxLength={256} />
            </Form.Item>
            <Form.Item
              label="Last Name"
              required
              className="form-input-last-name"
              name="lastName"
              rules={[{ required: true, whitespace: true, message: 'Please enter a valid last name' }]}
            >
              <Input placeholder="Last Name" maxLength={256} />
            </Form.Item>
            <Form.Item
              label="Initials"
              required
              name="initials"
              className="form-input-initials"
              rules={[{ required: true, message: 'Initials required' }]}
            >
              <Input placeholder="Initials" maxLength={256} />
            </Form.Item>
          </div>
          <div className="form-input-email-wrapper">
            <Form.Item
              label="Email address"
              name="email"
              required
              className="form-input-email"
              validateTrigger="onBlur"
              rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
            >
              <Input placeholder="Email address" type="email" maxLength={256} />
            </Form.Item>
          </div>
          <div className="form-signature-paint">
            <div ref={containerRef} className="form-signature-paint-fullname">
              <span
                ref={contentRef}
                className={`signature-text ${signatureFontSize === minimalSignatureFontSize ? PreLineClassName : ''}`}
                style={{ fontSize: `${signatureFontSize}px` }}
              >
                {firstName && firstName} {lastName && lastName}
              </span>
            </div>
            <div className="form-signature-paint-initials">{initials && initials}</div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default WithSignatureModal;
