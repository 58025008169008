import React, { useState, useCallback } from 'react';
import { useCurrentUser } from 'providers/UserProvider';
import { Heading, Button, Icon } from 'components';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, List } from 'antd';
import { teamTopFeatures, businessTopFeatures, allPlanFeatures, basicTopFeatures } from './planFeatures';
import FeatureTable from './featureTable';
import FAQ from './faq';
import BillingHeader from './billingHeader';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { PlanPageProps, PlanCardType } from './interface';
import { usePlanApiData } from 'hooks/usePlanApiData';
import DowngradeModal from './DowngradeModal';
import PageLoader from 'components/page-loader/page-loader';
import SeeAllFeaturesModal from './seeAllFeaturesModal';
import { useSeeAllFeaturesReducer } from 'hooks/useSeeAllFeaturesReducer';
import { ApiPlanFeatureRepository } from 'services/repositories/implementations/ApiPlanFeatureRepository';
import goToCinder from 'utils/goToCinder';
import './styles.less';

const PlanPage: React.FC<PlanPageProps> = ({ planRepository }) => {
  const [isModalOpen, setModalOpenState] = useState(false);
  const [planTypeState, setPlanType] = useState('');
  const {
    data: { accountId },
  } = useCurrentUser();
  const { planData, isLoading, isFetched } = usePlanApiData({ planRepository, accountId, enabled: !!accountId });
  const apiPlanFeatureRepository = new ApiPlanFeatureRepository();

  const { t } = useTranslation();
  const { isDesktop, isTablet, isMobile } = useMediaQuery();
  const translatedBasicTeamTopFeatures = basicTopFeatures.map((feature) => t(feature));
  const translatedTeamTopFeatures = teamTopFeatures.map((feature) => t(feature));
  const translatedBusinessTopFeatures = businessTopFeatures.map((feature) => t(feature));

  const { state: seeAllFeaturesState, triggerDispatch: seeAllFeaturesDispatch } = useSeeAllFeaturesReducer();

  const handleTalkToSales = () => window.open('https://links.proposify.com/sales/book');

  const handleViewPlanDetails = () => goToCinder('settings/summary');

  const handleTeamButtonClick = useCallback(
    (planName) => {
      if (planData?.type === planName) {
        if (planName === 'business') {
          handleTalkToSales();
        }
        handleViewPlanDetails();
      } else {
        handleDowngrade(planName);
      }
    },
    [planData?.type]
  );

  const handleDowngrade = useCallback(
    (planName) => {
      if (planData?.canSelfDowngrade && planData?.type !== '') {
        const urlString = 'settings/plan/' + planName.charAt(0).toUpperCase() + planName.slice(1);
        goToCinder(urlString);
      } else if (!planData?.canSelfDowngrade && planData?.type !== '') {
        goToCinder('settings/request/downgrade');
      } else {
        setModalOpenState(true);
        setPlanType(planName);
      }
    },
    [isModalOpen, planData?.canSelfDowngrade]
  );

  const basicPlanButtonlabel =
    planData?.type === 'basic' ? 'settings.plan.button_view_plan_details' : 'settings.plan.button_downgrade_plan_label';

  const teamPlanButtonLabel =
    planData?.type === 'team' ? 'settings.plan.button_view_plan_details' : 'settings.plan.button_downgrade_plan_label';

  const businessPlanButtonLabel =
    planData?.type === 'business' ? 'settings.plan.button_view_plan_details' : 'settings.plan.button_contact_plan_label';

  const handleSeeAllFeaturesButtonClick = (plan: PlanCardType) => {
    const features = {};
    const title = plan.charAt(0).toUpperCase() + plan.slice(1);

    for (const featureGroup in allPlanFeatures) {
      features[featureGroup] = allPlanFeatures[featureGroup].filter((feature) => feature[plan]);
    }

    const onConfirm = (planName) => {
      handleTeamButtonClick(planName);
    };

    const confirmButtonLabel = plan === 'business' ? businessPlanButtonLabel : teamPlanButtonLabel;

    seeAllFeaturesDispatch({
      title,
      plan,
      features,
      onConfirm,
      confirmButtonLabel,
    });
  };

  const SeeAllFeaturesLink = ({ plan }: { plan: PlanCardType }) => {
    return (
      <a className="see-all-features-link" href="#" onClick={() => handleSeeAllFeaturesButtonClick(plan)}>
        {t('settings.plan.see_all_features')} <Icon name="IcoArrowRight" />
      </a>
    );
  };

  return (
    <PageLoader isLoading={isLoading} isLoaded={isFetched}>
      <BillingHeader />
      {planData?.id ? (
        <DowngradeModal
          planFeatureRepository={apiPlanFeatureRepository}
          isOpen={isModalOpen}
          setModalOpen={setModalOpenState}
          currentPlanId={planData.id}
          planType={planTypeState}
        />
      ) : null}
      <SeeAllFeaturesModal state={seeAllFeaturesState} />
      <Row className="plan-page-price-card-container">
        <Col md={{ span: 6, offset: 6 }} sm={12} xs={24}>
          <Card className="default team" bordered={false}>
            <Heading level={2}>{t('settings.plan.basic.title')}</Heading>
            <p>
              {t('settings.plan.basic.subtitle')}
              <br />
              {t('settings.plan.basic.subtitle_billing_cycle')}
            </p>
            <div className="price">
              <Heading level={1}>$35</Heading>
              <p>{t('settings.plan.basic.billing_model')}</p>
            </div>
            <List
              dataSource={translatedBasicTeamTopFeatures}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta avatar={<Icon name="IcoCircleCheckSolid" />} description={item} />
                </List.Item>
              )}
            />
            {(isTablet || isMobile) && <SeeAllFeaturesLink plan="team" />}
            <Button type="default" variant="neutral" onClick={() => handleTeamButtonClick('basic')}>
              {t(`${basicPlanButtonlabel}`)}
            </Button>
          </Card>
        </Col>
        <Col md={5} sm={12} xs={24}>
          <Card className="default team" bordered={false}>
            <Heading level={2}>{t('settings.plan.team.title')}</Heading>
            <p>
              {t('settings.plan.team.subtitle')}
              <br />
              {t('settings.plan.team.subtitle_billing_cycle')}
            </p>
            <div className="price">
              <Heading level={1}>$49</Heading>
              <p>{t('settings.plan.team.billing_model')}</p>
            </div>
            <p className="feature-pretext">{t('settings.plan.feature_pretext', { plan_title: 'Basic' })}</p>
            <List
              dataSource={translatedTeamTopFeatures}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta avatar={<Icon name="IcoCirclePlusSolid" />} description={item} />
                </List.Item>
              )}
            />
            {(isTablet || isMobile) && <SeeAllFeaturesLink plan="business" />}
            <Button type="default" variant="emphasis" onClick={() => handleTeamButtonClick('team')}>
              {t(`${teamPlanButtonLabel}`)}
            </Button>
          </Card>
        </Col>
        <Col className="emphasis-container" md={5} sm={12} xs={24}>
          <Card className="emphasis business" bordered={false}>
            <Heading level={2}>{t('settings.plan.business.title')}</Heading>
            <p>
              {t('settings.plan.business.subtitle')}
              <br />
              {t('settings.plan.business.subtitle_billing_cycle')}
            </p>
            <div className="price">
              <Heading level={1}>$65</Heading>
              <p>
                {t('settings.plan.business.billing_model')}
                <br />
                {t('settings.plan.user_minimum', { count: 10 })}
                <br />
              </p>
            </div>
            <p className="feature-pretext">{t('settings.plan.feature_pretext', { plan_title: 'Team' })}</p>
            <List
              dataSource={translatedBusinessTopFeatures}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta avatar={<Icon name="IcoCirclePlusSolid" />} description={item} />
                </List.Item>
              )}
            />
            {(isTablet || isMobile) && <SeeAllFeaturesLink plan="business" />}
            <Button type="default" variant="emphasis" onClick={() => handleTeamButtonClick('business')}>
              {t(`${businessPlanButtonLabel}`)}
            </Button>
          </Card>
        </Col>
      </Row>
      {isDesktop && (
        <Row className="feature-table">
          <Col span={20} offset={3} pull={1}>
            <FeatureTable features={allPlanFeatures} />
          </Col>
        </Row>
      )}
      <Row className="plan-page-faq-container">
        <Col span={24}>
          <FAQ />
        </Col>
      </Row>
    </PageLoader>
  );
};

export default PlanPage;
