import React, { useContext } from 'react';
import { Modal } from 'components';
import { useTranslation } from 'react-i18next';
import usePreviewRedirect from '../../../pages/editor/usePreviewRedirect';
import { URLS } from '../../../utils/urls';
import { useNavigate } from 'react-router-dom';
import { SaveStatusContext } from '../providers/SaveStatusProvider';
import { ErrorCode } from 'services/socket/SocketEvents';

type DocumentLockedModalProps = {
  isDocumentLocked: boolean;
  documentId: string;
};

const DocumentLockedModal: React.FC<DocumentLockedModalProps> = ({ isDocumentLocked, documentId }) => {
  const { t } = useTranslation();
  const { currentSaveStatus } = useContext(SaveStatusContext);
  const navigate = useNavigate();
  const handlePreviewRedirect = usePreviewRedirect(documentId);

  let isModalVisible = false;
  const handlePipelineRedirect = () => navigate(URLS.pipeline);

  if (isDocumentLocked || currentSaveStatus.errCode === ErrorCode.LOCKED) {
    isModalVisible = true;
  }

  return (
    <Modal
      isVisible={isModalVisible}
      handleOk={handlePreviewRedirect}
      handleCancel={handlePipelineRedirect}
      affirmText={t('document.locked.go_to_preview')}
      cancelText={t('document.locked.go_to_pipeline')}
      title={t('document.locked.title')}
      closable={false}
      maskClosable={false}
      escClosable={false}
    >
      <p>{t('document.locked.message')}</p>
    </Modal>
  );
};

export default DocumentLockedModal;
