import { RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Direction } from 're-resizable/lib/resizer';
import { useTranslation } from 'react-i18next';

import { RootState, useAppDispatch } from '../../grid/reduxStore/Store';
import { gridPageMaxWidthInPixels } from '../../shared/gridConfig';
import { SignaturesContext } from '../../providers/SignaturesProvider';
import { calculatePageMaxHeight, getSnapPosition, roundToNearestMultipleOfGridSize, setupTrackedBlocks } from '../gridHelper';
import {
  selectCurrentDraggedBlock,
  setMaxHeightPage,
  updateCurrentDraggedBlock,
  updateGridDimensionConfig,
  updateGridPositionConfig,
} from '../../grid/reduxStore/editorSlice';
import { BlockConfig } from '../models/BlockConfig.model';
import { DocumentSettingsContext } from '../../SidePanel/document-settings/DocumentDesignSettings/DocumentSettingsContext';
import { useBlockDimensionChangedHandler } from '../../hooks/UseBlockDimensionChangedHandler';
import { openNotification } from '../../../notification';
import { useBlockPositionChangedHandler, useBlockPositionChangedHandlerWithoutUndoRedo } from '../../hooks/UseBlockPositionChangedHandler';

import {
  BlockDimensionType,
  BlockPositionType,
  HandleOnDragStartType,
  HandleOnDragStopType,
  HandleOnDragType,
  HandleOnResizingStopType,
  HandleOnResizingType,
  ResizableDelta,
} from './types';

type PositionDraggingProps = {
  x: number;
  y: number;
};

export const useDraggableBlockManipulations = () => {
  const blocksContent = useSelector((state: RootState) => {
    return state.gridBlockReducer.blocksContent;
  });
  const blockPositionChangedHandlerWithoutUndoRedo = useBlockPositionChangedHandlerWithoutUndoRedo();
  const blockPositionChangedHandler = useBlockPositionChangedHandler();
  const { documentSettings } = useContext(DocumentSettingsContext);
  const { signatures } = useContext(SignaturesContext);
  const blockDimensionChangedHandler = useBlockDimensionChangedHandler();
  const trackedBlocks = setupTrackedBlocks(blocksContent, signatures);

  const currentDraggedBlock = useSelector((state: RootState) => selectCurrentDraggedBlock(state));
  const [maxPageHeight, setMaxPageHeight] = useState<number>(calculatePageMaxHeight(blocksContent, signatures));
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const mouseMovementWhenDragging = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const updateBlocks = (item: BlockConfig) => {
    const { height, width, x, y } = item;
    const tempBlocks = {
      ...trackedBlocks,
      [item.id]: { height, width, x, y },
    };
    delete tempBlocks['placeholder'];
    const payload = { currentDraggedBlock: null };
    dispatch(updateCurrentDraggedBlock(payload));
  };

  const calculateNewPositionBasedOnResizing = (direction: Direction, delta: ResizableDelta, position: BlockPositionType) => {
    const newPosition = { xAxisPx: position.xAxisPx, yAxisPx: position.yAxisPx };
    const left = -delta.width;
    const top = -delta.height;

    if (direction === 'bottomLeft') {
      newPosition.xAxisPx += left;
    } else if (direction === 'topRight') {
      newPosition.yAxisPx += top;
    } else if (['top', 'left', 'topLeft'].includes(direction)) {
      newPosition.xAxisPx += left;
      newPosition.yAxisPx += top;
    }
    return newPosition;
  };

  const getSelectedBlockWithUpdatedDimensions = (delta: ResizableDelta, selectedBlockConfig: BlockConfig): BlockConfig => {
    const selectedBlockWithUpdatedDimensions = { ...selectedBlockConfig };
    selectedBlockWithUpdatedDimensions.width += delta.width;
    selectedBlockWithUpdatedDimensions.height += delta.height;
    return selectedBlockWithUpdatedDimensions;
  };

  const setDocumentHeight = useCallback(
    (currentBlock?) => {
      const computedPageMaxHeightFromAllBlocks = calculatePageMaxHeight(blocksContent, signatures, currentBlock);
      setMaxPageHeight(computedPageMaxHeightFromAllBlocks);
    },
    [blocksContent, signatures]
  );

  const calculateMouseMovementAndPosition = (
    event: MouseEvent,
    draggedBlock: BlockConfig,
    mouseMovementWhenDragging: RefObject<{ x: number; y: number }>
  ) => {
    if (!mouseMovementWhenDragging.current) return;
    mouseMovementWhenDragging.current.x += event.movementX;
    mouseMovementWhenDragging.current.y += event.movementY;

    return {
      x: mouseMovementWhenDragging.current.x + draggedBlock.x,
      y: mouseMovementWhenDragging.current.y + draggedBlock.y,
    };
  };

  const calculateNewPosition = (
    snapPosition: { x: number | null; y: number | null },
    previousPosition: { x: number; y: number },
    mouseMovementWhenDragging: RefObject<{ x: number; y: number }>
  ) => {
    if (!mouseMovementWhenDragging.current) return;
    const newX = snapPosition.x ?? ((mouseMovementWhenDragging.current.x = 0), previousPosition.x);
    const newY = snapPosition.y ?? ((mouseMovementWhenDragging.current.y = 0), previousPosition.y);

    return { x: newX, y: newY };
  };

  const handleDrag: HandleOnDragType = (event, _data, draggedBlock) => {
    const positionWhenDragging: PositionDraggingProps = calculateMouseMovementAndPosition(
      event,
      draggedBlock,
      mouseMovementWhenDragging
    ) as PositionDraggingProps;

    setDocumentHeight(draggedBlock);
    const payload = { currentDraggedBlock: draggedBlock };
    dispatch(updateCurrentDraggedBlock(payload));
    //setCurrentDraggedBlock(draggedBlock);

    if (signatures?.find((signature) => signature.signatureBoxId === draggedBlock.id)) {
      return;
    }

    const snapPosition = getSnapPosition(
      { ...draggedBlock, ...positionWhenDragging },
      trackedBlocks,
      gridPageMaxWidthInPixels,
      documentSettings.margin
    );

    const newPosition = calculateNewPosition(snapPosition, positionWhenDragging, mouseMovementWhenDragging) as PositionDraggingProps;

    dispatch(
      updateGridPositionConfig({
        blockId: draggedBlock.id,
        ...newPosition,
      })
    );
  };

  const handleDragStart: HandleOnDragStartType = () => {
    mouseMovementWhenDragging.current = { x: 0, y: 0 };
    const payload = { currentDraggedBlock };
    dispatch(updateCurrentDraggedBlock(payload));
  };

  const isBlockDroppedOffCanvas = ({ x, y, width, height }: { x: number; y: number; width: number; height: number }) => {
    const isMovedOffRightSide = x >= gridPageMaxWidthInPixels;
    const isMovedOffLeftSide = x + width <= 0;
    const isMovedOffTopSide = y + height <= 0;

    return isMovedOffRightSide || isMovedOffLeftSide || isMovedOffTopSide;
  };

  const hasPositionChangedOnDrag = (
    leftPxOnDragStart: number,
    leftPxOnDragStop: number,
    topPxOnDragStart: number,
    topPxOnDragStop: number
  ): boolean => leftPxOnDragStart !== leftPxOnDragStop || topPxOnDragStart !== topPxOnDragStop;

  const handleStop: HandleOnDragStopType = async (_event: MouseEvent, positionOnDragStart: BlockPositionType, item: BlockConfig) => {
    updateBlocks(item);

    if (!hasPositionChangedOnDrag(positionOnDragStart.xAxisPx, item.x, positionOnDragStart.yAxisPx, item.y)) return;

    const positionBefore = { xAxisPx: positionOnDragStart.xAxisPx, yAxisPx: positionOnDragStart.yAxisPx };
    if (isBlockDroppedOffCanvas(item)) {
      openNotification({
        title: t('editor.blocks.placing.outside_canvas_warn.title'),
        description: t('editor.blocks.placing.outside_canvas_warn.desc'),
        type: 'warning',
      });

      await blockPositionChangedHandlerWithoutUndoRedo(item.id, positionBefore);

      const computedPageMaxHeightFromAllBlocks = calculatePageMaxHeight(blocksContent, signatures);
      setMaxPageHeight(computedPageMaxHeightFromAllBlocks);
      return;
    }

    const positionAfter = { xAxisPx: roundToNearestMultipleOfGridSize(item.x), yAxisPx: roundToNearestMultipleOfGridSize(item.y) };
    await blockPositionChangedHandler(item.id, positionBefore, positionAfter);
  };

  const hasDimensionsChangedOnResize = (delta: { width: number; height: number }): boolean => delta.width !== 0 || delta.height !== 0;

  const handleOnResizeStop: HandleOnResizingStopType = async (
    direction,
    delta,
    blockStateOnResizeStop,
    positionOnResizeStart: BlockPositionType,
    dimensionOnResizeStart: BlockDimensionType
  ) => {
    if (!hasDimensionsChangedOnResize(delta)) return;

    const stateBeforeResizing = {
      widthPx: dimensionOnResizeStart.widthPx,
      heightPx: dimensionOnResizeStart.heightPx,
      yAxisPx: positionOnResizeStart.yAxisPx,
      xAxisPx: positionOnResizeStart.xAxisPx,
    };

    const stateAfterResizing = {
      widthPx: blockStateOnResizeStop.width,
      heightPx: blockStateOnResizeStop.height,
      yAxisPx: blockStateOnResizeStop.y,
      xAxisPx: blockStateOnResizeStop.x,
    };

    await blockDimensionChangedHandler(blockStateOnResizeStop.id, stateBeforeResizing, stateAfterResizing);
  };

  const handleOnResizing: HandleOnResizingType = (
    direction: Direction,
    delta: ResizableDelta,
    positionOnInteractionStart: BlockPositionType,
    dimensionOnInteractionStart: BlockDimensionType,
    selectedBlock: BlockConfig
  ) => {
    const newPosition = calculateNewPositionBasedOnResizing(direction, delta, positionOnInteractionStart);
    const updatedBlock = {
      ...selectedBlock,
      x: newPosition.xAxisPx,
      y: newPosition.yAxisPx,
      height: dimensionOnInteractionStart.heightPx + delta.height,
      width: dimensionOnInteractionStart.widthPx + delta.width,
    };

    dispatch(updateGridPositionConfig({ blockId: updatedBlock.id, x: updatedBlock.x, y: updatedBlock.y }));
    dispatch(updateGridDimensionConfig({ blockId: updatedBlock.id, width: updatedBlock.width, height: updatedBlock.height }));
    setDocumentHeight(getSelectedBlockWithUpdatedDimensions(delta, updatedBlock));
  };

  const handleOnResizeStopTextBlock: HandleOnResizingStopType = async (
    direction,
    delta,
    blockStateOnResizeStop,
    positionOnResizeStart: BlockPositionType,
    dimensionOnResizeStart: BlockDimensionType,
    sumBorderWidth?: number
  ) => {
    if (!hasDimensionsChangedOnResize(delta)) return;

    const inputBox = document.querySelector(`.froala_editor_${blockStateOnResizeStop.id}`);

    if (!inputBox) return;
    const selectedBlockWithUpdatedDimensions = { ...blockStateOnResizeStop };

    const { width: widthPxOnResizeStop, height: heightPxOnResizeStop } = selectedBlockWithUpdatedDimensions;
    const { height: textBoxHeight, width: textBlockWidth } = inputBox.getBoundingClientRect();
    const isResizableByTextBlock =
      textBoxHeight > heightPxOnResizeStop || textBlockWidth + (sumBorderWidth as number) !== widthPxOnResizeStop;

    selectedBlockWithUpdatedDimensions.width = isResizableByTextBlock ? textBlockWidth : widthPxOnResizeStop;
    selectedBlockWithUpdatedDimensions.height = isResizableByTextBlock ? textBoxHeight : heightPxOnResizeStop;
    await handleOnResizeStop(direction, delta, selectedBlockWithUpdatedDimensions, positionOnResizeStart, dimensionOnResizeStart);
  };

  useEffect(() => {
    setDocumentHeight();
  }, [blocksContent]);

  useEffect(() => {
    const payload = { maxHeightPage: maxPageHeight };
    dispatch(setMaxHeightPage(payload));
  }, [maxPageHeight]);

  return {
    handleOnResizeStopTextBlock,
    handleOnResizing,
    handleDrag,
    handleDragStart,
    handleOnResizeStop,
    handleStop,
    setDocumentHeight,
    updateBlocks,
  };
};
