import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { GridCellParams } from '@mui/x-data-grid-pro';

import { TableRowType, TableType } from '../../../../grid/reduxStore/saveHandlers';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { SidePanelProviderContext, TableSettingsTypes } from '../../SidePanelModelsProvider';
import { TableRowTypes } from '../../../../../../muiTheme/MuiDataGrid';
import { BlockStyleSettingsContext } from '../../designSettings/AdvancedSpacing/BlockSettingsProvider';
import { RootState } from '../../../../grid/reduxStore/Store';
import { useBlockDeletedHandler } from '../../../../hooks/UseBlockDeletedHandler';
import { columns } from '../../../../GridDndEditor/Block/Table';

import { selectContentTableRows, selectContentTableColumns, selectBlockContent } from '../../../../grid/reduxStore/editorSlice';
import { useTableManipulation } from '../../../../GridDndEditor/Block/Table/useTableManipulation';
import { useBlockContentChangedHandler } from '../../../../hooks/UseBlockContentChangedHandler';
import { defaultRowMeta } from '../../../../GridDndEditor/Block/Table/variables';

export enum TableRowAddPositions {
  BEFORE = 0,
  AFTER = +1,
}

export const useTableRowManipulation = () => {
  const { resetAllPanels } = useContext(SidePanelProviderContext);
  const [, setIsModalVisible] = useState(false);
  const { deleteBlocksSettings } = useContext(BlockStyleSettingsContext);
  const gridBlockDeletedHandler = useBlockDeletedHandler();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { toggledTableSettingsPanel, setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);
  const { getTableData } = useTableManipulation();
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);

  const allTextTableRows = useSelector((state: RootState) => selectContentTableRows(state, selectedBlockIdByWrapper as string));
  const allTextTableColumns = useSelector((state: RootState) => selectContentTableColumns(state, selectedBlockIdByWrapper as string));
  const selectTableBlockContent = useSelector((state: RootState) => selectBlockContent(state, selectedBlockIdByWrapper as string));
  const allRowIds = allTextTableRows?.map((r) => r.id);

  const updatedTableState = async (blockId: string, updateRows: TableRowType[], updatedColumns: typeof columns) => {
    const tableData = getTableData(blockId) as TableType;
    const newTableData: TableType = {
      ...tableData,
      columns: updatedColumns,
      rows: updateRows,
    };

    await blockContentChangedHandler(blockId, newTableData);
  };

  const addNewEmptyRowAtIndexPosition = (rows: TableRowType[], positionIndex: number, duplicatedData: TableRowType) => {
    const rowData: TableRowType = { ...duplicatedData, ...defaultRowMeta, rowType: TableRowTypes.BODY };
    return addRowAtIndexPosition(rows, rowData, positionIndex);
  };

  const addRowAtIndexPosition = async (rows: TableRowType[], rowData: TableRowType, positionIndex: number) => {
    const newRowId = uuidv4();
    const newRowData = { ...rowData, id: newRowId };
    const newRows = [...rows];
    newRows.splice(positionIndex, 0, newRowData);

    await updatedTableState(selectedBlockIdByWrapper as string, newRows, allTextTableColumns);

    return newRowId;
  };

  const setCellFocus = (rowId: string) => {
    if (!toggledTableSettingsPanel || !selectedBlockIdByWrapper) return;

    const tableData = getTableData(selectedBlockIdByWrapper) as TableType;
    const allColumns = tableData.columns;
    const tableCallbackDetails = toggledTableSettingsPanel.tableApi.tableCallbackDetails;
    const firstEditableColumn = allColumns.find((column) => column.editable && column.field);

    if (firstEditableColumn) {
      tableCallbackDetails.api.setRowSelectionModel([rowId]);
      setToggledTableSettingsPanel({
        tableSettingsPanel: TableSettingsTypes.TABLE_ROWS,
        tableApi: {
          selectedModel: tableCallbackDetails.api.getCellParams(rowId, firstEditableColumn.field),
          tableCallbackDetails: tableCallbackDetails,
        },
      });

      tableCallbackDetails.api.startCellEditMode({
        id: rowId,
        field: firstEditableColumn.field,
      });
    } else {
      setToggledTableSettingsPanel(null);
    }
  };

  const getSelectedRowId = (): string | undefined => {
    if (!toggledTableSettingsPanel) return undefined;

    const cellSelectedModel = toggledTableSettingsPanel.tableApi.selectedModel as GridCellParams;
    return cellSelectedModel.row.id;
  };

  const handleRowAdd = async (position: TableRowAddPositions) => {
    const selectedRowId = getSelectedRowId();
    if (!selectedBlockIdByWrapper || !toggledTableSettingsPanel || !selectedRowId) return;

    const updatedRows = allTextTableRows;
    const rowDataDuplicated = updatedRows[selectedRowId] || columns[0];
    const updatedRowDataDuplicated: TableRowType = {} as TableRowType;
    Object.keys(rowDataDuplicated).forEach((key) => {
      if (key !== TableRowTypes.BODY) updatedRowDataDuplicated[key] = '';
    });

    const selectedRowIndex = allRowIds.indexOf(selectedRowId);
    const newRowId = await addNewEmptyRowAtIndexPosition(updatedRows, selectedRowIndex + position, updatedRowDataDuplicated);

    if (newRowId) {
      setCellFocus(newRowId);
    }
  };

  const handleRowDuplication = async () => {
    const selectedRowId = getSelectedRowId();
    if (!selectedBlockIdByWrapper || !toggledTableSettingsPanel || !selectedRowId) return;

    const selectedRowIndex = allTextTableRows.map((row) => row.id).indexOf(selectedRowId);
    const rowDataDuplicated = allTextTableRows[selectedRowIndex];
    const newRowId = await addRowAtIndexPosition(allTextTableRows, rowDataDuplicated, selectedRowIndex + TableRowAddPositions.AFTER);

    if (newRowId) {
      setCellFocus(newRowId);
    }
  };

  const handleDeleteRow = async () => {
    const selectedRowId = getSelectedRowId();
    if (!selectedBlockIdByWrapper || !toggledTableSettingsPanel || !selectedRowId) return;
    const tableData = getTableData(selectedBlockIdByWrapper) as TableType;

    const updatedRows = tableData.rows.filter((row) => row.id !== selectedRowId);

    if (!updatedRows.length) {
      deleteBlocksSettings(selectedBlockIdByWrapper);
      gridBlockDeletedHandler(selectedBlockIdByWrapper, selectTableBlockContent).then(() => {
        setIsModalVisible(false);
        resetAllPanels();
      });
      return;
    }

    const idIndex = toggledTableSettingsPanel.tableApi.tableCallbackDetails.api.getAllRowIds().indexOf(selectedRowId);
    const newSelectedIndex = idIndex - 1 >= 0 ? idIndex - 1 : idIndex + 1;
    const newSelectedRowId = allRowIds[newSelectedIndex];
    await updatedTableState(selectedBlockIdByWrapper, [...updatedRows], [...tableData.columns]);

    const tableCallbackDetails = toggledTableSettingsPanel.tableApi.tableCallbackDetails;
    tableCallbackDetails.api.setRowSelectionModel([newSelectedRowId]);

    setCellFocus(newSelectedRowId);
  };

  return { handleRowAdd, handleRowDuplication, handleDeleteRow };
};
