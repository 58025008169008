import { createSlice } from '@reduxjs/toolkit';
import { BlockConfig } from 'components/editor/GridDndEditor/models/BlockConfig.model';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { gridDefaultGreaterZAxis, gridDefaultLowerZAxis } from '../../shared/gridConfig';
import { TableColumnType, TableRowType, TableType } from './saveHandlers';
import { RootState } from './Store';

type Collection<T> = {
  [key: string]: T;
};

export type BlockContent = {
  content: string;
  contentTable?: TableType;
  blockConfig: BlockConfig;
  type: GridBlockType;
};

export type BlockMetadata = {
  id: string;
  type: GridBlockType;
};
export type BlocksContentCollection = Collection<BlockContent>;

export type BlocksMetadataCollection = BlockMetadata[];

export type GridState = {
  blocksContent: BlocksContentCollection;
  blocksMetadata: BlocksMetadataCollection;
  blocksLayer: {
    greaterZIndexAvailable: number;
    lowerZIndexAvailable: number;
  };
  editorConfig: {
    currentDraggedBlock: BlockConfig | null;
    maxHeightPage: number;
  };
};

const initialState: GridState = {
  blocksContent: {},
  blocksMetadata: [],
  blocksLayer: { greaterZIndexAvailable: gridDefaultGreaterZAxis, lowerZIndexAvailable: gridDefaultLowerZAxis },
  editorConfig: { currentDraggedBlock: null, maxHeightPage: 0 },
};

const gridBlockSlice = createSlice({
  name: 'editor-grid-block',
  initialState,
  reducers: {
    addGridBlockState(state, { payload }) {
      const { blockId, content, blockConfig, blockType } = payload;
      state.blocksMetadata.push({ id: blockId, type: blockType });
      const textImageBlockConfig = {
        blockConfig,
        type: blockType,
        content,
      };
      const tableBlockConfig = {
        ...textImageBlockConfig,
        content: '',
        contentTable: content,
      };
      state.blocksContent[blockId] = blockType === 'TABLE' ? tableBlockConfig : textImageBlockConfig;
      state.blocksLayer.greaterZIndexAvailable++;
      return state;
    },
    updateGridBlockState(state, { payload }) {
      const { blockId, content } = payload;
      state.blocksContent[blockId].content = content;
    },
    updateGridTableBlockState(state, { payload }) {
      const { blockId, contentTable } = payload;
      state.blocksContent[blockId].contentTable = contentTable;
    },
    setMaxHeightPage(state, { payload }) {
      const { maxHeightPage } = payload;
      state.editorConfig.maxHeightPage = maxHeightPage;
    },
    updateGridPositionConfig(state, { payload }: { payload: { blockId: string; x: number; y: number } }) {
      const { blockId, x, y } = payload;
      state.blocksContent[blockId].blockConfig.x = x;
      state.blocksContent[blockId].blockConfig.y = y;
    },
    updateGridLayerConfig(state, { payload }: { payload: { blockId: string; zIndex: number } }) {
      const { blockId, zIndex } = payload;
      state.blocksContent[blockId].blockConfig.z = zIndex;

      const isBlockMovedToFrontLayer = zIndex === state.blocksLayer.greaterZIndexAvailable;
      const isBlockMovedToBackLayer = zIndex === state.blocksLayer.lowerZIndexAvailable;
      if (isBlockMovedToFrontLayer) {
        state.blocksLayer.greaterZIndexAvailable++;
      } else if (isBlockMovedToBackLayer) {
        state.blocksLayer.lowerZIndexAvailable--;
      }
    },
    updateGridDimensionConfig(state, { payload }: { payload: { blockId: string; width: number; height: number } }) {
      const { blockId, width, height } = payload;
      state.blocksContent[blockId].blockConfig.width = width;
      state.blocksContent[blockId].blockConfig.height = height;
    },
    updateCurrentDraggedBlock(state, { payload }) {
      const { currentDraggedBlock } = payload;
      state.editorConfig.currentDraggedBlock = currentDraggedBlock;
    },
    deleteGridBlockState(state, { payload }) {
      const { blockId } = payload;
      const blockIndex = state.blocksMetadata.findIndex((blockMetadata) => blockMetadata.id === blockId);

      const isBlockInFrontLayer = state.blocksContent[blockId].blockConfig.z === state.blocksLayer.greaterZIndexAvailable - 1;
      const isBlockInBackLayer = state.blocksContent[blockId].blockConfig.z === state.blocksLayer.lowerZIndexAvailable + 1;
      if (isBlockInFrontLayer) {
        state.blocksLayer.greaterZIndexAvailable = state.blocksContent[blockId].blockConfig.z;
      } else if (isBlockInBackLayer) {
        state.blocksLayer.lowerZIndexAvailable = state.blocksContent[blockId].blockConfig.z;
      }

      state.blocksMetadata.splice(blockIndex, 1);
      delete state.blocksContent[blockId];
    },
    resetState(state) {
      state = {
        blocksContent: {},
        blocksMetadata: [],
        blocksLayer: { greaterZIndexAvailable: gridDefaultGreaterZAxis, lowerZIndexAvailable: gridDefaultLowerZAxis },
        editorConfig: { currentDraggedBlock: null, maxHeightPage: 0 },
      };
      return state;
    },
    setInitialState(state, { payload }) {
      const { blocksContent, blocksMetadata, blocksLayer, editorConfig } = payload;
      state.blocksContent = blocksContent;
      state.blocksMetadata = blocksMetadata;
      state.blocksLayer = blocksLayer;
      state.editorConfig = editorConfig;
    },
  },
});

export const selectBlockContent = (state: RootState, blockId: string) => state.gridBlockReducer.blocksContent[blockId];
export const selectContentTable = (state: RootState, blockId: string) => selectBlockContent(state, blockId)?.contentTable;
export const selectContentTableRows = (state: RootState, blockId: string) => selectContentTable(state, blockId)?.rows as TableRowType[];
export const selectContentTableColumns = (state: RootState, blockId: string) =>
  selectContentTable(state, blockId)?.columns as TableColumnType[];
export const selectContentMaxPageHeight = (state: RootState) => state.gridBlockReducer.editorConfig.maxHeightPage;
export const selectCurrentDraggedBlock = (state: RootState) => state.gridBlockReducer.editorConfig.currentDraggedBlock as BlockConfig;

export const {
  addGridBlockState,
  updateGridTableBlockState,
  updateGridBlockState,
  updateGridPositionConfig,
  updateGridLayerConfig,
  updateGridDimensionConfig,
  updateCurrentDraggedBlock,
  deleteGridBlockState,
  setMaxHeightPage,
  resetState,
  setInitialState,
} = gridBlockSlice.actions;
export const gridBlockReducer = gridBlockSlice.reducer;
