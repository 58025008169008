import { useSelector } from 'react-redux';

import { GridBlockType } from '../shared/gridBlockType';
import { GridImageBlock } from './Block/Image/GridImageBlock/GridImageBlock';
import { GridTextBlock } from './Block/Text/GridTextBlock';
import { TextTableBlock } from './Block/Table';
import { RootState } from '../grid/reduxStore/Store';

import { EditorPageBlocksProps } from './GridDndEditor';

const EditorPageBlocks = ({ configOptions, documentId }: EditorPageBlocksProps) => {
  const { blocksContent } = useSelector((state: RootState) => state.gridBlockReducer);

  return (
    <div className="editor__page__blocks">
      {Object.values(blocksContent).map((block) => {
        if (block.type === GridBlockType.IMAGE) {
          return <GridImageBlock key={block.blockConfig.id} blockId={block.blockConfig.id} />;
        } else if (block.type === GridBlockType.TEXT) {
          return (
            <GridTextBlock
              key={block.blockConfig.id}
              blockId={block.blockConfig.id}
              documentId={documentId}
              configOptions={configOptions}
            />
          );
        } else if (block.type === GridBlockType.TABLE) {
          return <TextTableBlock key={block.blockConfig.id} documentId={documentId} loading={false} blockId={block.blockConfig.id} />;
        }
      })}
    </div>
  );
};

export default EditorPageBlocks;
