import { useState, useEffect } from 'react';

export type PageRefType = HTMLDivElement | null;

type UseHandlePageBreaksResult = {
  pageBreaks: number;
  leftPosition: number;
};

export const MAX_PAGE_HEIGHT = 1123;
export const PAGE_BREAKERS_WIDTH = 40;

export const useHandlePageBreaks = (pageRef: PageRefType): UseHandlePageBreaksResult => {
  const [pageBreaks, setPageBreaks] = useState<number>(0);
  const [leftPosition, setLeftPosition] = useState<number>(0);

  const appendToPageBreaks = (pageElementHeight: number) => {
    const pages = pageElementHeight / MAX_PAGE_HEIGHT;
    const currentPageBreaks = pages % 1 === 0 ? pages - 1 : pages;

    setPageBreaks(~~currentPageBreaks);
  };

  useEffect(() => {
    if (!pageRef) return;
    const pageElement = pageRef;
    const resizeObserver = new ResizeObserver(() => {
      appendToPageBreaks(pageElement.offsetHeight || 0);
      setLeftPosition(0 - PAGE_BREAKERS_WIDTH);
    });
    resizeObserver.observe(pageElement);
    return () => resizeObserver.disconnect();
  }, [pageRef, appendToPageBreaks]);

  return { pageBreaks, leftPosition };
};
