import {
  SecondaryMenuTrigger,
  DropDownSection,
  NumInputBoxTwoCol,
  ColorPickerSection,
  SecondaryMenu,
  SecondaryMenuSectionTitle,
} from '../../../component';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useContext } from 'react';
import { SidePanelProviderContext } from '../../SidePanelModelsProvider';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { getBlockSidesFromString } from '../../../../GridDndEditor/Block/Helper/BlockSides';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { BlockStyleSettingsContext } from '../AdvancedSpacing/BlockSettingsProvider';
import { AdvancedSpacingContext } from '../AdvancedSpacing';

export function TextBlockSettings() {
  const { t } = useTranslation();
  const { setToggledDesignSettingModelType } = useContext(SidePanelProviderContext);
  const { setIsAdvancedSpacingModelOpen } = useContext(AdvancedSpacingContext);
  const { onBorderRadiusChange, onBorderWidthChange, setBlockBorderSide, setBackgroundColor, setBlockBorderColor, getBlockStyle } =
    useContext(BlockStyleSettingsContext);
  const { selectedBlockIdByIcon: blockId } = useContext(SelectionContext);

  const { borderColor, currentBorderSide, borderWidth, borderRadius = 0, backgroundColor } = getBlockStyle(blockId);

  const handleSetIsActive = (isActive) => {
    if (isActive) setToggledDesignSettingModelType(GridBlockType.TEXT);
    else setToggledDesignSettingModelType(null);
  };

  return (
    <SecondaryMenu
      testId={'textbox-settings-component'}
      menuTitle={t('document.grid.text.design.title')}
      backButtonOnClick={handleSetIsActive}
    >
      <Stack rowGap="16px" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.text.design.background')}</SecondaryMenuSectionTitle>
        <ColorPickerSection currentColor={backgroundColor} handleColorChange={setBackgroundColor} />
      </Stack>
      <Stack rowGap="16px" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.text.design.border')}</SecondaryMenuSectionTitle>
        <ColorPickerSection currentColor={borderColor} handleColorChange={setBlockBorderColor} />
        <DropDownSection
          currentSelection={currentBorderSide}
          handleSelectionChange={(borderSideStr) => {
            setBlockBorderSide(getBlockSidesFromString(borderSideStr));
          }}
          testId={'border-dropdown'}
        />
        <NumInputBoxTwoCol
          inputValue={borderWidth}
          onChange={onBorderWidthChange}
          title={t('document.grid.text.design.border_width')}
          testId={'border-width'}
          disableNegativeNum
        />
        <NumInputBoxTwoCol
          inputValue={borderRadius}
          onChange={onBorderRadiusChange}
          title={t('document.grid.text.design.border_radius')}
          testId={'border-radius'}
          disableNegativeNum
        />
      </Stack>
      <SecondaryMenuTrigger
        menuTriggerTitle={t('document.grid.text.advanced_spacing.title')}
        menTriggerDesc={t('document.grid.text.advanced_spacing.padding')}
        handleClick={() => {
          setIsAdvancedSpacingModelOpen(true);
        }}
      />
    </SecondaryMenu>
  );
}
