import './styles.less';
import { useTranslation } from 'react-i18next';
import { Loading } from 'components';
import { useAPIClientQuery } from '../useAPIClientsQuery';
import { TextField, Button, InputLabel } from '@mui/material';
import { useCurrentUser } from 'providers/UserProvider';

export const ManageAPIClients = () => {
  const { t } = useTranslation();
  const {
    data: { id: userId },
  } = useCurrentUser();
  const { isLoading: isAPIClientLoading } = useAPIClientQuery(userId);

  return (
    <div className="api-clients__container">
      {isAPIClientLoading && (
        <div className="api-clients__loader">
          <Loading isLoading={isAPIClientLoading} />
        </div>
      )}
      <form>
        <div className="api-clients__form">
          <div className="api-clients__form__item">
            <InputLabel>Name</InputLabel>
            <div className="api-clients__form__item-input">
              <TextField fullWidth />
            </div>
          </div>

          <div className="api-clients__form__item">
            <InputLabel>Redirect URIs</InputLabel>
            <div className="api-clients__form__item-input">
              <TextField rows={4} multiline fullWidth />
            </div>
          </div>

          <div className="api-clients__form__item">
            <InputLabel>ID</InputLabel>
            <div className="api-clients__form__item-input">
              <TextField fullWidth />
            </div>
          </div>

          <div className="api-clients__save_button">
            <Button variant="contained" disabled={isAPIClientLoading}>
              {t('settings.connect_api.api_clients.save_button')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
