import { useQuery } from 'react-query';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';
import { QueryKeys } from 'utils/queryKeys';

export const useGetPreviewGridByDocId = (documentId: string) => {
  const result = useQuery({
    queryKey: QueryKeys.getGridsByDocumentId(documentId),
    queryFn: () => apiDocumentRepository.getGridsByDocumentId(documentId, true),
    enabled: !!documentId,
  });

  return { status: result.status, gridBlocksPreFormatted: result.data };
};
