import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';

import EditorPageSignatures from './EditorPageSignatures';
import { setupTrackedBlocks } from './gridHelper';
import { TrackedBlockCollection } from './models/TrackedBlockCollection.model';
import EditorPageBlocks from './EditorPageBlocks';
import { GridImageLoader } from './Block/Image/GridImageLoader/GridImageLoader';
import DragLines from './DragLines/DragLines';
import Placeholder from './Block/Placeholder/Placeholder';
import { columns, rows } from './Block/Table';
import { SelectionContext } from './SelectedBlockInfoProvider';

import { SignaturesContext, SignatureSocketWriteOperationPayload } from '../providers/SignaturesProvider';
import { GridBlockType } from '../shared/gridBlockType';
import { gridDefaultDimensionsOnDrop, gridPageMaxWidthInPixels, gridPageMinHeightInPixels } from '../shared/gridConfig';
import { EditorConfig } from '../helpers/config';
import { RootState, rootStore } from '../grid/reduxStore/Store';
import { dragDropManager } from '../dndContext';
import DocumentLockedModal from '../modals/DocumentLockedModal';
import { useDocumentLock } from '../modals/useDocumentLock';
import { DocumentSettingsContext } from '../SidePanel/document-settings/DocumentDesignSettings/DocumentSettingsContext';
import { toggleBlueBorderClass } from '../ImageLibrary/helpers';
import { AdvancedSpacingContext } from '../SidePanel/content';
import { useBlockAddedHandler } from '../hooks/UseBlockAddedHandler';

import './GridDndEditor.less';
import '../../../styles/tokens.less';
import { selectContentMaxPageHeight, selectCurrentDraggedBlock } from '../grid/reduxStore/editorSlice';

export type EditorPageBlocksProps = {
  documentId: string;
  configOptions?: EditorConfig;
};

export type GridDndEditorProps = EditorPageBlocksProps & {
  gridRef: React.MutableRefObject<HTMLDivElement | null>;
};

const PlaceHolderWrapper = ({ trackedBlocks }: TrackedBlockCollection) => {
  const placeholderPosition = { x: trackedBlocks.x, y: trackedBlocks.y };
  const placeholderDimension = { width: trackedBlocks.width, height: trackedBlocks.height };
  return <Placeholder position={placeholderPosition} dimension={placeholderDimension} />;
};

export default function GridDndEditor({ documentId, configOptions, gridRef }: GridDndEditorProps) {
  const { signatures } = useContext(SignaturesContext);
  const blocksContent = useSelector((state: RootState) => {
    return state.gridBlockReducer.blocksContent;
  });
  const trackedBlocks = setupTrackedBlocks(blocksContent, signatures);
  const currentDraggedBlock = useSelector((state: RootState) => selectCurrentDraggedBlock(state));

  const { isDocumentLocked } = useDocumentLock();
  const blockAddedHandler = useBlockAddedHandler();
  const { checkDocumentLockStatus } = useDocumentLock();
  const { handleSignatureInsert } = useContext(SignaturesContext);
  const { documentSettings, setShowMargins } = useContext(DocumentSettingsContext);
  const { setSelectedBlockIdByWrapper, setSelectedBlockIdByIcon, selectedBlockIdByWrapper } = useContext(SelectionContext);
  const { setIsAdvancedSpacingModelOpen } = useContext(AdvancedSpacingContext);
  const maxHeightPage = useSelector((state: RootState) => selectContentMaxPageHeight(state));
  const maxHeight = Math.max(maxHeightPage, gridPageMinHeightInPixels);

  const editorPageStyle = {
    height: maxHeight,
    backgroundColor: documentSettings.backgroundColor,
  };

  const [, drop] = useDrop(() => ({
    accept: [GridBlockType.TEXT, GridBlockType.SIGNATURE, GridBlockType.IMAGE, GridBlockType.TABLE],
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item, monitor) => {
      const offset = monitor.getClientOffset();
      const offsetInitialClientOffset = monitor.getInitialClientOffset();
      const offsetInitialSource = monitor.getInitialSourceClientOffset();

      if (offset && gridRef.current && offsetInitialClientOffset && offsetInitialSource) {
        const boundingBox = gridRef.current.getBoundingClientRect();
        const droppedX = offset.x - boundingBox.x;
        const droppedY = offset.y - boundingBox.y;
        const itemType = monitor.getItemType() as GridBlockType;
        const blockDimension = gridDefaultDimensionsOnDrop[itemType];
        const blockConfig = {
          x: droppedX,
          y: droppedY,
          z: rootStore.getState().gridBlockReducer.blocksLayer.greaterZIndexAvailable,
          ...blockDimension,
        };

        if (itemType === GridBlockType.TEXT) {
          blockAddedHandler('', blockConfig, itemType).then((gridAddedSocketResponseType) => {
            checkDocumentLockStatus(gridAddedSocketResponseType.errorCode);
          });
        } else if (itemType === GridBlockType.TABLE) {
          const tableContent = {
            rows,
            columns,
            metadata: {},
          };
          blockAddedHandler(tableContent, blockConfig, itemType).then((gridAddedSocketResponseType) => {
            checkDocumentLockStatus(gridAddedSocketResponseType.errorCode);
          });
        } else if (itemType === GridBlockType.SIGNATURE && item) {
          const signatureItem = item as SignatureSocketWriteOperationPayload;
          const itemWidth = signatureItem.properties.dimensions.width;
          const itemHeight = signatureItem.properties.dimensions.height;
          const offsetInitialSourceRight = offsetInitialSource.x + itemWidth;
          const offsetInitialSourceDifference = offsetInitialSourceRight - offsetInitialClientOffset.x;
          const isSignaturePlacedOnEdge = droppedX + offsetInitialSourceDifference > boundingBox.width;
          const adjustedX = isSignaturePlacedOnEdge
            ? boundingBox.width - itemWidth
            : droppedX - (itemWidth - offsetInitialSourceDifference);

          const adjustedY = droppedY + itemHeight > boundingBox.height ? boundingBox.height - itemHeight : droppedY;
          const payload: SignatureSocketWriteOperationPayload = {
            ...signatureItem,
            properties: {
              dimensions: signatureItem.properties.dimensions,
              position: {
                x: adjustedX,
                y: adjustedY,
              },
            },
          };
          handleSignatureInsert(payload);
        }
      }
    },
  }));

  const onMouseDown = () => {
    setSelectedBlockIdByWrapper(null), setSelectedBlockIdByIcon(null), setIsAdvancedSpacingModelOpen(false);
  };

  function combinedRef(el) {
    drop(el);
    if (el) {
      gridRef.current = el;
    }
  }

  const showLines = (state: boolean) => {
    toggleBlueBorderClass(state);
    setShowMargins(state);
  };

  const onDragLeaveHandler = () => {
    blocksContent[selectedBlockIdByWrapper as string].type === GridBlockType.TABLE && dragDropManager.getBackend().teardown();
    showLines(false);
  };

  return (
    <>
      <DocumentLockedModal isDocumentLocked={isDocumentLocked} documentId={documentId} />
      <div
        className="editor__page"
        ref={combinedRef}
        style={editorPageStyle}
        onMouseDown={onMouseDown}
        onDragOver={() => showLines(true)}
        onDragLeave={onDragLeaveHandler}
      >
        <GridImageLoader documentId={documentId} />
        <DragLines
          currentBlock={currentDraggedBlock}
          trackedBlockCollection={trackedBlocks}
          maxWidthPx={gridPageMaxWidthInPixels}
          maxHeightPx={maxHeight}
        />
        <div className="editor__page__container">
          {trackedBlocks['placeholder'] && <PlaceHolderWrapper trackedBlocks={trackedBlocks['placeholder']} />}
          <EditorPageBlocks documentId={documentId} configOptions={configOptions} />
          <EditorPageSignatures />
        </div>
      </div>
    </>
  );
}
