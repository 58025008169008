import React, { createContext, useContext, useEffect, useState } from 'react';
import { BlockSides } from '../../../../GridDndEditor/Block/Helper/BlockSides';
import { GridLoadType } from '../../../../grid/reduxStore/saveHandlers';
import { useCurrentUser } from '../../../../../../providers/UserProvider';
import { useSocketClient } from '../../../../../../providers/SocketContext';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { BlockEvents, DefaultSocketResponseType } from '../../../../../../services/socket/SocketEvents';

import { SaveStatusContext } from '../../../../providers/SaveStatusProvider';
import { DocumentSaveStatus } from '../../../../shared/models/DocumentSaveStatus';

export interface BlockSettings {
  updatedByUserId: number;
  blockId: string;
  borderStyle?: string;
  borderLeft?: number;
  borderRight?: number;
  borderTop?: number;
  borderBottom?: number;
  borderRadius?: number;
  borderColor?: string;
  backgroundColor?: string;
  opacity?: number;
  imageAlt?: string;
  imageLink?: string;
  [type: string]: any;
}

interface BlockStyleSettingsContextProps {
  onBorderWidthChange: (value: number) => void;
  onBorderRadiusChange: (value: number) => void;
  setCurrentBoarderSide: React.Dispatch<React.SetStateAction<BlockSides[]>>;
  loadBlockStyleSettings: (gridBlocks: GridLoadType[]) => void;
  currentBlockStyle: BlockSettings;
  getBlockStyle: (blockId: string | null) => BlockSettings;
  setBackgroundColor: (color: string) => void;
  addBlocksSettings: (blockId: string) => void;
  deleteBlocksSettings: (blockId: string) => void;
  setBlockBorderColor: (color: string) => void;
  setBlockBorderSide: (sides: string[]) => void;
  onDisplayChange: ({ opacity, imageAlt, imageLink }: { opacity: number; imageAlt: string; imageLink: string }) => void;
  updateBlockStyleSettings: (style: BlockSettings) => void;
  saveCurrentBlockStyle: (style: BlockSettings) => void;
}

export const BlockStyleSettingsContext = createContext<BlockStyleSettingsContextProps>({} as BlockStyleSettingsContextProps);

interface BlockStyleSettingsProviderProps {
  children: React.ReactNode;
}

export function BlockSettingsProvider({ children }: BlockStyleSettingsProviderProps) {
  const { data: currentUser } = useCurrentUser();
  const userId = currentUser.id;
  const socketClient = useSocketClient();
  const { selectedBlockIdByIcon: blockId } = useContext(SelectionContext);
  const [currentBorderSide, setCurrentBoarderSide] = useState<BlockSides[]>([BlockSides.ALL_SIDES]);
  const [blocksSettings, setBlocksSettings] = useState<{ [key: string]: BlockSettings }>({});
  const { setSaveStatus, updateSaveStatus } = useContext(SaveStatusContext);
  const initialBorderSettings = {
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    borderBottom: 0,
  };

  const defaultBlockStyle: BlockSettings = {
    updatedByUserId: userId,
    blockId: blockId || '',
    borderRadius: 0,
    borderColor: '#ffffffff',
    backgroundColor: '#ffffffff',
    opacity: 100,
    imageAlt: '',
    imageLink: '',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    currentTimestamp: '',
    currentBorderSide,
    borderWidth: 0,
    ...initialBorderSettings,
  };

  const generateAdditionalBlockSettings = (blockSettings) => {
    const filteredSides = Object.keys(BlockSides).filter((side: string) => BlockSides[side] !== BlockSides.ALL_SIDES);
    const getSidesFromSettings: string[] = [];
    filteredSides.forEach((s: string) => blockSettings[`border${BlockSides[s]}`] > 0 && getSidesFromSettings.push(BlockSides[s]));
    const borderWidth = getSidesFromSettings.length > 0 ? blockSettings[`border${getSidesFromSettings[0]}`] : 0;
    return { borderWidth, currentBorderSide: getSidesFromSettings };
  };

  const loadBlockStyleSettings = (gridBlocks) => {
    const blockStyleObject = {};
    gridBlocks.forEach((gridBlock) => {
      const { gridId, blockSettings } = gridBlock;

      if (blockSettings && gridId && blockSettings) {
        const additionalValues = generateAdditionalBlockSettings(blockSettings);
        blockStyleObject[gridId] = { ...blockSettings, ...additionalValues };
      }
    });
    setBlocksSettings(blockStyleObject);
  };

  const addBlocksSettings = (blockId: string) => {
    if (blocksSettings[blockId] === undefined) {
      const addBlockSettings = {
        ...defaultBlockStyle,
        blockId,
      };

      const newState = {
        ...blocksSettings,
        [blockId]: {
          ...addBlockSettings,
        },
      };

      setBlocksSettings(newState);
      saveCurrentBlockStyle(addBlockSettings);
    }
  };

  const deleteBlocksSettings = (blockId: string) => {
    const newState = { ...blocksSettings };
    delete newState[blockId];
    setBlocksSettings(newState);
  };

  const currentBlockStyle: BlockSettings = (blockId && blocksSettings[blockId]) || defaultBlockStyle;

  const setBlockBackgroundColor = (bgColor: string) => {
    if (!blockId) return;
    const updatedCurrentBlockStyle = { ...currentBlockStyle };
    updatedCurrentBlockStyle.backgroundColor = bgColor;

    updateBlockStyleSettings(updatedCurrentBlockStyle);
    saveCurrentBlockStyle(updatedCurrentBlockStyle);
  };

  const setBlockBorderColor = (color: string) => {
    if (!blockId) return;
    const updatedCurrentBlockStyle = { ...currentBlockStyle };
    updatedCurrentBlockStyle.borderColor = color;

    updateBlockStyleSettings(updatedCurrentBlockStyle);
    saveCurrentBlockStyle(updatedCurrentBlockStyle);
  };

  const setBlockBorderSide = (sides: string[]) => {
    if (!blockId) return;

    let updatedCurrentBlockStyle = { ...currentBlockStyle };
    const { borderWidth } = updatedCurrentBlockStyle;
    const activeBorderSettings = {};

    if (!sides.length) {
      updatedCurrentBlockStyle = { ...updatedCurrentBlockStyle, ...initialBorderSettings };
    } else {
      sides.forEach((side: string) => {
        const borderName = `border${side}`;
        activeBorderSettings[borderName] = borderWidth;
      });
      updatedCurrentBlockStyle = { ...currentBlockStyle, ...initialBorderSettings, ...activeBorderSettings };
    }
    updatedCurrentBlockStyle.currentBorderSide = sides;

    updateBlockStyleSettings(updatedCurrentBlockStyle);
    saveCurrentBlockStyle(updatedCurrentBlockStyle);
  };

  const updateBlockStyleSettings = (style: BlockSettings) => {
    if (!blockId) return;
    setBlocksSettings((prevState) => ({ ...prevState, [blockId]: style }));
  };

  const getBlockStyle = (inputBlockId: string | null) => {
    if (inputBlockId && blocksSettings[inputBlockId]) return blocksSettings[inputBlockId];

    return defaultBlockStyle;
  };

  useEffect(() => {
    const activeBorderSides = currentBlockStyle.currentBorderSide ?? [BlockSides.ALL_SIDES];
    if (activeBorderSides.includes(BlockSides.LEFT) || activeBorderSides.includes(BlockSides.ALL_SIDES)) {
      currentBlockStyle.borderLeft = currentBlockStyle.borderWidth;
    }
    if (activeBorderSides.includes(BlockSides.RIGHT) || activeBorderSides.includes(BlockSides.ALL_SIDES)) {
      currentBlockStyle.borderRight = currentBlockStyle.borderWidth;
    }
    if (activeBorderSides.includes(BlockSides.TOP) || activeBorderSides.includes(BlockSides.ALL_SIDES)) {
      currentBlockStyle.borderTop = currentBlockStyle.borderWidth;
    }
    if (activeBorderSides.includes(BlockSides.BOTTOM) || activeBorderSides.includes(BlockSides.ALL_SIDES)) {
      currentBlockStyle.borderBottom = currentBlockStyle.borderWidth;
    }
  }, [
    currentBlockStyle.currentBorderSide,
    blockId,
    currentBlockStyle.borderLeft,
    currentBlockStyle.borderRight,
    currentBlockStyle.borderBottom,
    currentBlockStyle.borderTop,
  ]);

  const onBorderWidthChange = (value) => {
    const newState: BlockSettings = { ...currentBlockStyle, ...initialBorderSettings };
    newState.borderWidth = value;

    const currentBorderSide = newState.currentBorderSide ?? [BlockSides.ALL_SIDES];
    if (currentBorderSide.includes(BlockSides.LEFT) || currentBorderSide.includes(BlockSides.ALL_SIDES)) {
      newState.borderLeft = value;
    }
    if (currentBorderSide.includes(BlockSides.RIGHT) || currentBorderSide.includes(BlockSides.ALL_SIDES)) {
      newState.borderRight = value;
    }
    if (currentBorderSide.includes(BlockSides.TOP) || currentBorderSide.includes(BlockSides.ALL_SIDES)) {
      newState.borderTop = value;
    }
    if (currentBorderSide.includes(BlockSides.BOTTOM) || currentBorderSide.includes(BlockSides.ALL_SIDES)) {
      newState.borderBottom = value;
    }

    updateBlockStyleSettings(newState);
    saveCurrentBlockStyle(newState);
  };

  const onBorderRadiusChange = (value) => {
    currentBlockStyle.borderRadius = value;

    updateBlockStyleSettings(currentBlockStyle);
    saveCurrentBlockStyle(currentBlockStyle);
  };

  const onDisplayChange = ({ opacity, imageAlt, imageLink }) => {
    if (!blockId) return;

    const displayStyles = { opacity, imageAlt, imageLink };

    const updatedStyles = {
      ...currentBlockStyle,
      ...displayStyles,
    };

    updateBlockStyleSettings(updatedStyles);
    saveCurrentBlockStyle(updatedStyles);
  };

  const saveCurrentBlockStyle = (style: BlockSettings) => {
    // clean state for editor-server
    const styleToSave = { ...style };
    delete styleToSave.borderWidth;
    delete styleToSave.currentBorderSide;

    setSaveStatus(DocumentSaveStatus.SAVING);
    socketClient.publish(BlockEvents.BLOCK_SETTINGS_SAVE, styleToSave, (responseParsed: DefaultSocketResponseType) => {
      updateSaveStatus({ status: responseParsed.status, errorCode: responseParsed.errorCode });
      return;
    });
  };

  const contextValue: BlockStyleSettingsContextProps = {
    onBorderWidthChange,
    onBorderRadiusChange,
    setCurrentBoarderSide,
    loadBlockStyleSettings,
    currentBlockStyle,
    getBlockStyle,
    onDisplayChange,
    addBlocksSettings,
    deleteBlocksSettings,
    saveCurrentBlockStyle,
    setBackgroundColor: setBlockBackgroundColor,
    setBlockBorderColor,
    setBlockBorderSide,
    updateBlockStyleSettings,
  };

  return <BlockStyleSettingsContext.Provider value={contextValue}>{children}</BlockStyleSettingsContext.Provider>;
}
