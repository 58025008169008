import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { ReactNode } from 'react';

type CustomContextMenuOption = {
  title: string;
  callback: () => void;
};

type CustomContextMenuProps = {
  children: ReactNode;
  options: CustomContextMenuOptions;
};

type ContextMenuEvent = React.MouseEvent<HTMLDivElement> & {
  target: HTMLElement;
};

export type CustomContextMenuOptions = CustomContextMenuOption[];
export const CustomContextMenu = ({ options, children }: CustomContextMenuProps) => {
  const [contextMenu, setContextMenu] = React.useState<{
    mouseXAxis: number;
    mouseYAxis: number;
  } | null>(null);
  const handleContextMenu = (event: ContextMenuEvent) => {
    const contextMenuXAxisGap = 2,
      contextMenuYAxisGap = -6;

    event.preventDefault();
    setContextMenu(
      contextMenu === null ? { mouseXAxis: event.clientX + contextMenuXAxisGap, mouseYAxis: event.clientY + contextMenuYAxisGap } : null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };
  const handleClick = (callback: () => void) => {
    setContextMenu(null);
    callback();
  };

  return (
    <div onContextMenu={handleContextMenu}>
      {children}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseYAxis, left: contextMenu.mouseXAxis } : undefined}
      >
        {options.map((item, itemKey) => {
          return (
            <MenuItem
              key={itemKey}
              onClick={() => {
                handleClick(item.callback);
              }}
            >
              {item.title}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
