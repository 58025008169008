import React, { useRef } from 'react';
import { GridDndEditor } from '../GridDndEditor';
import { EditorConfig } from '../helpers/config';
import { Box } from '@mui/material';
import { PageRefType, useHandlePageBreaks } from '../pagebreaks/useHandlePageBreaks';
import { SectionToolbar } from './SectionToolbar';
import PageBreaks from '../pagebreaks';
import './Sections.less';
interface EditorContentProps {
  documentId: string;
  configOptions: EditorConfig;
  gridRef: React.RefObject<HTMLDivElement>;
  toolbarButtons?: any;
  addButtonOnClick: () => void;
  deleteButtonOnClick: (canvasId: number) => void;
  canvasId: number;
  enableDelete: boolean;
}

const CreatePageBreaks = ({ pageRef }: { pageRef: PageRefType }) => {
  const { pageBreaks, leftPosition } = useHandlePageBreaks(pageRef);

  if (!pageRef) return null;

  return <PageBreaks leftPosition={leftPosition} pageBreaks={pageBreaks} />;
};

const Canvas: React.FC<EditorContentProps> = ({
  documentId,
  configOptions,
  gridRef,
  addButtonOnClick,
  deleteButtonOnClick,
  canvasId,
  enableDelete,
}) => {
  const pageRef = useRef<HTMLDivElement>(null);

  return (
    <Box sx={{ marginTop: '12px', position: 'relative' }} data-py-canvas-id={canvasId} className={`canvas_${canvasId}`}>
      <SectionToolbar
        enableDelete={enableDelete}
        addButtonOnClick={addButtonOnClick}
        deleteButtonOnClick={() => deleteButtonOnClick(canvasId)}
      />

      <Box ref={pageRef} className="section-wrapper">
        <GridDndEditor documentId={documentId} gridRef={gridRef} configOptions={configOptions} />
        <CreatePageBreaks pageRef={pageRef.current} />
      </Box>
    </Box>
  );
};

export default Canvas;
