import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { CSSProperties, useLayoutEffect, useRef } from 'react';
import { cellFocusOutEventKeys } from '../../components/editor/GridDndEditor/Block/Table/variables';

export const DataGridCustomAutoGrowingInput = (props: GridRenderEditCellParams) => {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = useRef<HTMLInputElement>(null);

  const handleValueChange = (newValue: string) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  useLayoutEffect(() => {
    if (ref.current) {
      if (hasFocus) {
        ref.current.focus();
      } else {
        handleValueChange(ref.current.textContent || '');
      }
    }
  }, [hasFocus]);

  useLayoutEffect(() => {
    if (ref.current && ref.current.textContent !== value) {
      ref.current.textContent = value;
    }
  }, [value]);

  const style: CSSProperties = {
    width: '100%',
    outline: 'none',
    resize: 'none',
    fontWeight: 'normal',
    color: '#000',
  };

  return (
    <div
      ref={ref}
      style={style}
      role="textbox"
      contentEditable
      suppressContentEditableWarning
      onKeyDown={(event) => {
        if (cellFocusOutEventKeys.includes(event.key)) handleValueChange(event.currentTarget.textContent || '');
      }}
      onBlur={(event) => handleValueChange(event.currentTarget.textContent || '')}
    >
      {value}
    </div>
  );
};
