import React from 'react';
import { Box } from '@mui/material';
import { CreateAssetButton } from './components/create-asset-button';
import { AssetList } from './components/asset-list';
import { useContentLibraryListQuery } from 'hooks/useContentLibraryListQuery';
import { EmptyState } from './components/empty-state';

const LibraryPage: React.FC = () => {
  const { visibleAssetsList, isLoading, isAssetListLoaded } = useContentLibraryListQuery();

  return (
    <Box display="flex" className="content-library-page-container" data-testid="content-library-page-container">
      <Box display="flex" justifyContent="flex-end" width={'244px'}>
        <CreateAssetButton />
      </Box>
      <Box bgcolor={'white'} flexGrow={1} borderRadius={'28px'} marginLeft="24px">
        {isAssetListLoaded && !isLoading && visibleAssetsList.length === 0 ? <EmptyState /> : <AssetList />}
      </Box>
    </Box>
  );
};

export default LibraryPage;
