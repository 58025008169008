import GridDeleteComponent from '../GridDeleteComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../grid/reduxStore/Store';
import './BlockSettingsToolsContainer.less';
import BlockSettingsIconButton from './BlockSettingsIconButton';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
interface BlockSettingsToolsProps {
  blockId: string;
}

export function BlockSettingsToolsContainer({ blockId }: BlockSettingsToolsProps) {
  const blockContent = useSelector((state: RootState) => {
    return state.gridBlockReducer.blocksContent[blockId];
  });

  return (
    <div className="block-settings-container">
      <GridDeleteComponent blockId={blockId} />
      {/* TODO add design setting to table */}
      {(blockContent.type === GridBlockType.TEXT || blockContent.type === GridBlockType.IMAGE) && (
        <BlockSettingsIconButton blockId={blockId} type={GridBlockType[blockContent.type]} />
      )}
    </div>
  );
}
