import { Document } from 'services/documents/entities/Document';
import { DocumentAPIUpdateResponse, DocumentImageLibraryApiResponse } from '../implementations/ApiDocumentRepository';
import { ProposalShareEmailPayload } from './ProposalRepository';
import { ProspectType } from './ProspectRepository';
import { PromiseAllSettledResult } from 'interfaces/PromisedAllSettledResult';
import { GridBlockType } from '../../../components/editor/shared/gridBlockType';
import { BlockSettings, TableType } from '../../../components/editor/grid/reduxStore/saveHandlers';

export type CreateDocumentPayload = Partial<{
  title: string;
}>;

export interface UpdateDocumentPayload {
  title?: string;
  price?: number | null;
  assignedUser?: { id: number } | null;
  prospect?: { id: number; type: ProspectType; contact?: { id: number; name: string; email: string } | null } | null;
  trash?: boolean;
  documentId: string;
}

export interface CreateDocumentApiResponse {
  document_id: string;
}

export interface CreateDocumentResponse {
  documentId: string; // uuid
}

interface BaseProspect {
  id: number;
  name: string;
  uid: string;
}

interface CompanyProspect extends BaseProspect {
  type: ProspectType.Company;
  contact: {
    id: number;
    name: string;
    email: string;
  } | null;
}

interface ContactProspect extends BaseProspect {
  type: ProspectType.Person;
  contact: {
    id: number;
    name: string;
    email: string;
  } | null;
}
export type DocumentProspect = CompanyProspect | ContactProspect;
export interface GetDocumentEntity {
  id: string;
  title: string;
  price: number;
  status: DocumentStatus;
  isLocked: boolean;
  company: { id: number; name: string } | null;
  assignedUser: { id: number; name: string } | null;
  prospect: DocumentProspect | null;
  isDocumentBelongsToSameAccount: boolean;
}

export enum DocumentStatus {
  Won = 'WON',
  Lost = 'LOST',
  Draft = 'DRAFT',
}

interface DocumentListResponse {
  documents: Document[];
}

interface SigneesQueryParams {
  searchText?: string;
  offset: number;
  limit: number;
}

export interface SigneeInterface {
  uid: string;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  signeeType: 'USER' | 'PERSON';
  fullName: string;
}
interface SigneesResponse {
  signees: Array<SigneeInterface>;
}

export interface ShareDocumentApiPayload
  extends Pick<ProposalShareEmailPayload, 'recipients' | 'bcc_emails' | 'cc_emails' | 'subject' | 'body'> {
  id: string;
}

export interface UploadDocumentImageResponse {
  filename: string;
  link: string;
}

export interface DocumentImageLibraryPayload {
  documentId: string;
  images: File[];
}

export type GridsByDocumentIdResponse = {
  gridId: string;
  htmlContent: string;
  content?: TableType;
  position: {
    top_px: number;
    left_px: number;
    z_index: number;
  };
  dimensions: {
    width_px: number;
    height_px: number;
  };
  type: GridBlockType;
  userId: number;
} & {
  blockSettings: BlockSettings;
};

export interface DocumentRepository {
  createDocument: (payload?: CreateDocumentPayload) => Promise<CreateDocumentResponse>;
  updateDocument: (payload: UpdateDocumentPayload) => Promise<DocumentAPIUpdateResponse>;
  getDocumentById: (documentId: string, isPreview?) => Promise<GetDocumentEntity>;
  getGridsByDocumentId: (documentId: string, isPreview?: boolean) => Promise<GridsByDocumentIdResponse[]>;
  list: () => Promise<DocumentListResponse>;
  getSignees: (accountId: number, params: SigneesQueryParams) => Promise<SigneesResponse>;
  shareDocument: (payload: ShareDocumentApiPayload) => Promise<void>;
  getDocumentLibraryImages: (documentId: string) => Promise<DocumentImageLibraryApiResponse>;
  uploadDocumentLibraryImages: (payload: DocumentImageLibraryPayload) => Promise<PromiseAllSettledResult<UploadDocumentImageResponse>[]>;
}
