import { deleteBlockWithSocket, GridDeletedSocketResponseType } from '../grid/reduxStore/gridBlockSocketActions';
import { BlockContent, deleteGridBlockState } from '../grid/reduxStore/editorSlice';
import { useAppDispatch } from '../grid/reduxStore/Store';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useSocketClient } from '../../../providers/SocketContext';
import { useContext } from 'react';
import { SaveStatusContext } from '../providers/SaveStatusProvider';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { UndoRedoManager } from '../UndoRedo/UndoRedoManager';
import { useBlockAddedHandlerWithoutUndoRedo } from './UseBlockAddedHandler';
import { UndoRedoCommandForBlocks } from '../UndoRedo/UndoRedoCommand';

type GridBlockDeletedHandlerType = (blockId: string, blockContent: BlockContent) => Promise<GridDeletedSocketResponseType>;
type GridBlockDeletedHandlerWithoutUndoRedoType = (blockId: string) => Promise<GridDeletedSocketResponseType>;

function useUndoRedoBlockDeletedHandler(gridBlockDeletedHandlerWithoutUndoRedo: GridBlockDeletedHandlerWithoutUndoRedoType) {
  const blockAddedHandlerWithoutUndoRedo = useBlockAddedHandlerWithoutUndoRedo();
  return (blockId: string, blockContent: BlockContent) => {
    const undoRedoManager = UndoRedoManager.getUndoRedoManager();
    const { content, blockConfig, type: blockType, contentTable } = blockContent;
    const undoCallback = async (undoRedoCommand: UndoRedoCommandForBlocks): Promise<boolean> => {
      try {
        const blockContent = contentTable ? contentTable : content;
        const gridAddedSocketResponse = await blockAddedHandlerWithoutUndoRedo(blockContent, blockConfig, blockType);
        const newBlockId = gridAddedSocketResponse.content.gridId;
        undoRedoCommand.patchBlockId(newBlockId);
      } catch (error) {
        return false;
      }
      return true;
    };

    const redoCallback = async (undoRedoCommand: UndoRedoCommandForBlocks): Promise<boolean> => {
      try {
        const mappedBlockId = undoRedoCommand.getMappedBlockId(blockId);
        await gridBlockDeletedHandlerWithoutUndoRedo(mappedBlockId);
      } catch (error) {
        return false;
      }
      return true;
    };

    undoRedoManager.pushUndoRedoCommands(new UndoRedoCommandForBlocks(blockId, undoCallback, redoCallback));
  };
}

export function useBlockDeletedHandler(): GridBlockDeletedHandlerType {
  const gridBlockDeletedHandlerWithoutUndoRedo = useGridBlockDeletedHandlerWithoutUndoRedo();
  const undoRedoBlockDeletedHandler = useUndoRedoBlockDeletedHandler(gridBlockDeletedHandlerWithoutUndoRedo);
  return async (blockId, blockContent) => {
    const gridDeletedSocketResponse = await gridBlockDeletedHandlerWithoutUndoRedo(blockId);
    undoRedoBlockDeletedHandler(blockId, blockContent);
    return gridDeletedSocketResponse;
  };
}
export function useGridBlockDeletedHandlerWithoutUndoRedo(): GridBlockDeletedHandlerWithoutUndoRedoType {
  const dispatch = useAppDispatch();
  const { data } = useCurrentUser();
  const userId = data.id;
  const socketClient = useSocketClient();
  const { setSaveStatus, updateSaveStatus } = useContext(SaveStatusContext);

  return async (blockId) => {
    setSaveStatus(DocumentSaveStatus.SAVING);
    return dispatch(
      deleteBlockWithSocket({
        blockId,
        socketClient,
        userId,
        callback: ({ status, errorCode }) => {
          updateSaveStatus({ status, errorCode });
          dispatch(deleteGridBlockState({ blockId }));
        },
      })
    ).unwrap();
  };
}
