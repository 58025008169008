import React from 'react';
import { Modal } from 'components';
import { useTranslation } from 'react-i18next';
import './style.less';

type GridDeleteWarningModelProps = {
  isDeleteRequested: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

const GridDeleteWarningModal: React.FC<GridDeleteWarningModelProps> = ({ isDeleteRequested, handleOk, handleCancel }) => {
  const { t } = useTranslation();
  const affirmButtonVariant = 'negative';

  return (
    <Modal
      isVisible={isDeleteRequested}
      handleOk={handleOk}
      handleCancel={handleCancel}
      affirmText={t('document.grid.delete.warning.ok_button_text')}
      cancelText={t('document.grid.delete.warning.cancel_button_text')}
      title={t('document.grid.delete.warning.title')}
      closable={false}
      maskClosable={false}
      escClosable={false}
      affirmButtonVariant={affirmButtonVariant}
    >
      <div className={'grid-delete-model-body'}>
        <p>{t('document.grid.delete.warning.desc')}</p>
      </div>
    </Modal>
  );
};

export default GridDeleteWarningModal;
