import { useState } from 'react';
import { ErrorCode } from '../../../services/socket/SocketEvents';

export function useDocumentLock() {
  const [isDocumentLocked, setIsDocumentLocked] = useState<boolean>(false);

  const checkDocumentLockStatus = (errorCode: ErrorCode) => {
    if (errorCode === ErrorCode.LOCKED) {
      setIsDocumentLocked(true);
      return true;
    } else {
      return false;
    }
  };

  return {
    isDocumentLocked,
    checkDocumentLockStatus,
    setIsDocumentLocked,
  };
}
