import { GridAddType } from '../grid/reduxStore/saveHandlers';
import { gridPixelSize, gridSnapDistanceInPx } from '../shared/gridConfig';
import { BlocksContentCollection, BlocksMetadataCollection, GridState } from '../grid/reduxStore/editorSlice';
import { getSignaturesMaxHeight } from '../SidePanel/Signatures/SignatureHelper';
import { SignatureBox } from 'services/repositories/interfaces/SignatureRepository';
import { BlockConfig } from './models/BlockConfig.model';
import { TrackedBlockCollection } from './models/TrackedBlockCollection.model';
import { Margin } from '../SidePanel/document-settings/DocumentDesignSettings/models/Margin.model';
import { TableRowTypes } from '../../../muiTheme/MuiDataGrid';

export const maxImageWidthAllowedInPx = 500;
export const maxImageHeightAllowedInPx = 500;

export function mapRowType(rowTypeString: string): string {
  switch (rowTypeString) {
    case 'HEADER':
      return TableRowTypes.HEADER;
    case 'BODY':
      return TableRowTypes.BODY;
    case 'FOOTER':
      return TableRowTypes.FOOTER;
    default:
      return TableRowTypes.BODY;
  }
}

export function convertGridResponseToGridContentBlocks(gridsData: GridAddType[]): GridState {
  return gridsData.reduce(
    (reducedData, key) => {
      if (key.content === undefined) {
        key.content = {
          rows: [],
          columns: [],
          metadata: {
            column_metadata: [],
            row_metadata: [],
          },
        };
      }

      // Use TableRowTypes directly to map row types
      const convertedRows = key.content.rows.map((row) => ({
        ...row,
        rowType: mapRowType(row.rowType.toUpperCase()),
      }));

      reducedData['blocksMetadata'].push({ id: key.gridId as string, type: key.type });
      reducedData['blocksContent'][key.gridId as string] = {
        content: key.htmlContent,
        contentTable: { ...key.content, rows: convertedRows },
        type: key.type,
        blockConfig: {
          id: key.gridId || '0',
          height: key.dimensions.height_px,
          width: key.dimensions.width_px,
          y: key.position.top_px,
          x: key.position.left_px,
          z: key.zIndex,
        },
      };
      reducedData.blocksLayer.greaterZIndexAvailable = Math.max(key.zIndex + 1, reducedData.blocksLayer.greaterZIndexAvailable);
      reducedData.blocksLayer.lowerZIndexAvailable = Math.min(key.zIndex - 1, reducedData.blocksLayer.lowerZIndexAvailable);
      return reducedData;
    },
    {
      blocksContent: {} as BlocksContentCollection,
      blocksMetadata: [] as BlocksMetadataCollection,
      blocksLayer: { greaterZIndexAvailable: 0, lowerZIndexAvailable: 0 },
      editorConfig: { currentDraggedBlock: null, maxHeightPage: 0 },
    }
  );
}

export function getEditorMaxHeight(blocksContent: BlocksContentCollection | object, ...otherMaxValues: number[]) {
  let gridBlockMaxHeight = 0;
  if (blocksContent) {
    gridBlockMaxHeight = Object.entries(blocksContent).reduce((currentMax, blockContent) => {
      return Math.max(currentMax, blockContent[1].blockConfig.y + (blockContent[1].blockConfig.height || 0));
    }, 0);
  }

  return Math.max(gridBlockMaxHeight, ...otherMaxValues) + gridPixelSize * gridPixelSize;
}

export function roundToNearestMultipleOfGridSize(value: number) {
  return Math.round(value / gridPixelSize) * gridPixelSize;
}

export const calculateImageWidthAndHeight = (actualWidth: number, actualHeight: number) => {
  if (actualWidth <= maxImageWidthAllowedInPx && actualHeight <= maxImageHeightAllowedInPx) {
    return { calculatedWidth: actualWidth, calculatedHeight: actualHeight };
  }

  let calculatedWidth = actualWidth;
  let calculatedHeight = actualHeight;

  if (actualWidth > maxImageWidthAllowedInPx) {
    const aspectRatio = actualHeight / actualWidth;
    calculatedWidth = maxImageWidthAllowedInPx;
    calculatedHeight = calculatedWidth * aspectRatio;
  }

  if (calculatedHeight > maxImageHeightAllowedInPx) {
    const newAspectRatioAfterSettingHeight = calculatedWidth / calculatedHeight;
    calculatedHeight = maxImageHeightAllowedInPx;
    calculatedWidth = calculatedHeight * newAspectRatioAfterSettingHeight;
  }

  return { calculatedWidth, calculatedHeight };
};

const isElementVisible = (el) => {
  const headerHeight = 125;
  const spaceBetweenCanvasAndHeader = 88;
  const clientToCanvasSpace = headerHeight + spaceBetweenCanvasAndHeader;
  const viewportTop = window.scrollY + (window.scrollY > spaceBetweenCanvasAndHeader ? headerHeight : 0);
  const viewportBottom = window.scrollY + window.innerHeight;
  const elementTop = clientToCanvasSpace + el.blockConfig?.y || el.properties.position.y;
  const elementBottom =
    clientToCanvasSpace +
    (el.blockConfig ? el.blockConfig.y + el.blockConfig.height : el.properties.position.y + el.properties.dimensions.height);
  return elementTop < viewportBottom && elementBottom > viewportTop;
};

export function setupTrackedBlocks(gridBlocks: BlocksContentCollection, signatures) {
  const collection = {};
  Object.values(gridBlocks).forEach((gridBlock) => {
    const isVisible = isElementVisible(gridBlock);
    collection[gridBlock.blockConfig.id] = {
      height: gridBlock.blockConfig.height,
      width: gridBlock.blockConfig.width,
      x: gridBlock.blockConfig.x,
      y: gridBlock.blockConfig.y,
      isVisible,
    };
  });

  signatures?.forEach((signature) => {
    const isVisible = isElementVisible(signature);
    collection[signature.signatureBoxId] = {
      height: signature.properties.dimensions.height,
      width: signature.properties.dimensions.width,
      x: signature.properties.position.x,
      y: signature.properties.position.y,
      isVisible,
    };
  });

  return collection;
}

export function calculatePageMaxHeight(gridBlocks: BlocksContentCollection, signatureBlocks?: SignatureBox[], activeBlock?: BlockConfig) {
  const pageMaxHeightBasedOnGridBlocks = getPageMaxHeightBasedOnGridBlocks(gridBlocks, activeBlock);
  const pageMaxHeightBasedOnSignatureBlocks = getSignaturesMaxHeight(signatureBlocks);
  if (pageMaxHeightBasedOnSignatureBlocks > pageMaxHeightBasedOnGridBlocks) {
    return pageMaxHeightBasedOnSignatureBlocks;
  }
  return pageMaxHeightBasedOnGridBlocks;
}

export function getPageMaxHeightBasedOnGridBlocks(gridBlocks: BlocksContentCollection, activeBlock?: BlockConfig) {
  let pageMaxHeight = 0;
  for (const key in gridBlocks) {
    let currentBlockPageHeight: number;

    /* gridBlocks parameter doesn't contain an updated state of a block that is currently being dragged,
    resized or having its content changed. So we rely on activeBlock being passed with the updated state. */
    if (activeBlock && key === activeBlock.id) {
      currentBlockPageHeight = activeBlock.height + activeBlock.y;
    } else {
      currentBlockPageHeight = gridBlocks[key].blockConfig.y + (gridBlocks[key].blockConfig.height || 0);
    }

    if (pageMaxHeight < currentBlockPageHeight) {
      pageMaxHeight = currentBlockPageHeight;
    }
  }
  return pageMaxHeight;
}

export function getSnapPosition(
  currentBlock: BlockConfig,
  trackedBlockCollection: TrackedBlockCollection,
  pageMaxWidthPx: number,
  margins: Margin
) {
  if (!currentBlock) {
    return { x: null, y: null };
  }

  const snapMatches = {
    xMatches: new Set<number>(),
    yMatches: new Set<number>(),
  };

  for (const [id, trackedData] of Object.entries(trackedBlockCollection)) {
    if (id === currentBlock.id) {
      continue;
    }

    const currentBlockHeight = currentBlock.height;
    const currentBlockWidth = currentBlock.width;
    const trackedBlockHeight = trackedData.height;
    const trackedBlockWidth = trackedData.width;

    const draggedBlockBottom = currentBlock.y + currentBlockHeight;
    const draggedBlockRight = currentBlock.x + currentBlockWidth;
    const draggedBlockMiddleX = currentBlock.x + currentBlockWidth / 2;
    const draggedBlockMiddleY = currentBlock.y + currentBlockHeight / 2;

    const trackedBlockBottom = trackedData.y + trackedBlockHeight;
    const trackedBlockRight = trackedData.x + trackedBlockWidth;
    const trackedBlockMiddleX = trackedData.x + trackedBlockWidth / 2;
    const trackedBlockMiddleY = trackedData.y + trackedBlockHeight / 2;

    const pageMiddleX = pageMaxWidthPx / 2;

    const currentBlockHalfWidth = currentBlock.width / 2;
    const currentBlockHalfHeight = currentBlock.height / 2;

    const marginRightXPx = pageMaxWidthPx - margins.right;

    // The following arrays contain the dragged block and tracked block's X and Y points
    // for the 4 sides of each block and their middle points, as well as page middle points.
    const currentBlockXPoints: number[] = [currentBlock.x, draggedBlockRight, draggedBlockMiddleX];
    const trackedBlockXPoints: number[] = [
      trackedData.x,
      trackedBlockRight,
      trackedBlockMiddleX,
      pageMiddleX,
      margins.left,
      marginRightXPx,
    ];
    const currentBlockYPoints: number[] = [currentBlock.y, draggedBlockBottom, draggedBlockMiddleY];
    const trackedBlockYPoints: number[] = [trackedData.y, trackedBlockBottom, trackedBlockMiddleY, margins.top];

    // We use these 2 arrays to adjust the resulting x and y values based on the currentBlock's
    // width and height during a snap action.
    const xAdjustment: number[] = [0, currentBlock.width, currentBlockHalfWidth];
    const yAdjustment: number[] = [0, currentBlock.height, currentBlockHalfHeight];

    // We use these 2 loops to track distance between the currentBlock and trackedBlock points.
    // If the distance between the 2 blocks is within the gridSnapDistanceInPx range on both
    // sides, we add the trackedBlock point to the snapMatches set, adjusting the resulting
    // x and y values based on the currentBlock's width and height.
    for (let i = 0; i < currentBlockXPoints.length; i++) {
      for (let j = 0; j < trackedBlockXPoints.length; j++) {
        const distance = currentBlockXPoints[i] - trackedBlockXPoints[j];

        if (distance >= -gridSnapDistanceInPx && distance <= gridSnapDistanceInPx) {
          snapMatches.xMatches.add(trackedBlockXPoints[j] - xAdjustment[i]);
        }
      }
    }

    for (let i = 0; i < currentBlockYPoints.length; i++) {
      for (let j = 0; j < trackedBlockYPoints.length; j++) {
        const distance = currentBlockYPoints[i] - trackedBlockYPoints[j];

        if (distance >= -gridSnapDistanceInPx && distance <= gridSnapDistanceInPx) {
          snapMatches.yMatches.add(trackedBlockYPoints[j] - yAdjustment[i]);
        }
      }
    }
  }

  // snap priority given to leftmost x and topmost y from each group
  const snapMatchX = snapMatches.xMatches.size ? Math.min(...Array.from(snapMatches.xMatches)) : null;
  const snapMatchY = snapMatches.yMatches.size ? Math.min(...Array.from(snapMatches.yMatches)) : null;

  return {
    x: snapMatchX,
    y: snapMatchY,
  };
}
