import { createAsyncThunk } from '@reduxjs/toolkit';
import { BlockEvents, DefaultSocketResponseType } from '../../../../services/socket/SocketEvents';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { Block } from 'components/editor/GridDndEditor/models/Block.model';
import SocketClient from '../../../../services/socket/SocketClient';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';

export type GridDefaultType = {
  gridId?: string;
  userId: number;
};

export type GridDeleteType = {
  gridId: string;
};

export type TableRowType = GridValidRowModel;
export type TableColumnType = GridColDef;

export type TableType = {
  rows: TableRowType[];
  columns: TableColumnType[];
  metadata: {
    column_metadata: object[];
    row_metadata: object[];
  };
};

export type GridContentChangedType = GridDefaultType & {
  htmlContent: string;
  content?: TableType;
};

export type GridPositionChangedType = GridDefaultType & {
  position: {
    top_px: number;
    left_px: number;
  };
};

export type GridDimensionChangedType = GridDefaultType & {
  dimensions: {
    width_px: number;
    height_px: number;
  };
};

export type GridLayerChangedType = GridDefaultType & {
  zIndex: number;
};

interface BlockSettingsDefault {
  blockType: GridBlockType;
  blockId: string;
  updatedByUserId: number;
  updatedAt: string;
}

type BaseBlockType = {
  borderLeft: number;
  borderRight: number;
  borderTop: number;
  borderBottom: number;
  borderColor: string;
  borderRadius: number;
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  paddingBottom: number;
};

export type TextBlockSettings = BaseBlockType & {
  backgroundColor: string;
};

export type ImageBlockSettings = BaseBlockType & {
  imageAlt: string;
  imageLink: string;
  opacity: number;
};

export type BlockSettings = (BlockSettingsDefault & TextBlockSettings) | (BlockSettingsDefault & ImageBlockSettings);

export type GridAddType = {
  type: GridBlockType;
} & GridContentChangedType &
  GridPositionChangedType &
  GridLayerChangedType &
  GridDimensionChangedType;

export type TableContentType = {
  rows: object[];
  columns: object[];
  metadata: object;
};

export type SaveTableContentType = TableContentType & GridDeleteType;

export type GridAddTableType = {
  type: GridBlockType;
  content: TableContentType;
} & GridPositionChangedType &
  GridLayerChangedType &
  GridDimensionChangedType;

export type GridLoadType = GridAddType & {
  blockSettings: BlockSettings;
};

export type GridAddedSocketResponseType = DefaultSocketResponseType & { content: { gridId: string } };

type GridDefaultSocketCallbackType = (response: DefaultSocketResponseType) => void;
type GridAddedSocketCallbackType = (response: GridAddedSocketResponseType) => void;

type SaveLayerWithSocketPayloadType = {
  gridBlockId: string;
  zIndex: number;
  userId: number;
  socketClient: SocketClient;
};

const createSocketPromise = <T>(socketClient: SocketClient, event: BlockEvents, data: any, callback: (response: T) => void): Promise<T> =>
  new Promise<T>((resolve) => {
    socketClient.publish(event, data, (response: T) => {
      callback(response);
      resolve(response);
    });
  });

export const addGridBlockWithSocket = createAsyncThunk(
  'editor-grid-block/saveContentWithSocket',
  async ({
    content,
    blockConfig,
    socketClient,
    userId,
    callback,
    type,
  }: {
    content: string;
    blockConfig: Block;
    socketClient: SocketClient;
    userId: number;
    callback: GridAddedSocketCallbackType;
    type: GridBlockType;
  }) => {
    const data: GridAddType = {
      userId,
      position: { top_px: blockConfig.y, left_px: blockConfig.x },
      zIndex: blockConfig.z,
      dimensions: { width_px: blockConfig.width || 0, height_px: blockConfig.height || 0 },
      type,
      htmlContent: content,
    };

    return createSocketPromise(socketClient, BlockEvents.BLOCK_ADDED, data, callback);
  }
);

export const addGridTableBlockWithSocket = createAsyncThunk(
  'editor-grid-block/saveContentWithSocket',
  async ({
    content,
    blockConfig,
    socketClient,
    userId,
    callback,
    type,
  }: {
    content: TableContentType;
    blockConfig: Block;
    socketClient: SocketClient;
    userId: number;
    callback: GridAddedSocketCallbackType;
    type: GridBlockType;
  }) => {
    const data: GridAddTableType = {
      userId,
      position: { top_px: blockConfig.y, left_px: blockConfig.x },
      zIndex: blockConfig.z,
      dimensions: { width_px: blockConfig.width || 0, height_px: blockConfig.height || 0 },
      type,
      content,
      gridId: '',
    };

    return createSocketPromise(socketClient, BlockEvents.BLOCK_ADDED, data, callback);
  }
);

export const saveContentWithSocket = createAsyncThunk(
  'editor-grid-block/saveContentWithSocket',
  async ({
    blockId,
    content,
    socketClient,
    userId,
    callback,
  }: {
    blockId: string;
    content: string;
    socketClient: SocketClient;
    userId: number;
    callback: GridDefaultSocketCallbackType;
  }) => {
    const data: GridContentChangedType = {
      gridId: blockId,
      htmlContent: content,
      userId,
    };

    return createSocketPromise(socketClient, BlockEvents.BLOCK_CONTENT_CHANGED, data, callback);
  }
);

export const saveTableContentWithSocket = createAsyncThunk(
  'editor-grid-block/saveContentWithSocket',
  async ({
    blockId,
    content,
    socketClient,
    callback,
  }: {
    blockId: string;
    content: TableContentType;
    socketClient: SocketClient;
    callback: GridDefaultSocketCallbackType;
  }) => {
    const data: SaveTableContentType = { gridId: blockId, ...content };

    return createSocketPromise(socketClient, BlockEvents.BLOCK_TABLE_CONTENT_CHANGED, data, callback);
  }
);

export const saveLayerWithSocket = createAsyncThunk(
  'editor-grid-block/saveLayerWithSocket',
  async ({ gridBlockId, userId, zIndex, socketClient }: SaveLayerWithSocketPayloadType) => {
    const data: GridLayerChangedType = {
      gridId: gridBlockId,
      userId,
      zIndex,
    };

    return createSocketPromise(socketClient, BlockEvents.BLOCK_LAYER_CHANGED, data, () => true);
  }
);

export const saveConfigWithSocket = createAsyncThunk(
  'editor-grid-block/saveConfigWithSocket',
  async ({
    blockId,
    x,
    y,
    width,
    height,
    eventType,
    userId,
    socketClient,
    callback,
  }: {
    blockId: string;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    eventType: BlockEvents;
    userId: number;
    socketClient: SocketClient;
    callback: GridDefaultSocketCallbackType;
  }) => {
    if (eventType === BlockEvents.BLOCK_POSITION_CHANGED) {
      const data: GridPositionChangedType = {
        gridId: blockId,
        userId,
        position: { top_px: y || 0, left_px: x || 0 },
      };

      return new Promise<void>((resolve) => {
        socketClient.publish(BlockEvents.BLOCK_POSITION_CHANGED, data, (response: DefaultSocketResponseType) => {
          callback(response);
          resolve();
        });
      });
    } else {
      const data: GridDimensionChangedType = {
        gridId: blockId,
        userId,
        dimensions: { width_px: width || 0, height_px: height || 0 },
      };

      return new Promise<void>((resolve) => {
        socketClient.publish(BlockEvents.BLOCK_DIMENSION_CHANGED, data, (response: DefaultSocketResponseType) => {
          callback(response);
          resolve();
        });
      });
    }
  }
);
