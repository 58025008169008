import { ReactNode } from 'react';
import { accessToken } from 'services/authorization/handleTokenCookie';
import DocumentBuilder from 'components/editor/DocumentBuilder/DocumentBuilder';
import { SignaturesProvider } from 'components/editor/providers/SignaturesProvider';
import { Provider } from 'react-redux';
import { rootStore } from '../../components/editor/grid/reduxStore/Store';
import { SocketProvider } from '../../providers/SocketContext';
import { SaveStatusProvider } from 'components/editor/providers/SaveStatusProvider';
import { SelectedBlockInfoProvider } from '../../components/editor/GridDndEditor/SelectedBlockInfoProvider';
import { BlockSettingsProvider } from '../../components/editor/SidePanel/content/designSettings/AdvancedSpacing/BlockSettingsProvider';

interface Props {
  contentId: string;
  children?: ReactNode;
}

export function EditorContentWrapper({ contentId, children }: Props) {
  return (
    <SocketProvider documentId={contentId} accessToken={accessToken as string}>
      <Provider store={rootStore}>
        <SaveStatusProvider>
          <SignaturesProvider documentId={contentId}>
            <SelectedBlockInfoProvider>
              <BlockSettingsProvider>
                {children}
                <DocumentBuilder documentId={contentId} />
              </BlockSettingsProvider>
            </SelectedBlockInfoProvider>
          </SignaturesProvider>
        </SaveStatusProvider>
      </Provider>
    </SocketProvider>
  );
}
