import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SignaturesContext, SignatureSocketWriteOperationPayload } from '../../providers/SignaturesProvider';
import { useDrag } from 'react-dnd';
import { getSignatureColor, SignatureBaseButton } from '../../shared/components/SignatureBaseButton';
import { dimensions } from '../../signatures/constants';
import { defaultSignature, SignatureButtonProps } from './SignatureButton';

export const DraggableSignatureButton: React.FC<SignatureButtonProps> = ({ signee }) => {
  const { t } = useTranslation();
  const { documentId } = useContext(SignaturesContext);

  const item = useMemo(() => {
    const signatureProperties = { ...defaultSignature.properties };
    let payload: SignatureSocketWriteOperationPayload = {
      ...defaultSignature,
      signatureBoxId: '',
      properties: signatureProperties,
    };
    if (signee != null) {
      payload = {
        ...payload,
        assignedSignee: signee,
      };
    }
    return payload;
  }, [documentId, signee]);

  const [{ isDragging }, drag] = useDrag({
    type: 'SIGNATURE',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item,
  });
  return (
    <SignatureBaseButton
      ref={drag}
      style={{
        width: dimensions.width,
        height: dimensions.height,
        opacity: isDragging ? 0.5 : 1,
      }}
      draggable={true}
      color={getSignatureColor(signee?.email)}
      title={signee?.email ?? t('unassigned')}
    >
      Signature
    </SignatureBaseButton>
  );
};
