import { useContext } from 'react';
import { IconButton } from '@mui/material';

import { SidePanelProviderContext, AdvancedSpacingContext } from '../../SidePanel/content';
import { SelectionContext } from '../SelectedBlockInfoProvider';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { BlockStyleSettingsContext } from '../../SidePanel/content/designSettings/AdvancedSpacing/BlockSettingsProvider';
import IcoTune from '../../../icon/icons/IcoTune';

interface BlockSettingsProps {
  type: GridBlockType;
  blockId: string | null;
}

function BlockSettings({ type, blockId }: BlockSettingsProps) {
  const classValue = type?.toUpperCase();
  const iconButtonSX = {
    width: '28px',
    height: '28px',
    color: 'white',
  };
  const { setToggledDesignSettingModelType } = useContext(SidePanelProviderContext);
  const { setIsAdvancedSpacingModelOpen } = useContext(AdvancedSpacingContext);
  const { setSelectedBlockIdByIcon } = useContext(SelectionContext);
  const { addBlocksSettings } = useContext(BlockStyleSettingsContext);

  return (
    <IconButton
      size={'small'}
      sx={iconButtonSX}
      onClick={() => {
        setToggledDesignSettingModelType(GridBlockType[type]);
        setIsAdvancedSpacingModelOpen(false);
        setSelectedBlockIdByIcon(blockId);
        blockId && addBlocksSettings(blockId);
      }}
      data-testid={`${classValue}-settings-icon`}
    >
      <IcoTune fontSize="inherit" />
    </IconButton>
  );
}

export default BlockSettings;
