import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Froala from 'froala-editor';
import FroalaEditor from 'react-froala-wysiwyg';

import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { EditorConfig, editorConfig } from '../../../helpers/config';
import { useTributeOnFroalaInit } from 'hooks/useTributeOnFroalaInit';
import { useGetVariables } from '../../../hooks/useVariables';
import { useUsedVariables } from '../../../../../hooks/useUsedVariables';
import { UsedVariable } from '../../../SidePanel/Variables/interfaces';
import { handleAllUsedVariables } from '../../../grid/reduxStore/usedVariablesSlice';
import UndoRedoPlugin from '../../../CustomFroalaPlugins/undo/UndoRedoPlugin';

import { debounce } from '../../../../../utils/debounce';
export type GridBlockContentChangeHandler = (blockId: string, content: string) => void;
export interface FroalaEditorProps {
  documentId: string;
  blockId: string;
  configOptions?: EditorConfig;
  documentContent?: string;
  editorResizeHandler?: (editorId: string, rect: DOMRectReadOnly) => void;
  contentChangeHandler?: GridBlockContentChangeHandler;
  isEditMode?: boolean;
  style: CSSProperties;
}

const FroalaTextbox = ({
  documentId,
  blockId,
  configOptions,
  documentContent: initialDocumentContent,
  editorResizeHandler,
  contentChangeHandler,
  isEditMode = false,
  style,
}: FroalaEditorProps) => {
  const editorId = `froala_editor_${blockId}`;
  const { data: variables } = useGetVariables(documentId);
  const editorRef = useRef<FroalaEditor>(null);
  const debounceDelay = 100;
  useTributeOnFroalaInit({ variables, editorRef: editorRef });

  const { handleUsedVariablesParsing } = useUsedVariables();
  const dispatch = useDispatch();
  const froalaEditorElement = document.querySelector(`.froala_editor_${blockId} .fr-wrapper .fr-element`);

  const [documentContent, setDocumentContent] = useState<string>(initialDocumentContent || '');
  const [IsOnlyTextStyleUpdate, setIsOnlyTextStyleUpdate] = useState<boolean>(false);

  const placeholderTextConfig = {
    placeholderText: editorConfig.placeholderText,
  };
  const handleUsedVariablesParsingCallback = (usedVariables: UsedVariable[]) => {
    dispatch(handleAllUsedVariables({ blockId, usedVariables }));
  };

  useEffect(() => {
    handleUsedVariablesParsing(documentContent, handleUsedVariablesParsingCallback);
    if (contentChangeHandler) {
      contentChangeHandler(blockId, documentContent);
    }

    return () => handleUsedVariablesParsingCallback([]);
  }, [documentContent]);

  const defaultConfig = {
    ...editorConfig,
    ...configOptions,
    ...placeholderTextConfig,
    ...{
      autofocus: true,
    },
  };

  useEffect(() => {
    if (!froalaEditorElement || !editorResizeHandler) return;

    const editorResizeHandlerDebounced = debounce((editorId: string, textbox: DOMRectReadOnly) => {
      if (editorResizeHandler) {
        editorResizeHandler(editorId, textbox);
      }
    }, debounceDelay);

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];

      // check if there are any initial value coming from drabble and trigger resizing
      if (documentContent || IsOnlyTextStyleUpdate) {
        editorResizeHandlerDebounced(blockId, entry.contentRect);
      }
    });

    observer.observe(froalaEditorElement);
    return () => {
      observer.disconnect();
    };
  }, [documentContent, editorResizeHandler, IsOnlyTextStyleUpdate]);

  const modelChangeHandler = (documentContent: string) => {
    setDocumentContent(documentContent);
    if (documentContent) {
      setIsOnlyTextStyleUpdate(false);
    } else {
      setIsOnlyTextStyleUpdate(true);
    }
  };

  useEffect(() => {
    froalaEditorElement?.setAttribute('contenteditable', `${isEditMode}`);
  }, [[], isEditMode]);

  Froala.PLUGINS.undoRedoPlugin = UndoRedoPlugin;

  return (
    <div className={editorId} style={style} data-testid={`test_${editorId}`}>
      <FroalaEditor
        tag="textarea"
        config={{ ...defaultConfig }}
        onModelChange={modelChangeHandler}
        model={documentContent || ''}
        ref={editorRef}
      />
    </div>
  );
};

export default FroalaTextbox;
