import React, { createContext, useState } from 'react';

interface SelectedBlockInfoContextProps {
  setSelectedBlockIdByIcon: (selectedBlockIdByIcon: string | null) => void;
  selectedBlockIdByIcon: string | null;
  setSelectedBlockIdByWrapper: (selectedBlockIdByWrapper: string | null) => void;
  selectedBlockIdByWrapper: string | null;
}
export const SelectionContext = createContext<SelectedBlockInfoContextProps>({} as SelectedBlockInfoContextProps);

interface SelectedBlockInfoProviderProps {
  children: React.ReactNode;
}
export const SelectedBlockInfoProvider = ({ children }: SelectedBlockInfoProviderProps) => {
  const [selectedBlockIdByIcon, setSelectedBlockIdByIcon] = useState<string | null>(null);
  const [selectedBlockIdByWrapper, setSelectedBlockIdByWrapper] = useState<string | null>(null);

  const contextValue: SelectedBlockInfoContextProps = {
    selectedBlockIdByIcon,
    setSelectedBlockIdByIcon,
    selectedBlockIdByWrapper,
    setSelectedBlockIdByWrapper,
  };

  return <SelectionContext.Provider value={contextValue}>{children}</SelectionContext.Provider>;
};
