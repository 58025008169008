import { createContext, useContext } from 'react';
import SocketClient from 'services/socket/SocketClient';

type SocketProviderType = {
  children: React.ReactElement;
  documentId: string;
  accessToken: string;
};

const SocketContext = createContext<SocketClient | undefined>(undefined);
export function useSocketClient(): SocketClient {
  const socketClient = useContext(SocketContext);
  if (socketClient === undefined) {
    throw new Error('useSocketClient must be used within a SocketProvider');
  }
  return socketClient;
}

export function SocketProvider({ children, documentId, accessToken }: SocketProviderType) {
  const editorUrl = process.env.REACT_APP_EDITOR_SERVER as string;
  const socketClient = new SocketClient(editorUrl, { documentId, accessToken });
  return <SocketContext.Provider value={socketClient}>{children}</SocketContext.Provider>;
}
