import { useAppDispatch } from '../components/editor/grid/reduxStore/Store';
import { useSocketClient } from '../providers/SocketContext';
import { useCurrentUser } from '../providers/UserProvider';
import { addGridBlockWithSocket } from '../components/editor/grid/reduxStore/saveHandlers';
import { addGridBlockState } from '../components/editor/grid/reduxStore/editorSlice';
import { Block } from 'components/editor/GridDndEditor/models/Block.model';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { useDocumentLock } from '../components/editor/modals/useDocumentLock';

export default function useImageSave() {
  const dispatch = useAppDispatch();
  const socketClient = useSocketClient();
  const { data } = useCurrentUser();
  const userId = data.id;
  const { checkDocumentLockStatus } = useDocumentLock();

  const imageSave = (documentId: string, imageContent: string, blockConfig: Block, cb: (response) => void) => {
    dispatch(
      addGridBlockWithSocket({
        userId,
        content: imageContent,
        blockConfig,
        socketClient,
        type: GridBlockType.IMAGE,
        callback: (response) => {
          cb(response);
          if (checkDocumentLockStatus(response.errorCode)) {
            return;
          }
          const gridBlockId = response.content.gridId;
          const updatedConfig = {
            ...blockConfig,
            id: gridBlockId,
          };
          const payload = {
            documentId: documentId,
            content: imageContent,
            blockConfig: updatedConfig,
            blockId: gridBlockId,
            blockType: GridBlockType.IMAGE,
          };
          dispatch(addGridBlockState(payload));
        },
      })
    );
  };

  return { imageSave };
}
