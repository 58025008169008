import React, { useRef, useMemo, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Resizable } from 're-resizable';
import { DraggableCore } from 'react-draggable';

import { availableResizeHandle } from '../consts';
import ContentClipper from '../Clipper/ContentClipper';
import { useInViewport } from '../useInViewport';
import useGridBlockLayer from '../UseGridBlockLayer';
import { BlockSettingsToolsContainer } from '../BlockSettings/BlockSettingsToolsContainer';
import { SelectionContext } from '../SelectedBlockInfoProvider';
import { RootState, rootStore } from '../../grid/reduxStore/Store';
import { gridPixelSize } from '../../shared/gridConfig';
import { CustomContextMenu, CustomContextMenuOptions } from '../../../CustomContextMenu/CustomContextMenu';

import { BlockDimensionType, BlockPositionType, DraggableGridBlockWrapperProps } from './types';
import { useDraggableBlockManipulations } from './useDraggableBlockManipulations';

export const DraggableGridBlockWrapper: React.FC<DraggableGridBlockWrapperProps> = ({
  blockId,
  children,
  onMouseDown,
  isEditMode = false,
  lockAspectRatio = false,
  sumBorderWidth,
  disableResizingHandlers = false,
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const {
    handleOnResizing,
    handleDrag,
    handleDragStart,
    handleOnResizeStop: handleResizeStop,
    handleStop,
  } = useDraggableBlockManipulations();

  const { bringGridToFront, bringGridForward, sendGridBackward, sendGridToBack } = useGridBlockLayer();
  const blocksContent = useSelector((state: RootState) => state.gridBlockReducer.blocksContent);
  const { blockConfig } = blocksContent[blockId];
  const { scrollToPosition } = useInViewport();
  const positionOnInteractionStart = useRef<BlockPositionType>({ xAxisPx: 0, yAxisPx: 0 });
  const dimensionOnInteractionStart = useRef<BlockDimensionType>({ heightPx: 0, widthPx: 0 });
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);
  const isSelectedState = blockId === selectedBlockIdByWrapper;
  const isContentClipperActive = (isSelectedState || isEditMode) && !isDragging;
  const isMac = navigator.userAgent.includes('Mac');

  const mapAvailableResizeHandle = () =>
    Object.keys(availableResizeHandle).reduce((resizeHandles, key) => {
      resizeHandles[key] = true;
      return resizeHandles;
    }, {});

  const blockSize = {
    width: blockConfig.width,
    height: blockConfig.height,
  };

  const getResizingSides = disableResizingHandlers ? false : mapAvailableResizeHandle();
  const customContextMenuOptions: CustomContextMenuOptions = [
    {
      title: 'Bring to front',
      callback: () => bringGridToFront(blockConfig, rootStore.getState().gridBlockReducer.blocksLayer.greaterZIndexAvailable),
    },
    {
      title: 'Bring forward',
      callback: () => bringGridForward(blockConfig, blocksContent),
    },
    {
      title: 'Send backward',
      callback: () => sendGridBackward(blockConfig, blocksContent),
    },
    {
      title: 'Send to back',
      callback: () => sendGridToBack(blockConfig, rootStore.getState().gridBlockReducer.blocksLayer.lowerZIndexAvailable),
    },
  ];

  const customStyle = useMemo<React.CSSProperties>(
    () => ({
      position: 'absolute',
      top: 0,
      left: 0,
      transform: `translate(${blockConfig.x}px, ${blockConfig.y}px)`,
    }),
    [blockConfig.x, blockConfig.y]
  );

  const handleMouseDown = (e, blockId) => {
    onMouseDown?.(e, blockId);
  };

  const onStartHandle = (e, data) => {
    positionOnInteractionStart.current = { xAxisPx: blockConfig.x, yAxisPx: blockConfig.y };
    handleDragStart(e as MouseEvent, data, blockConfig);
  };

  const onDragHandle = (e, data) => {
    setIsDragging(true);
    scrollToPosition(data.y);
    handleDrag(e as MouseEvent, data, { ...blockConfig });
  };

  const onStopHandle = (event) => {
    setIsDragging(false);
    handleStop(event as MouseEvent, positionOnInteractionStart.current, blockConfig);
  };

  const onMouseDownHandle = (e) => {
    e.stopPropagation();
    handleMouseDown(e, blockId);
  };

  const onResize = (_event, direction, _refToElement, delta) => {
    handleOnResizing(direction, delta, positionOnInteractionStart.current, dimensionOnInteractionStart.current, blockConfig);
  };

  const onResizeStop = (_event, direction, delta) => {
    handleResizeStop(
      direction,
      delta,
      blockConfig,
      positionOnInteractionStart.current,
      dimensionOnInteractionStart.current,
      sumBorderWidth
    );
  };

  const onResizeStartHandle = (event) => {
    positionOnInteractionStart.current = { xAxisPx: blockConfig.x, yAxisPx: blockConfig.y };
    dimensionOnInteractionStart.current = {
      heightPx: blockConfig.height,
      widthPx: blockConfig.width,
    };
    event.stopPropagation();
  };

  return (
    <CustomContextMenu options={customContextMenuOptions}>
      <div style={{ zIndex: blockConfig.z, position: 'relative' }}>
        <DraggableCore
          onStart={onStartHandle}
          onDrag={onDragHandle}
          onStop={onStopHandle}
          scale={1}
          onMouseDown={onMouseDownHandle}
          grid={[gridPixelSize, gridPixelSize]}
          disabled={isEditMode}
        >
          <Resizable
            className={`editor__page__draggable ${isMac ? 'is_mac' : ''} ${isEditMode ? 'edit-state' : ''} ${
              isSelectedState ? 'selected-state' : ''
            }`}
            size={blockSize}
            scale={1}
            boundsByDirection={true}
            minWidth={gridPixelSize}
            style={customStyle}
            minHeight={gridPixelSize}
            grid={[gridPixelSize, gridPixelSize]}
            handleComponent={availableResizeHandle}
            enable={getResizingSides}
            onResizeStart={onResizeStartHandle}
            onResize={onResize}
            onResizeStop={onResizeStop}
            lockAspectRatio={lockAspectRatio}
          >
            <BlockSettingsToolsContainer blockId={blockId} />
            {children}
            {!isContentClipperActive && <ContentClipper blockId={blockId} />}
          </Resizable>
        </DraggableCore>
      </div>
    </CustomContextMenu>
  );
};

DraggableGridBlockWrapper.displayName = 'DraggableGridBlockWrapper';
