import { createAsyncThunk } from '@reduxjs/toolkit';
import SocketClient from '../../../../services/socket/SocketClient';
import { GridDeleteType } from './saveHandlers';
import { AcknowledgmentResponseStatus, BlockEvents, ErrorCode } from '../../../../services/socket/SocketEvents';

export type GridDeletedSocketResponseType = { status: AcknowledgmentResponseStatus; content: string; errorCode: ErrorCode };
type GridDeletedCallbackType = (gridDeletedSocketResponse: GridDeletedSocketResponseType) => void;
export const deleteBlockWithSocket = createAsyncThunk(
  'editor-grid-block/deleteGridWithSocket',
  async (payload: { blockId: string; socketClient: SocketClient; userId: number; callback: GridDeletedCallbackType }) => {
    const { blockId, callback, socketClient } = payload;

    const data: GridDeleteType = {
      gridId: blockId,
    };
    return new Promise<GridDeletedSocketResponseType>((resolve) => {
      socketClient.publish(BlockEvents.BLOCK_DELETED, data, (responseParsed: GridDeletedSocketResponseType) => {
        callback(responseParsed);
        resolve(responseParsed);
      });
    });
  }
);
