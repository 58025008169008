import { gridDragLinePreviewDistanceInPx } from 'components/editor/shared/gridConfig';
import { BlockConfig } from '../models/BlockConfig.model';
import { TrackedBlockCollection } from '../models/TrackedBlockCollection.model';
import './DragLines.less';
import { useContext } from 'react';
import {
  DocumentSettingsContext,
  DocumentSettingsType,
} from '../../SidePanel/document-settings/DocumentDesignSettings/DocumentSettingsContext';
import { gridPageMaxWidthInPixels, gridPageMinHeightInPixels } from '../../shared/gridConfig';

interface DragLinesProps {
  currentBlock: BlockConfig | null;
  trackedBlockCollection: TrackedBlockCollection;
  maxWidthPx: number;
  maxHeightPx: number;
}

export default function DragLines({ currentBlock, trackedBlockCollection, maxWidthPx, maxHeightPx }: DragLinesProps) {
  const { documentSettings, showMargins } = useContext(DocumentSettingsContext);
  const dragLineData = getDragLineData(currentBlock as BlockConfig, trackedBlockCollection, maxWidthPx);
  const marginLineData = getMarginLineData(currentBlock as BlockConfig, documentSettings, showMargins);

  return (
    <>
      {dragLineData.concat(marginLineData).map((lineDataItem) => {
        const dashGap = lineDataItem.isDottedLines ? 4 : 0;

        return lineDataItem.lines.map((line, index) => {
          return lineDataItem.direction === 'horizontal' ? (
            <svg key={`h-line-${index}`} className="dragline-container horizontal" viewBox={`0 0 ${maxWidthPx} ${maxHeightPx}`}>
              <line x1={0} y1={line} x2={maxWidthPx} y2={line} stroke={lineDataItem.color} strokeWidth="1px" strokeDasharray={dashGap} />
            </svg>
          ) : (
            <svg key={`v-line-${index}`} className="dragline-container vertical" viewBox={`0 0 ${maxWidthPx} ${maxHeightPx}`}>
              <line x1={line} y1={0} x2={line} y2={maxHeightPx} stroke={lineDataItem.color} strokeWidth="1px" strokeDasharray={dashGap} />
            </svg>
          );
        });
      })}
    </>
  );
}

function getMarginLineData(currentBlock: BlockConfig, documentSettings: DocumentSettingsType, showMargins: boolean) {
  const pageVerticalMarginLines = new Set<number>();
  const pageHorizontalMarginLines = new Set<number>();

  const { top, bottom, left, right } = documentSettings.margin;
  const marginThresholdInPx = 8;
  const topHorizontalMargin = Math.floor(top);
  const bottomHorizontalMargin = Math.floor(gridPageMinHeightInPixels - bottom);
  const leftVerticalMargin = Math.floor(left);
  const rightVerticalMargin = Math.floor(gridPageMaxWidthInPixels - right);

  if (showMargins) {
    pageVerticalMarginLines.add(leftVerticalMargin);
    pageVerticalMarginLines.add(rightVerticalMargin);
    pageHorizontalMarginLines.add(topHorizontalMargin);
    pageHorizontalMarginLines.add(bottomHorizontalMargin);
  } else if (currentBlock) {
    const draggedBlockBottom = currentBlock.y + (currentBlock.height || 0);
    const draggedBlockRight = currentBlock.x + (currentBlock.width || 0);

    if (
      Math.abs(currentBlock.x - leftVerticalMargin) <= marginThresholdInPx ||
      Math.abs(draggedBlockRight - leftVerticalMargin) <= marginThresholdInPx
    ) {
      pageVerticalMarginLines.add(leftVerticalMargin);
    }

    if (
      Math.abs(currentBlock.x - rightVerticalMargin) <= marginThresholdInPx ||
      Math.abs(draggedBlockRight - rightVerticalMargin) <= marginThresholdInPx
    ) {
      pageVerticalMarginLines.add(rightVerticalMargin);
    }

    if (Math.abs((currentBlock.y % gridPageMinHeightInPixels) - topHorizontalMargin) <= marginThresholdInPx) {
      const noOfPages = Math.floor(currentBlock.y / gridPageMinHeightInPixels);
      pageHorizontalMarginLines.add(noOfPages * gridPageMinHeightInPixels + topHorizontalMargin);
    }

    if (Math.abs((draggedBlockBottom % gridPageMinHeightInPixels) - topHorizontalMargin) <= marginThresholdInPx) {
      const noOfPages = Math.floor(draggedBlockBottom / gridPageMinHeightInPixels);
      const currentPageBottomMarginInPx = noOfPages * gridPageMinHeightInPixels + topHorizontalMargin;
      pageHorizontalMarginLines.add(currentPageBottomMarginInPx);
    }

    if (
      Math.abs((currentBlock.y % gridPageMinHeightInPixels) - bottomHorizontalMargin) <= marginThresholdInPx ||
      Math.abs((draggedBlockBottom % gridPageMinHeightInPixels) - bottomHorizontalMargin) <= marginThresholdInPx
    ) {
      const noOfPages = Math.floor(currentBlock.y / gridPageMinHeightInPixels);
      pageHorizontalMarginLines.add(noOfPages * gridPageMinHeightInPixels + bottomHorizontalMargin);
    }
  }

  return [
    {
      color: 'red',
      direction: 'horizontal',
      isDottedLines: true,
      lines: Array.from(pageHorizontalMarginLines),
    },
    {
      color: 'red',
      direction: 'vertical',
      isDottedLines: true,
      lines: Array.from(pageVerticalMarginLines),
    },
  ];
}

function getDragLineData(currentBlock: BlockConfig, trackedBlockCollection: TrackedBlockCollection, maxWidthPx: number) {
  if (!currentBlock) {
    return [];
  }

  const dragLineCollection = {
    horizontalEdgeLines: new Set<number>(),
    verticalEdgeLines: new Set<number>(),
    middleHorizontalLines: new Set<number>(),
    middleVerticalLines: new Set<number>(),
    pageHorizontalLines: new Set<number>(),
    pageVerticalLines: new Set<number>(),
    pageHorizontalMarginLines: new Set<number>(),
    pageVerticalMarginLines: new Set<number>(),
  };

  for (const [id, trackedData] of Object.entries(trackedBlockCollection)) {
    if (id === currentBlock.id || !trackedData.isVisible) {
      continue;
    }

    const draggedBlockBottom = currentBlock.y + currentBlock.height;
    const draggedBlockRight = currentBlock.x + currentBlock.width;
    const draggedBlockMiddleX = currentBlock.x + currentBlock.width / 2;
    const draggedBlockMiddleY = currentBlock.y + currentBlock.height / 2;
    const trackedBlockBottom = trackedData.y + trackedData.height;
    const trackedBlockRight = trackedData.x + trackedData.width;
    const trackedBlockMiddleX = trackedData.x + trackedData.width / 2;
    const trackedBlockMiddleY = trackedData.y + trackedData.height / 2;
    const pageMiddleX = maxWidthPx / 2;

    const currentVerticalXPoints: number[] = [currentBlock.x, draggedBlockRight];
    const trackedVerticalXPoints: number[] = [trackedData.x, trackedBlockRight, trackedBlockMiddleX];
    const currentHorizontalYPoints: number[] = [currentBlock.y, draggedBlockBottom];
    const trackedHorizontalYPoints: number[] = [trackedData.y, trackedBlockBottom, trackedBlockMiddleY];

    // Collects lines that are close to the dragged block within the gridPreviewDistanceInPx range.
    const collectLines = (currentPoints: number[], trackedPoints: number[], lines: Set<number>) => {
      for (let i = 0; i < currentPoints.length; i++) {
        for (let j = 0; j < trackedPoints.length; j++) {
          const distance = currentPoints[i] - trackedPoints[j];

          if (distance >= -gridDragLinePreviewDistanceInPx && distance <= gridDragLinePreviewDistanceInPx) {
            lines.add(trackedPoints[j]);
          }
        }
      }
    };

    // This section is called a number of times as there are 6 different series of lines to collect, each with different display characteristics.
    collectLines(currentVerticalXPoints, trackedVerticalXPoints, dragLineCollection.verticalEdgeLines);
    collectLines(currentHorizontalYPoints, trackedHorizontalYPoints, dragLineCollection.horizontalEdgeLines);
    collectLines([draggedBlockMiddleX], trackedVerticalXPoints, dragLineCollection.middleVerticalLines);
    collectLines([draggedBlockMiddleY], trackedHorizontalYPoints, dragLineCollection.middleHorizontalLines);
    collectLines([...currentVerticalXPoints, draggedBlockMiddleX], [pageMiddleX], dragLineCollection.pageVerticalLines);
  }

  // deleting edge lines that are also on a middle crossing. This prevents solid lines from covering dotted lines.
  dragLineCollection.pageHorizontalLines.forEach((line) => {
    dragLineCollection.horizontalEdgeLines.delete(line);
    dragLineCollection.middleHorizontalLines.delete(line);
  });

  dragLineCollection.pageVerticalLines.forEach((line) => {
    dragLineCollection.verticalEdgeLines.delete(line);
    dragLineCollection.middleVerticalLines.delete(line);
  });

  return [
    {
      color: 'green',
      direction: 'horizontal',
      isDottedLines: false,
      lines: Array.from(dragLineCollection.horizontalEdgeLines),
    },
    {
      color: 'green',
      direction: 'vertical',
      isDottedLines: false,
      lines: Array.from(dragLineCollection.verticalEdgeLines),
    },
    {
      color: 'green',
      direction: 'horizontal',
      isDottedLines: true,
      lines: Array.from(dragLineCollection.middleHorizontalLines),
    },
    {
      color: 'green',
      direction: 'vertical',
      isDottedLines: true,
      lines: Array.from(dragLineCollection.middleVerticalLines),
    },
    {
      color: 'red',
      direction: 'horizontal',
      isDottedLines: true,
      lines: Array.from(dragLineCollection.pageHorizontalLines),
    },
    {
      color: 'red',
      direction: 'vertical',
      isDottedLines: true,
      lines: Array.from(dragLineCollection.pageVerticalLines),
    },
  ];
}
