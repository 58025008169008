import { TableType } from '../../../grid/reduxStore/saveHandlers';
import { BlockContent, updateGridDimensionConfig, updateGridTableBlockState } from '../../../grid/reduxStore/editorSlice';
import { rootStore, useAppDispatch } from '../../../grid/reduxStore/Store';
import { useBlockDimensionChangedHandlerWithoutUndoRedo } from '../../../hooks/UseBlockDimensionChangedHandler';
import { GridApiCommon, GridApiPro } from '@mui/x-data-grid-pro';

export const useTableManipulation = () => {
  const blockDimensionChangedHandlerWithoutUndoRedo = useBlockDimensionChangedHandlerWithoutUndoRedo();
  const dispatch = useAppDispatch();

  const getBlockContent = (blockId: string): BlockContent | null => {
    return { ...rootStore.getState().gridBlockReducer.blocksContent[blockId] };
  };

  const getTableData = (blockId: string): TableType | undefined => {
    const blockContent = getBlockContent(blockId);
    if (!blockContent) return undefined;
    return blockContent.contentTable;
  };

  const getTableUpdatedDimensions = (tableApi: GridApiPro | GridApiCommon) => {
    if (!tableApi || !tableApi.rootElementRef.current) return undefined;
    const visibleColumns = tableApi.getVisibleColumns();
    const columnsTotalWidth = visibleColumns.reduce((width, column) => width + (column.width ?? 0), 0);

    return {
      width: columnsTotalWidth,
      height: tableApi.rootElementRef.current.offsetHeight as number,
    };
  };

  const updateBlockDimensionsWithTableDimensions = async (blockId: string, tableApi: GridApiPro | GridApiCommon) => {
    const dimensions = getTableUpdatedDimensions(tableApi);
    if (!dimensions) return;

    await blockDimensionChangedHandlerWithoutUndoRedo(blockId, { widthPx: dimensions.width, heightPx: dimensions.height });
  };

  const updateTableBlockContentStoreState = (blockId: string, tableData: TableType) => {
    const payload = {
      blockId: blockId,
      contentTable: tableData,
    };
    return dispatch(updateGridTableBlockState(payload));
  };

  const updateTableBlockDimensionsStoreState = (blockId: string, { width, height }: { width: number; height: number }) => {
    dispatch(updateGridDimensionConfig({ blockId, width, height }));
  };

  return {
    getBlockContent,
    getTableData,
    getTableUpdatedDimensions,
    updateBlockDimensionsWithTableDimensions,
    updateTableBlockDimensionsStoreState,
    updateTableBlockContentStoreState,
  };
};
