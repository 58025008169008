import { VariablesGroup } from '../services/entities/VariablesEntity';
import Tribute from 'tributejs';
import { formatOptions, options } from '../components/editor/variables/options';
import React, { useEffect } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';

export function useTributeOnFroalaInit({
  variables,
  editorRef,
}: {
  variables: VariablesGroup | undefined;
  editorRef: React.RefObject<FroalaEditor>;
}) {
  useEffect(() => {
    if (variables === undefined || editorRef.current == null) return;
    const formattedOptions = { values: formatOptions(variables) };
    const tribute = new Tribute(Object.assign(options, formattedOptions));
    const { el } = (editorRef.current as FroalaEditor).getEditor();
    tribute.attach(el);
  }, [variables]);
}
