import { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useContentLibraryListQuery } from 'hooks/useContentLibraryListQuery';
import { Asset } from 'services/library/entities/Asset';
import IcoWebStories from '../../../../components/icon/icons/IcoWebStories';
import './styles.less';
import { convertCreatedAtDateToUserTime, formatCreatedAtDate, isToday } from 'utils/dateHandlers';
import { useCurrentUser } from 'providers/UserProvider';
import { useTranslation } from 'react-i18next';

export interface Props {
  onAssetClick?: (Asset: Asset) => Promise<void>;
}

export function AssetList({ onAssetClick }: Props) {
  const { t } = useTranslation();

  const { visibleAssetsList, isLoading, isLoadingOnScroll, isAssetListLoaded, loadMoreAssets, remainingAssets } =
    useContentLibraryListQuery();
  const { data: currentUserInfo } = useCurrentUser();
  const containerRef = useRef<HTMLDivElement>(null);
  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      if (!container) return;

      const iscontainerScrolledToBottom = container?.clientHeight + container?.scrollTop >= container?.scrollHeight;

      if (iscontainerScrolledToBottom && !isLoading && !isLoadingOnScroll) {
        loadMoreAssets();
        setShowSkeleton(true);
      }
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoading, isLoadingOnScroll, loadMoreAssets]);

  useEffect(() => {
    if (!isLoadingOnScroll && showSkeleton) {
      const timeout = setTimeout(() => {
        setShowSkeleton(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [isLoadingOnScroll, showSkeleton]);

  const showDateOrTime = (createdAtDate: string) => {
    const userTimezone = currentUserInfo.timezone || 'UTC';

    if (isToday(createdAtDate)) {
      return convertCreatedAtDateToUserTime(createdAtDate, userTimezone);
    } else {
      return formatCreatedAtDate(createdAtDate);
    }
  };

  return (
    <Box className="asset-list-container" data-testid="asset-list-container" ref={containerRef}>
      {!isAssetListLoaded && (
        <Box margin="auto">
          <CircularProgress className="circular-progress" data-testid="circular-progress-loader" size={24} />
        </Box>
      )}

      {isAssetListLoaded && visibleAssetsList.length > 0 && (
        <Table className="content-library-list" data-testid="content-library-list" stickyHeader>
          <TableHead className="content-library-list-header">
            <TableRow>
              <TableCell className="content-library-icon-column"></TableCell>
              <TableCell className="content-library-name-column">{t('library.asset_list_name')}</TableCell>
              <TableCell className="content-library-last-modified-column">{t('library.asset_list_last_modified')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleAssetsList.map((asset: Asset) => (
              <TableRow
                key={asset.id}
                className="content-library-list-row"
                data-testid={`content-library-asset-${asset.id}`}
                onClick={onAssetClick ? () => onAssetClick(asset) : undefined}
              >
                <TableCell className="content-library-icon-row">
                  <IcoWebStories />
                </TableCell>
                <TableCell className="content-library-name-row" data-testid={`content-library-asset-${asset.name}`}>
                  <Typography className="content-library-name">{asset.name}</Typography>
                </TableCell>
                <TableCell className="content-library-last-modified-row" data-testid={`content-library-asset-${asset.updated_at}`}>
                  <Typography className="content-library-last-modified">{showDateOrTime(asset.updated_at)}</Typography>
                </TableCell>
              </TableRow>
            ))}
            {isLoadingOnScroll &&
              showSkeleton &&
              Array.from(new Array(Math.min(remainingAssets, 30))).map((_, index) => (
                <TableRow key={index} data-testid={`content-library-skeleton-${index}`}>
                  <TableCell>
                    <Skeleton datatest-id="skeleton" variant="circular" width={24} height={24} />
                  </TableCell>
                  <TableCell>
                    <Skeleton datatest-id="skeleton" variant="text" width={264} height={11} />
                  </TableCell>
                  <TableCell>
                    <Skeleton datatest-id="skeleton" variant="text" width={100} height={11} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}
