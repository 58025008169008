import React, { useState, createContext, useEffect, useContext } from 'react';
import './margin/MarginSettings.less';
import { gridDefaultPixelDensity, gridPageMaxWidthInPixels, gridPageMinHeightInPixels } from '../../../shared/gridConfig';
import { useSocketClient } from '../../../../../providers/SocketContext';
import { Margin } from './models/Margin.model';
import { MarginSide } from './models/MarginSide.enum';
import { UndoRedoManager } from 'components/editor/UndoRedo/UndoRedoManager';
import { UndoRedoCommand } from '../../../UndoRedo/UndoRedoCommand';
import { useDocumentLock } from '../../../modals/useDocumentLock';
import { SaveStatusContext } from '../../../providers/SaveStatusProvider';
import { DefaultSocketResponseType } from '../../../../../services/socket/SocketEvents';
import { DocumentSaveStatus } from '../../../shared/models/DocumentSaveStatus';

export type DocumentSettingsType = {
  margin: Margin;
  backgroundColor: string;
};

interface DocumentSettingsContextType {
  documentSettings: DocumentSettingsType;
  updateMargins: (value: string | number, type: MarginSide) => void;
  updateBackgroundColor: (color: string) => void;
  showMargins: boolean;
  setShowMargins: (show: boolean) => void;
}

export const DocumentSettingsContext = createContext<DocumentSettingsContextType>({} as DocumentSettingsContextType);

interface DocumentSettingsProviderProps {
  children: any;
}

const useDocumentSettingsUndoRedo = () => {
  const undoRedoManager = UndoRedoManager.getUndoRedoManager();
  return (
    documentSettings: DocumentSettingsType,
    newDocumentSettings: DocumentSettingsType,
    setDocumentSettingsWithoutUndoRedo: SetDocumentSettingsType
  ) => {
    const undoCallback = async (): Promise<boolean> => {
      try {
        setDocumentSettingsWithoutUndoRedo(documentSettings);
      } catch (error) {
        return false;
      }
      return true;
    };
    const redoCallback = async (): Promise<boolean> => {
      try {
        setDocumentSettingsWithoutUndoRedo(newDocumentSettings);
      } catch (error) {
        return false;
      }
      return true;
    };

    undoRedoManager.pushUndoRedoCommands(new UndoRedoCommand(undoCallback, redoCallback));
  };
};

type SetDocumentSettingsType = (newDocumentSettings: DocumentSettingsType) => void;
const useDocumentSettings = () => {
  const defaultMarginValue = 0;
  const socketClient = useSocketClient();
  const saveUndoRedoDocumentSettings = useDocumentSettingsUndoRedo();
  const { checkDocumentLockStatus } = useDocumentLock();
  const { setSaveStatus, updateSaveStatus } = useContext(SaveStatusContext);
  const [documentSettings, updateDocumentSettings] = useState<DocumentSettingsType>({
    margin: {
      top: defaultMarginValue,
      bottom: defaultMarginValue,
      left: defaultMarginValue,
      right: defaultMarginValue,
    },
    backgroundColor: '#ffffffff',
  });
  const onDocumentSettingsLoad = ({ content: newSettings }: { content: DocumentSettingsType }) => {
    updateDocumentSettings(newSettings);
  };

  useEffect(() => {
    if (!socketClient.isConnected()) return;

    socketClient.loadDocumentSettings(onDocumentSettingsLoad);
  }, [socketClient.isConnected()]);

  const updateSettingsCallback = (response: DefaultSocketResponseType) => {
    updateSaveStatus({ status: response.status, errorCode: response.errorCode });
    checkDocumentLockStatus(response.errorCode);
  };

  const setDocumentSettings: SetDocumentSettingsType = (newDocumentSettings) => {
    setSaveStatus(DocumentSaveStatus.SAVING);
    setDocumentSettingsWithoutUndoRedo(newDocumentSettings);
    saveUndoRedoDocumentSettings(documentSettings, newDocumentSettings, setDocumentSettingsWithoutUndoRedo);
  };
  const setDocumentSettingsWithoutUndoRedo = (newDocumentSettings: DocumentSettingsType) => {
    updateDocumentSettings(newDocumentSettings);
    socketClient.saveDocumentSettings(newDocumentSettings, updateSettingsCallback);
  };

  return { documentSettings, setDocumentSettings };
};
export const DocumentSettingsProvider: React.FC<DocumentSettingsProviderProps> = ({ children }: DocumentSettingsProviderProps) => {
  const { documentSettings, setDocumentSettings } = useDocumentSettings();
  const [showMargins, setShowMargins] = useState<boolean>(false);

  const updateMargins = (value: string | number, type: MarginSide) => {
    const valueInInches = typeof value === 'string' ? parseFloat(value) : value;
    if (valueInInches < 0) return;

    const valueInPixels = valueInInches * gridDefaultPixelDensity;
    if ((type === MarginSide.LEFT || type === MarginSide.RIGHT) && valueInPixels > gridPageMaxWidthInPixels) {
      return;
    }

    if ((type === MarginSide.TOP || type === MarginSide.BOTTOM) && valueInPixels > gridPageMinHeightInPixels) {
      return;
    }

    const newMargin: Margin = { ...documentSettings.margin, [MarginSide[type].toLowerCase()]: parseFloat(valueInPixels.toFixed(2)) };
    setDocumentSettings({ ...documentSettings, margin: newMargin });
  };

  const updateBackgroundColor = (color: string) => {
    setDocumentSettings({ ...documentSettings, backgroundColor: color });
  };

  return (
    <DocumentSettingsContext.Provider value={{ documentSettings, updateMargins, updateBackgroundColor, showMargins, setShowMargins }}>
      {children}
    </DocumentSettingsContext.Provider>
  );
};
