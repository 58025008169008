import { IconButton } from '@mui/material';
import { useContext, useState } from 'react';

import { useBlockDeletedHandler } from '../hooks/UseBlockDeletedHandler';
import GridDeleteWarningModal from './models/GridDeleteWarningModal';
import { rootStore } from '../grid/reduxStore/Store';
import { SidePanelProviderContext } from '../SidePanel/content';
import { BlockStyleSettingsContext } from '../SidePanel/content/designSettings/AdvancedSpacing/BlockSettingsProvider';
import IcoTrash from '../../icon/icons/IcoTrash';

import '../GridDndEditor/GridDndEditor.less';

export interface GridDeleteComponentProp {
  blockId: string;
}
export default function GridDeleteComponent({ blockId }: GridDeleteComponentProp) {
  const gridBlockDeletedHandler = useBlockDeletedHandler();
  const [shouldModalVisible, setIsModalVisible] = useState(false);
  const { resetAllPanels } = useContext(SidePanelProviderContext);
  const { deleteBlocksSettings } = useContext(BlockStyleSettingsContext);

  const handleDeleteOk = () => {
    const blockContent = rootStore.getState().gridBlockReducer.blocksContent[blockId];
    deleteBlocksSettings(blockId);
    gridBlockDeletedHandler(blockId, blockContent).then(() => {
      setIsModalVisible(false);
      resetAllPanels();
    });
  };

  const iconButtonSX = {
    width: '28px',
    height: '28px',
    color: 'white',
  };

  return (
    <>
      <IconButton size={'small'} sx={iconButtonSX} onClick={() => setIsModalVisible(true)}>
        <IcoTrash className="editor__page__draggable__grid-delete-handle" />
      </IconButton>
      <GridDeleteWarningModal
        isDeleteRequested={shouldModalVisible}
        handleOk={() => handleDeleteOk()}
        handleCancel={() => setIsModalVisible(false)}
      />
    </>
  );
}
