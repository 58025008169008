import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { usePreviewSignatures } from 'hooks/usePreviewSignatures';
import { useParams } from 'react-router-dom';
import WithLoadingIndicator from 'components/page-loader/page-loader';

import './gridStyles.less';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';

import { editorConfig } from 'components/editor/helpers/config';
import { useTranslation } from 'react-i18next';
import { PreviewMenu } from 'components/preview/preview-menu';
import { CINDER_URLS } from 'utils/cinder-urls';
import { PreviewGrid } from 'components/preview';
import { NavigateToCinder } from 'components/navigate-to-cinder';
import Banner from 'components/preview/banner';
import { Col, Row } from 'antd';
import { isAuthenticated } from 'utils/authentication';
import { PreviewActionMenu } from 'components/preview/action-menu/action-menu';
import { PreviewBlock } from '../../components/editor/GridDndEditor/Block/Preview/PreviewBlock';
import { getEditorMaxHeight } from '../../components/editor/GridDndEditor/gridHelper';
import { SignatureStatus } from '../../services/repositories/interfaces/SignatureRepository';
import WithSignatureModal from '../../components/editor/signatures/withSignatureModal';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { gridPageMinHeightInPixels, gridPixelSize } from 'components/editor/shared/gridConfig';
import { useGetPreviewByDocId } from './useGetPreviewByDocId';
import { useGetPreviewGridByDocId } from './useGetPreviewGridByDocId';
import { BlockContent, BlocksContentCollection } from '../../components/editor/grid/reduxStore/editorSlice';
import { GridsByDocumentIdResponse } from '../../services/repositories/interfaces/DocumentRepository';
import { BlockSettings, ImageBlockSettings, TextBlockSettings } from '../../components/editor/grid/reduxStore/saveHandlers';
import ImageComponent from '../../components/editor/GridDndEditor/Block/Image/GridImageBlock/ImageComponent';
import ProposifyTheme from '../../muiTheme/PyTheme';
import { ThemeProvider } from '@mui/material/styles';
import { PreviewTableBlock } from '../../components/editor/GridDndEditor/Block/Preview/PreviewTableBlock';
import PreviewSignedSignatureBlock from '../../components/editor/GridDndEditor/Block/Signature/PreviewSignedSignatureBlock';

const getBlocksContentFromApiResponse = (
  gridBlocks: GridsByDocumentIdResponse[]
): { gridBlocksContent: BlocksContentCollection; blockStyleMap: Map<string, BlockSettings> } => {
  const gridBlocksContent: BlocksContentCollection = {};
  const blockStyleMap: Map<string, BlockSettings> = new Map();
  for (const gridBlockElement of gridBlocks) {
    const blockContent: BlockContent = {
      content: gridBlockElement.htmlContent,
      type: gridBlockElement.type,
      blockConfig: {
        id: gridBlockElement.gridId,
        x: gridBlockElement.position.left_px,
        y: gridBlockElement.position.top_px,
        z: gridBlockElement.position.z_index,
        width: gridBlockElement.dimensions.width_px,
        height: gridBlockElement.dimensions.height_px,
      },
    };
    if (gridBlockElement.type === GridBlockType.TABLE) {
      blockContent.contentTable = gridBlockElement.content;
    }

    gridBlocksContent[gridBlockElement.gridId] = blockContent;

    // Add block settings to blockStyleMap
    blockStyleMap.set(gridBlockElement.gridId, {
      blockType: gridBlockElement.type,
      updatedAt: '',
      blockId: gridBlockElement.gridId,
      borderTop: gridBlockElement.blockSettings?.borderTop || 0,
      borderLeft: gridBlockElement.blockSettings?.borderLeft || 0,
      borderRight: gridBlockElement.blockSettings?.borderRight || 0,
      borderBottom: gridBlockElement.blockSettings?.borderBottom || 0,
      borderColor: gridBlockElement.blockSettings?.borderColor || '',
      borderRadius: gridBlockElement.blockSettings?.borderRadius || 0,
      backgroundColor: (gridBlockElement.blockSettings as TextBlockSettings)?.backgroundColor || '',
      updatedByUserId: gridBlockElement.blockSettings?.updatedByUserId || 0,
      opacity: (gridBlockElement.blockSettings as ImageBlockSettings)?.opacity || 100,
      imageAlt: (gridBlockElement.blockSettings as ImageBlockSettings)?.imageAlt || '',
      imageLink: (gridBlockElement.blockSettings as ImageBlockSettings)?.imageLink || '',
      paddingLeft: gridBlockElement.blockSettings?.paddingLeft || 0,
      paddingRight: gridBlockElement.blockSettings?.paddingRight || 0,
      paddingTop: gridBlockElement.blockSettings?.paddingTop || 0,
      paddingBottom: gridBlockElement.blockSettings?.paddingBottom || 0,
    });
  }
  return { gridBlocksContent, blockStyleMap };
};
export const PreviewGridPage: React.FC = () => {
  const { t } = useTranslation();
  const { documentId: documentIdFromParam } = useParams<{ documentId: string }>();
  const documentId = documentIdFromParam as string;
  const { data: documentData, status } = useGetPreviewByDocId(documentId);
  const { gridBlocksPreFormatted, status: gridQueryStatus } = useGetPreviewGridByDocId(documentId);
  const { signatures, setSignatures, maxSignatureHeight } = usePreviewSignatures(documentId);
  let gridBlocksContent: object;
  const documentTitle = documentData?.title || 'Untitled Document';
  const documentPrice = documentData?.price || 0.0;
  const documentProspectName = documentData?.prospect?.name || '';
  let blockStyleMap: Map<string, BlockSettings>;

  switch (status) {
    case 'error':
      return <NavigateToCinder path={CINDER_URLS.error} />;
    case 'success':
      if (gridQueryStatus === 'error') return <NavigateToCinder path={CINDER_URLS.error} />;

      ({ gridBlocksContent = {}, blockStyleMap = new Map<string, BlockSettings>() } = gridBlocksPreFormatted
        ? getBlocksContentFromApiResponse(gridBlocksPreFormatted)
        : {});

      return (
        <ThemeProvider theme={ProposifyTheme}>
          <PreviewGrid
            header={
              <PreviewMenu
                documentId={documentId}
                documentTitle={documentTitle}
                documentProspectName={documentProspectName}
                documentPrice={documentPrice}
                isDocumentBelongsToSameAccount={documentData.isDocumentBelongsToSameAccount}
              />
            }
            banner={<Banner documentId={documentId}>{t('document.preview.banner')}</Banner>}
            main={
              <div
                data-testid="grid-preview"
                className="preview__editor__wrapper__grid"
                style={{ height: Math.max(gridPageMinHeightInPixels, getEditorMaxHeight(gridBlocksContent, maxSignatureHeight)) }}
              >
                <div className="preview__grid__blocks">
                  {gridQueryStatus === 'success' &&
                    Object.values(gridBlocksContent).map((block) => {
                      if (block.type === GridBlockType.TEXT) {
                        const blockSettings = blockStyleMap.get(block.blockConfig.id);
                        if (!blockSettings) return;
                        const {
                          paddingLeft,
                          paddingTop,
                          paddingRight,
                          paddingBottom,
                          backgroundColor,
                          borderColor,
                          borderLeft,
                          borderRight,
                          borderTop,
                          borderBottom,
                          borderRadius,
                        } = blockSettings as unknown as TextBlockSettings;
                        return (
                          <PreviewBlock key={block.blockConfig.id} gridBlock={block} gridSystemInPx={gridPixelSize}>
                            <div
                              className={block.blockConfig.id}
                              style={{
                                borderTop: `solid ${borderColor} ${borderTop}px`,
                                borderBottom: `solid ${borderColor} ${borderBottom}px`,
                                borderLeft: `solid ${borderColor} ${borderLeft}px`,
                                borderRight: `solid ${borderColor} ${borderRight}px`,
                                borderRadius: `${borderRadius}px`,
                                backgroundColor: backgroundColor,
                                paddingLeft: `${paddingLeft}px`,
                                paddingTop: `${paddingTop}px`,
                                paddingRight: `${paddingRight}px`,
                                paddingBottom: `${paddingBottom}px`,
                                height: '100%',
                              }}
                              data-testid={`test_${block.blockConfig.id}`}
                            >
                              <FroalaEditor
                                key={block.blockConfig.id}
                                tag="textarea"
                                config={{
                                  ...editorConfig,
                                  placeholderText: '',
                                  editorClass: 'py-preview',
                                  events: {
                                    initialized() {
                                      (this as any).edit.off();
                                    },
                                  },
                                }}
                                model={block.content}
                              />
                            </div>
                          </PreviewBlock>
                        );
                      } else if (block.type === GridBlockType.IMAGE) {
                        const blockSettings = blockStyleMap.get(block.blockConfig.id);
                        if (!blockSettings) return;
                        const imageAlt = 'imageAlt' in blockSettings ? blockSettings.imageAlt : '';
                        const imageLink =
                          'imageLink' in blockSettings && blockSettings.imageLink ? 'https://' + blockSettings.imageLink : '';
                        const {
                          paddingLeft,
                          paddingTop,
                          paddingRight,
                          paddingBottom,
                          borderColor,
                          borderLeft,
                          borderRight,
                          borderTop,
                          borderBottom,
                          borderRadius,
                          opacity,
                        } = blockSettings as unknown as ImageBlockSettings;
                        return (
                          <PreviewBlock key={block.blockConfig.id} gridBlock={block} gridSystemInPx={gridPixelSize}>
                            {imageLink ? (
                              <a href={imageLink} target="_blank" rel="noopener">
                                <ImageComponent
                                  style={{
                                    borderTop: `solid ${borderColor} ${borderTop}px`,
                                    borderBottom: `solid ${borderColor} ${borderBottom}px`,
                                    borderLeft: `solid ${borderColor} ${borderLeft}px`,
                                    borderRight: `solid ${borderColor} ${borderRight}px`,
                                    borderRadius: `${borderRadius}px`,
                                    paddingLeft: `${paddingLeft}px`,
                                    paddingTop: `${paddingTop}px`,
                                    paddingRight: `${paddingRight}px`,
                                    paddingBottom: `${paddingBottom}px`,
                                  }}
                                  imageAlt={imageAlt}
                                  opacity={opacity || 0}
                                  currentBlockId={block.blockConfig.id}
                                  src={block.content}
                                />
                              </a>
                            ) : (
                              <ImageComponent
                                style={{
                                  borderTop: `solid ${borderColor} ${borderTop}px`,
                                  borderBottom: `solid ${borderColor} ${borderBottom}px`,
                                  borderLeft: `solid ${borderColor} ${borderLeft}px`,
                                  borderRight: `solid ${borderColor} ${borderRight}px`,
                                  borderRadius: `${borderRadius}px`,
                                  paddingLeft: `${paddingLeft}px`,
                                  paddingTop: `${paddingTop}px`,
                                  paddingRight: `${paddingRight}px`,
                                  paddingBottom: `${paddingBottom}px`,
                                }}
                                imageAlt={imageAlt}
                                opacity={opacity || 0}
                                currentBlockId={block.blockConfig.id}
                                src={block.content}
                              />
                            )}
                          </PreviewBlock>
                        );
                      } else if (block.type === GridBlockType.TABLE) {
                        return <PreviewTableBlock key={block.blockConfig.id} block={block} />;
                      }
                      return null;
                    })}
                </div>
                <div className="preview__grid__signatures">
                  <div className="preview__signatures__container">
                    {Array.isArray(signatures) &&
                      signatures?.map(({ signatureBoxId, properties, ...rest }) => {
                        return rest.status === SignatureStatus.Signed ? (
                          <PreviewSignedSignatureBlock
                            signee={rest.signatureEvent}
                            position={properties.position}
                            signatureId={signatureBoxId}
                            key={signatureBoxId}
                            signedDate={rest.signatureEvent.signedDate}
                          />
                        ) : (
                          <WithSignatureModal
                            position={properties.position}
                            dimensions={properties.dimensions}
                            signatureId={signatureBoxId}
                            key={signatureBoxId}
                            assignedSignee={rest.assignedSignee}
                            documentId={documentId}
                            setSignatures={setSignatures}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            }
            footer={
              <Row justify="space-between" align="middle">
                <Col>
                  <div className="preview__footer__document_price">
                    {documentData.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </div>
                </Col>
                {isAuthenticated() ? (
                  <Col>
                    <PreviewActionMenu documentId={documentId} documentTitle={documentTitle} documentProspectName={documentProspectName} />
                  </Col>
                ) : null}
              </Row>
            }
          />
        </ThemeProvider>
      );
    case 'loading':
    default:
      return <WithLoadingIndicator isLoading={true} isLoaded={false}></WithLoadingIndicator>;
  }
};
