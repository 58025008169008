import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import { MuiDataGrid } from './MuiDataGrid';
import { MuiIconButton } from './MuiIconButton';

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#00283a',
    },
    secondary: {
      main: '#00b8d1',
    },
    error: {
      main: '#f23d5e',
    },
    warning: {
      main: '#ffbf0a',
    },
    info: {
      main: '#7e58d0',
    },
    success: {
      main: '#12a75c',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: 'proxima-nova',
    h1: {
      fontSize: '3.6rem',
      fontWeight: 400,
      letterSpacing: '0em',
    },
    h2: {
      fontSize: '2.8rem',
      fontWeight: 400,
      letterSpacing: '0em',
    },
    h3: {
      fontSize: '2.3rem',
    },
    h4: {
      fontSize: '1.8rem',
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiDataGrid,
    MuiIconButton,
  },
};

const lightTheme: Theme = createTheme(themeOptions);

export default lightTheme;
