import { useContext } from 'react';

import { SignatureStatus } from '../../../services/repositories/interfaces/SignatureRepository';
import SignedSignature from './Block/Signature/SignedSignatureBlock';
import UnSignedSignature from './Block/Signature/UnsignedSignatureBlock';
import { SignaturesContext } from '../providers/SignaturesProvider';

const EditorPageSignatures = () => {
  const { signatures, setSelectedSignature } = useContext(SignaturesContext);

  return (
    <div className="editor__page__signatures">
      {signatures?.map(({ signatureBoxId, properties, status, signatureEvent, assignedSignee }) =>
        status === SignatureStatus.Signed ? (
          <SignedSignature
            signee={signatureEvent}
            position={properties.position}
            dimensions={properties.dimensions}
            signatureId={signatureBoxId}
            key={signatureBoxId}
            signedDate={signatureEvent.signedDate}
            bounds=".editor__page"
          />
        ) : (
          <UnSignedSignature
            assignedSignee={assignedSignee}
            position={properties.position}
            dimensions={properties.dimensions}
            signatureId={signatureBoxId}
            key={signatureBoxId}
            handleClick={() => setSelectedSignature(signatureBoxId)}
            bounds=".editor__page"
          />
        )
      )}
    </div>
  );
};

export default EditorPageSignatures;
