import React, { useContext, useEffect, useState } from 'react';
import './DocumentBuilder.less';
import '../variables/variablesList.less';
import '../styles.less';
import { Layout } from 'antd';
import { useSocketClient } from 'providers/SocketContext';
import FontLoader from 'components/font';
import useEditorRef from 'hooks/useEditorRef';
import { EditorSidePanel } from '../SidePanel';
import Froala from 'froala-editor';
import { FeatureFlags } from 'utils/featureFlags';
import useFeatureFlag from 'hooks/useFeatureFlag';
import Sections from '../Sections';
import { SignaturesContext } from 'components/editor/providers/SignaturesProvider';
import { DndProvider } from 'react-dnd';
import { BlocksContentCollection, BlocksMetadataCollection, resetState, setInitialState } from '../grid/reduxStore/editorSlice';
import { useAppDispatch } from '../grid/reduxStore/Store';
import { GridLoadType } from '../grid/reduxStore/saveHandlers';
import { convertGridResponseToGridContentBlocks } from '../GridDndEditor/gridHelper';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { CONFIG } from 'config';
import { dragDropManager } from '../dndContext';
import EditorMenu from '../EditorMenu/EditorMenu';
import { Block } from '../GridDndEditor/models/Block.model';
import { gridDefaultGreaterZAxis, gridDefaultHeight, gridDefaultWidth, gridDefaultXAxis, gridDefaultYAxis } from '../shared/gridConfig';
import { useBlockAddedHandler } from '../hooks/UseBlockAddedHandler';
import { DocumentSettingsProvider } from '../SidePanel/document-settings/DocumentDesignSettings/DocumentSettingsContext';
import { GridBlockType } from '../shared/gridBlockType';
import { SidePanelProvider } from '../SidePanel/content';

import { BlockEvents } from '../../../services/socket/SocketEvents';
import { BlockStyleSettingsContext } from '../SidePanel/content/designSettings/AdvancedSpacing/BlockSettingsProvider';
import { ThemeProvider } from '@mui/material/styles';
import ProposifyTheme from '../../../muiTheme/PyTheme';
import CssBaseline from '@mui/material/CssBaseline';

interface DocumentBuilderProps {
  documentId: string;
}

const DocumentBuilder: React.FC<DocumentBuilderProps> = ({ documentId }) => {
  const socketClient = useSocketClient();
  const [editorConfig, setEditorConfig] = useState<any>(undefined);
  const [isConfigLoaded, setIsConfigLoaded] = useState<boolean>(false);
  const { setSignaturesOnMount } = useContext(SignaturesContext);
  const { editorRef, setRef } = useEditorRef();
  const [isSignatureLoaded, setIsSignatureLoaded] = useState<boolean>(false);
  const evaluatedFeatureFlags = useFeatureFlag([FeatureFlags.chatGPT]);
  const dispatch = useAppDispatch();
  const blockAddHandler = useBlockAddedHandler();
  const { loadBlockStyleSettings } = useContext(BlockStyleSettingsContext);

  const editorConfigHandler = (newConfig) => {
    setEditorConfig(newConfig);
    setIsConfigLoaded(true);
  };

  const gridLoadCallback = async ({ content: gridsData }: { content: GridLoadType[] }) => {
    if (gridsData.length > 0) {
      loadBlockStyleSettings(gridsData);
      const blocks = convertGridResponseToGridContentBlocks(gridsData);
      dispatch(
        setInitialState({
          documentId: documentId,
          blocksContent: blocks.blocksContent,
          blocksMetadata: blocks.blocksMetadata,
          blocksLayer: blocks.blocksLayer,
          editorConfig: blocks.editorConfig,
        })
      );
    } else {
      dispatch(
        setInitialState({
          documentId: documentId,
          blocksContent: {} as BlocksContentCollection,
          blocksMetadata: [] as BlocksMetadataCollection,
          blocksLayer: { greaterZIndexAvailable: 0, lowerZIndexAvailable: 0 },
          editorConfig: { currentDraggedBlock: null, maxHeightPage: 0 },
        })
      );

      const blockConfig: Block = {
        width: gridDefaultWidth,
        height: gridDefaultHeight,
        x: gridDefaultXAxis,
        y: gridDefaultYAxis,
        z: gridDefaultGreaterZAxis,
      };

      await blockAddHandler('', blockConfig, GridBlockType.TEXT);
    }
  };

  useEffect(() => {
    socketClient.getAllSignaturesOnMount((data) => {
      setSignaturesOnMount(data);
      setIsSignatureLoaded(true);
    });

    socketClient.connect();

    return () => {
      socketClient.disconnect();
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (socketClient.isConnected()) {
      socketClient.publish(BlockEvents.BLOCK_LOAD, null, gridLoadCallback);
    }
  }, [socketClient.isConnected()]);

  useEffect(() => {
    //ChatGPT Button
    if (evaluatedFeatureFlags[FeatureFlags.chatGPT]) {
      Froala.DefineIcon('chatGPT', { NAME: 'search', SVG_KEY: 'search' });
      Froala.RegisterCommand('chatGPT', {
        title: 'Ask ChatGPT',
        icon: 'chatGPT',
        focus: false,
        undo: false,
        refreshAfterCallback: false,
        callback: async function callback() {
          const data = {
            model: 'gpt-3.5-turbo',
            max_tokens: 256,
            temperature: 0,
            messages: [
              {
                role: 'system',
                content: 'You are a world class sales leader and you help write sales proposals',
              },
              {
                role: 'user',
                content: this.selection.text(),
              },
            ],
          };
          // Make the API call to ChatGPT
          const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${CONFIG.CHAT_GPT_KEY}`,
            },
            body: JSON.stringify(data),
          });
          const { choices } = await response.json();
          // replace the suggestion into selected text
          this.html.insert(choices[0].message.content);
        },
      });
    }
  }, [evaluatedFeatureFlags[FeatureFlags.chatGPT]]);

  return (
    <ThemeProvider theme={ProposifyTheme}>
      <CssBaseline />
      <div className="editor-header-wrapper">
        <EditorMenu documentId={documentId} />
        <FontLoader editorConfigHandler={editorConfigHandler} />
      </div>
      <DndProvider manager={dragDropManager}>
        <DocumentSettingsProvider>
          <SidePanelProvider>
            <Layout className="editor-document-builder">
              <Content className="editor-document-main-content">
                {isSignatureLoaded && isConfigLoaded && <Sections documentId={documentId} setRef={setRef} editorConfig={editorConfig} />}
              </Content>
              <Sider theme="light" width={''} className="editor-right-sider">
                <EditorSidePanel documentId={documentId} editorRef={editorRef} />
              </Sider>
            </Layout>
          </SidePanelProvider>
        </DocumentSettingsProvider>
      </DndProvider>
    </ThemeProvider>
  );
};

export default DocumentBuilder;
