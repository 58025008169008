import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { TextBlockSettings } from 'components/editor/grid/reduxStore/saveHandlers';
import FroalaTextbox from './FroalaTextbox';
import useBlockState from '../useBlockState';
import { DraggableGridBlockWrapper } from '../../DraggableGridBlock/DraggableGridBlockWrapper';
import { EditorConfig } from '../../../helpers/config';
import { RootState } from '../../../grid/reduxStore/Store';
import { useBlockContentChangedHandler } from '../../../hooks/UseBlockContentChangedHandler';
import { BlockStyleSettingsContext } from '../../../SidePanel/content/designSettings/AdvancedSpacing/BlockSettingsProvider';
import { useDraggableBlockManipulations } from '../../DraggableGridBlock/useDraggableBlockManipulations';
import { roundToNearestMultipleOfGridSize } from '../../gridHelper';
import { useBlockDimensionChangedHandler } from '../../../hooks/UseBlockDimensionChangedHandler';

interface GridTextBlockProps {
  documentId: string;
  blockId: string;
  configOptions?: EditorConfig;
  handleMouseDown?: (blockId: string) => void;
}
export const GridTextBlock: React.FC<GridTextBlockProps> = ({ documentId, blockId, configOptions }) => {
  const gridBlockContentChangedHandler = useBlockContentChangedHandler();
  const { handleOnMouseDown, isEditMode } = useBlockState(blockId);
  const blocksContent = useSelector((state: RootState) => {
    return state.gridBlockReducer.blocksContent;
  });
  const blockDimensionChangedHandler = useBlockDimensionChangedHandler();
  const { setDocumentHeight } = useDraggableBlockManipulations();

  const editorResizeHandler = async (editorId: string, textbox: DOMRectReadOnly) => {
    const resizedBlock = { ...blocksContent[editorId].blockConfig };
    const textboxRoundedHeight = roundToNearestMultipleOfGridSize(textbox.height);
    const wasDimensionChangedOrNull = (resizedBlock.height && textboxRoundedHeight > resizedBlock.height) || !resizedBlock.height;

    if (wasDimensionChangedOrNull) {
      const stateBeforeResizing = {
        widthPx: resizedBlock.width,
        heightPx: resizedBlock.height,
        yAxisPx: resizedBlock.y,
        xAxisPx: resizedBlock.x,
      };
      const stateAfterResizing = {
        widthPx: resizedBlock.width,
        heightPx: textboxRoundedHeight,
        yAxisPx: resizedBlock.y,
        xAxisPx: resizedBlock.x,
      };
      await blockDimensionChangedHandler(editorId, stateBeforeResizing, stateAfterResizing);

      resizedBlock.height = textboxRoundedHeight;
      setDocumentHeight(resizedBlock);
    }
  };

  const blockContent = useSelector((state: RootState) => {
    return state.gridBlockReducer.blocksContent[blockId];
  });
  const { getBlockStyle } = useContext(BlockStyleSettingsContext);

  const contentChangeHandler = (blockId: string, newContent: string) => {
    gridBlockContentChangedHandler(blockId, newContent);
  };
  const {
    paddingLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    backgroundColor,
    borderColor,
    borderLeft,
    borderRight,
    borderTop,
    borderBottom,
    borderRadius,
  } = getBlockStyle(blockId) as unknown as TextBlockSettings;
  const froalaTextStyle = {
    borderTop: `solid ${borderColor} ${borderTop}px`,
    borderBottom: `solid ${borderColor} ${borderBottom}px`,
    borderLeft: `solid ${borderColor} ${borderLeft}px`,
    borderRight: `solid ${borderColor} ${borderRight}px`,
    borderRadius: `${borderRadius}px`,
    backgroundColor: backgroundColor,
    paddingLeft: `${paddingLeft}px`,
    paddingTop: `${paddingTop}px`,
    paddingRight: `${paddingRight}px`,
    paddingBottom: `${paddingBottom}px`,
    height: '100%',
  };

  const sumBorderWidth = borderLeft + borderRight;

  return (
    <DraggableGridBlockWrapper
      key={`grid-text-${blockId}`}
      blockId={blockId}
      sumBorderWidth={sumBorderWidth}
      isEditMode={isEditMode}
      onMouseDown={(e) => handleOnMouseDown(e)}
    >
      <FroalaTextbox
        style={froalaTextStyle}
        documentId={documentId}
        key={blockId}
        blockId={blockId}
        configOptions={configOptions}
        documentContent={blockContent.content}
        editorResizeHandler={editorResizeHandler}
        contentChangeHandler={contentChangeHandler}
        isEditMode={isEditMode}
      />
    </DraggableGridBlockWrapper>
  );
};

GridTextBlock.displayName = 'GridTextBlock';
