import { UndoRedoManager } from '../../UndoRedo/UndoRedoManager';
import FroalaEditor from 'froala-editor';
import { UndoRedoCommand } from '../../UndoRedo/UndoRedoCommand';

type FunctionReturnVoid = () => void;
type FunctionReturnBoolean = () => boolean;
interface UndoRedoPluginType {
  _init: FunctionReturnVoid;
  undo: FunctionReturnVoid;
  redo: FunctionReturnVoid;
  canUndo: FunctionReturnBoolean;
  canRedo: FunctionReturnBoolean;
}

const undoRedoManager = UndoRedoManager.getUndoRedoManager();
class UndoRedoPlugin implements UndoRedoPluginType {
  private readonly editor: FroalaEditor;
  constructor(editor: FroalaEditor) {
    this.editor = editor;
  }

  _init(): void {
    const editor = this.editor;

    editor.events.on('contentChanged', function () {
      if (!editor.undo.canDo()) return true;
      const undoCallback = async (): Promise<boolean> => {
        try {
          editor.commands.undo();
        } catch (error) {
          return false;
        }

        return true;
      };
      const redoCallback = async (): Promise<boolean> => {
        try {
          editor.commands.redo();
        } catch (error) {
          return false;
        }
        return true;
      };
      undoRedoManager.pushUndoRedoCommands(new UndoRedoCommand(undoCallback, redoCallback));
    });
  }

  undo(): void {
    undoRedoManager.runUndoCommand();
  }

  redo(): void {
    undoRedoManager.runRedoCommand();
  }

  canUndo(): boolean {
    return !undoRedoManager.isUndoStackEmpty();
  }

  canRedo(): boolean {
    return !undoRedoManager.isRedoStackEmpty();
  }
}
export default UndoRedoPlugin;
