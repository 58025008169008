import React from 'react';
import { useParams } from 'react-router-dom';
import { EditorContentWrapper } from './editorContentWrapper';
import { NavigateToCinder } from 'components/navigate-to-cinder';
import { CINDER_URLS } from 'utils/cinder-urls';
import PageLoader from 'components/page-loader/page-loader';
import DocumentLockedModal from 'components/editor/modals/DocumentLockedModal';
import { useQuery } from 'react-query';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';

const EditorPage: React.FC = () => {
  const { documentId } = useParams<{ documentId: string }>();

  if (!documentId) {
    throw new Error('Document id missing');
  }

  /* Not using useGetDocumentByIdQuery hook because the key is being invalidated in other flows,
     making this component to re-render and it breaks the editor page */
  const {
    data: documentData,
    isFetching,
    isFetched,
    status: documentLoadingStatus,
  } = useQuery({
    queryKey: `editor_document_index_get_by_id_${documentId}_call`,
    queryFn: () => apiDocumentRepository.getDocumentById(documentId),
    enabled: !!documentId,
  });

  if (documentLoadingStatus === 'error') {
    return <NavigateToCinder path={CINDER_URLS.error} />;
  }

  return (
    <PageLoader isLoading={isFetching} isLoaded={isFetched}>
      {/* TODO update API to return contentId. At the moment they are the same on documents, so we can pass the documentId */}
      <EditorContentWrapper contentId={documentId}>
        <DocumentLockedModal isDocumentLocked={!!documentData?.isLocked} documentId={documentId} />
      </EditorContentWrapper>
    </PageLoader>
  );
};

export default EditorPage;
