import { FunctionComponent, useCallback } from 'react';
import { Modal, Paragraph, Icon, Heading } from 'components';
import { useTranslation } from 'react-i18next';
import { useGetDowngradeFeaturesList } from 'hooks/useGetDowngradeFeaturesList';
import { Row, Col, List } from 'antd';
import { MissingFeatureEntity } from 'services/entities/MissingFeatureEntity';
import { ApiPlanFeatureRepository } from 'services/repositories/implementations/ApiPlanFeatureRepository';
import { useMediaQuery } from 'hooks/useMediaQuery';
import goToCinder from 'utils/goToCinder';

interface DowngradeModalProps {
  isOpen: boolean;
  setModalOpen: (state: boolean) => any;
  currentPlanId: number;
  planFeatureRepository: ApiPlanFeatureRepository;
  planType: string;
}

const DowngradeModal: FunctionComponent<DowngradeModalProps> = ({
  planFeatureRepository,
  isOpen,
  setModalOpen,
  currentPlanId,
  planType,
}) => {
  const { t } = useTranslation();
  const teamPlanId = Number(process.env.REACT_APP_QUARTERLY_TEAM_PLAN_ID);
  const currentPlanFeatures = useGetDowngradeFeaturesList(planFeatureRepository, currentPlanId, isOpen);
  const newPlanFeatures = useGetDowngradeFeaturesList(planFeatureRepository, teamPlanId, isOpen);
  const { isMobile } = useMediaQuery();
  const title = t('settings.plan.downgrade_confirmation_title');

  const getMissingFeatures = useCallback((): MissingFeatureEntity[] => {
    const activeCurrentPlanFeatures = currentPlanFeatures.filter((feature) => feature.active);
    const inactiveNewPlanFeatures = newPlanFeatures.filter((feature) => !feature.active);

    return activeCurrentPlanFeatures.filter((activeCurrentPlanFeature) =>
      inactiveNewPlanFeatures.some((inactiveNewPlanFeature) => activeCurrentPlanFeature.name === inactiveNewPlanFeature.name)
    );
  }, [currentPlanFeatures, newPlanFeatures]);

  const missingFeatures = getMissingFeatures();

  const handleDowngradeRedirect = () => {
    switch (planType) {
      case 'basic':
        goToCinder('settings/plan/basic');
        break;
      case 'team':
        goToCinder('settings/plan/team');
        break;
    }
  };

  return (
    <Modal
      isVisible={isOpen}
      handleOk={handleDowngradeRedirect}
      handleCancel={() => setModalOpen(false)}
      affirmText={t('settings.plan.button_confirm_downgrade_plan_label')}
      cancelText={t('settings.plan.button_cancel_downgrade_plan_label')}
      title={title}
      affirmButtonVariant="negative"
    >
      <Row>
        <Col flex={1}>
          {isMobile ? <Heading level={3}>{title}</Heading> : null}
          <Paragraph size="md">{t('settings.plan.downgrade_confirmation_description')}</Paragraph>
        </Col>
      </Row>
      <br />
      <Row>
        <Col flex={1}>
          <List
            dataSource={missingFeatures}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Icon name="IcoCircleCloseX" />}
                  description={<Paragraph size="sm">{item.onDowngradeDescription}</Paragraph>}
                  title={<Heading level={5}>{item.name}</Heading>}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default DowngradeModal;
