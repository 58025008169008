import React from 'react';
import { Menu, Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { Props } from './interface';
import { usePermissionsContextValue } from 'providers/PermissionsProvider';
import { getNavigationItemsWithPermissions } from 'services/permissions/helpers/NavigationItemsPermissions';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { desktopNavigationItems } from 'utils/navigationItems';
import MobileMenu from './mobileMenu';

import './styles.less';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureFlags } from 'utils/featureFlags';

const { Sider } = Layout;

const Sidebar: React.FC<Props> = ({ mode }) => {
  const { userPermissions } = usePermissionsContextValue();

  const location = useLocation();

  const itemsWithPermissions = getNavigationItemsWithPermissions(desktopNavigationItems, userPermissions);

  const defaultSelectedKey = ['' + itemsWithPermissions.findIndex((item) => item.href === location.pathname)];

  const { isDesktop, isSmallerThanDesktop } = useMediaQuery();
  const isDesktopView = mode === 'fixed' && isDesktop;
  const isMobileView = mode === 'collapsible' && isSmallerThanDesktop;

  const evaluateV3Flags = useFeatureFlag([FeatureFlags.editorV3, FeatureFlags.documentsV3]);
  const hasAnyFlag = evaluateV3Flags[FeatureFlags.editorV3] || evaluateV3Flags[FeatureFlags.documentsV3];

  const conditionallyUpdateNavigationItem = itemsWithPermissions.map((navigationItem) => {
    if (navigationItem.id === 'documents-list') {
      return {
        ...navigationItem,
        href: '/pipeline',
      };
    }
    return navigationItem;
  });

  if (isDesktopView) {
    return (
      <Sider width={56} defaultCollapsed collapsed collapsedWidth={56} data-testid="app-sidebar" className="app-sidebar">
        <Menu
          items={(hasAnyFlag ? conditionallyUpdateNavigationItem : itemsWithPermissions).map(
            ({ component, title, href, external, hidden }, i) => ({
              icon: React.createElement(component),
              label: external ? <a href={href}></a> : <Link to={href}></Link>,
              title,
              hidden,
              key: i,
              'data-testid': i,
            })
          )}
          theme="dark"
          defaultSelectedKeys={defaultSelectedKey}
          mode="inline"
        />
      </Sider>
    );
  } else if (isMobileView) {
    return <MobileMenu />;
  } else {
    return <></>;
  }
};

export default Sidebar;
