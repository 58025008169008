import { configureStore } from '@reduxjs/toolkit';
import { gridBlockReducer } from './editorSlice';
import { usedVariablesReducer } from './usedVariablesSlice';
import { useDispatch } from 'react-redux';

export const rootStore = configureStore({
  reducer: { gridBlockReducer, usedVariablesReducer },
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof rootStore.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof rootStore.getState>;
