import React, { useState, useRef, KeyboardEvent, useContext, useEffect } from 'react';
import { Col, Layout, Row } from 'antd';
import DOMPurify from 'dompurify';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { ProfileActions, HelpButton, Paragraph, Button, Icon, Tooltip, openNotification } from 'components';
import PriceInput from 'components/priceInput';
import { Endpoints } from 'utils/endpoints';
import useUpdateDocumentMutation from 'hooks/useUpdateDocumentMutation';
import { DocumentShareModal } from 'components/editor/document-share-modal';
import { Header } from 'components/editor/document-share-modal/header';
import { DocumentShare } from 'components/editor/document-share';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { ErrorCode } from 'services/socket/SocketEvents';
import { DocumentStatus, UpdateDocumentPayload } from 'services/repositories/interfaces/DocumentRepository';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import './styles.less';
import SaveStatusText from '../shared/components/SaveStatus/SaveStatus';
import { SaveStatusContext } from '../providers/SaveStatusProvider';
import { EditorToolbar } from './EditorToolbar';

interface EditorMenuProps {
  documentId: string;
}

const sanitizeTitle = (value: string) => {
  return DOMPurify.sanitize(value).substring(0, 99);
};

const blurOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    event.currentTarget.blur();
  }
};

export default function EditorMenu({ documentId }: EditorMenuProps) {
  const [title, setTitle] = useState<string>('Document Title');
  const [documentPrice, setDocumentPrice] = useState<number | null>(0);
  const previewURL = Endpoints.getDocumentPreviewPage(documentId);
  const handlePreviewRedirect = () => window.open(previewURL, '_blank');
  const documentTitleRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
  const { mutate } = useUpdateDocumentMutation();
  const { t } = useTranslation();
  const { data: documentData } = useGetDocumentByIdQuery(documentId);
  const { setSaveStatus } = useContext(SaveStatusContext);

  useEffect(() => {
    if (documentData?.title) {
      setTitle(documentData.title);
    }
    if (documentData?.price) {
      setDocumentPrice(documentData.price);
    }
  }, [documentData]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const sanitizedValue = sanitizeTitle(event.target.value);
    setTitle(sanitizedValue);
  }

  const updateDocumentProperties = (payload: Omit<UpdateDocumentPayload, 'documentId'>) => {
    setSaveStatus(DocumentSaveStatus.SAVING);
    mutate(
      { documentId, ...payload },
      {
        onSuccess: () => {
          setSaveStatus(DocumentSaveStatus.SAVED);
        },

        onError: (error: unknown) => {
          if (error instanceof AxiosError && error.response?.status === 423) {
            setSaveStatus(DocumentSaveStatus.NOT_SAVED, ErrorCode.LOCKED);
          } else {
            openNotification({
              title: t('error'),
              description: t('document.menu.update_error'),
              type: 'error',
            });
          }
        },
      }
    );
  };

  const updateTitle = () => {
    const sanitizedTitle = sanitizeTitle(title);
    if (!sanitizedTitle.trim()) {
      const defaultTitle = t('editor.default_document_title');
      setTitle(defaultTitle);
      persistTitle({ title: defaultTitle });
    } else {
      persistTitle({ title: sanitizedTitle });
    }
  };

  const persistTitle = (titlePayload: object) => {
    updateDocumentProperties(titlePayload);
  };

  const updatePrice = () => {
    updateDocumentProperties({ price: documentPrice });
  };

  const [isVisible, setIsVisible] = useState(false);

  const closeShareModal = () => setIsVisible(false);
  const openShareModal = () => setIsVisible(true);

  const documentTitleWithCompanyName = [title, documentData?.company?.name].filter(Boolean).join(' - ');

  return (
    <Layout.Header className="editor-header">
      <Row justify="space-between" align="top" className="editor-menu-container">
        <Col className="editor-menu-container__column--left document-details">
          <Tooltip title={title} placement="bottom">
            <input
              ref={documentTitleRef}
              onKeyDown={blurOnEnter}
              className="document-title"
              maxLength={100}
              placeholder={t('editor.default_document_title')}
              onChange={onChange}
              onBlur={updateTitle}
              value={title}
            />
          </Tooltip>
          <div className="document-status">
            <Paragraph size="sm">{documentData?.status || DocumentStatus.Draft}</Paragraph>
            $<PriceInput value={documentPrice} onChange={setDocumentPrice} onBlur={updatePrice} />
            <Paragraph size="sm" className="timestamp-ml-xs">
              <SaveStatusText></SaveStatusText>
            </Paragraph>
          </div>
        </Col>
        <Col className="editor-menu-container__column--right">
          <div className="buttons-container">
            <Button
              icon={<Icon name="IcoPreview" title={t('preview')} />}
              variant="neutral"
              type="default"
              className="preview-button"
              onClick={handlePreviewRedirect}
            >
              {t('preview')}
            </Button>
            <Button
              icon={<Icon name="IcoSend" title={t('share_document')} />}
              variant="positive"
              type="primary"
              className="send-button"
              onClick={openShareModal}
            >
              {t('share_document')}
            </Button>
            <DocumentShareModal
              open={isVisible}
              onCancel={closeShareModal}
              title={<Header title={t('share.title')} description={documentTitleWithCompanyName} />}
              destroyOnClose
            >
              <DocumentShare documentId={documentId} onSuccess={closeShareModal} />
            </DocumentShareModal>
          </div>
          <HelpButton />
          <ProfileActions />
        </Col>
      </Row>
      <EditorToolbar />
    </Layout.Header>
  );
}
