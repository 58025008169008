import { useTranslation } from 'react-i18next';
import { PageHeader } from 'components';
import { ManageAPIClients } from './blocks/manage-api-clients';

export const ConnectAPIPage = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader title={t('settings.connect_api.title')} paragraph={t('settings.connect_api.subtitle')} />
      <ManageAPIClients />
    </>
  );
};
