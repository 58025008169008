import { saveConfigWithSocket } from '../grid/reduxStore/saveHandlers';
import { updateGridDimensionConfig } from '../grid/reduxStore/editorSlice';
import { useAppDispatch } from '../grid/reduxStore/Store';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useSocketClient } from '../../../providers/SocketContext';
import { useDocumentLock } from '../modals/useDocumentLock';
import { useContext } from 'react';
import { SaveStatusContext } from '../providers/SaveStatusProvider';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { UndoRedoManager } from '../UndoRedo/UndoRedoManager';
import { UndoRedoCommandForBlocks } from '../UndoRedo/UndoRedoCommand';
import { BlockEvents, DefaultSocketResponseType } from '../../../services/socket/SocketEvents';
import {
  BlockPositionChangedHandlerWithoutUndoRedoType,
  useBlockPositionChangedHandlerWithoutUndoRedo,
} from './UseBlockPositionChangedHandler';

type DimensionPositionStateChangedType = {
  widthPx: number;
  heightPx: number;
  yAxisPx: number;
  xAxisPx: number;
};

type DimensionStateChangedType = {
  widthPx: number;
  heightPx: number;
};

export type BlockDimensionChangedHandlerType = (blockId: string, blockState: DimensionStateChangedType) => Promise<void>;

const useUndoRedoDimensionChangedHandler = (
  blockDimensionChangedHandlerWithoutUndoRedo: BlockDimensionChangedHandlerType,
  blockPositionChangedHandlerWithoutUndoRedo: BlockPositionChangedHandlerWithoutUndoRedoType
) => {
  const undoRedoManager = UndoRedoManager.getUndoRedoManager();

  return (
    blockId: string,
    stateBeforeResizing: DimensionPositionStateChangedType,
    stateAfterResizing: DimensionPositionStateChangedType
  ) => {
    const wasPositionChanged =
      stateBeforeResizing.yAxisPx !== stateAfterResizing.yAxisPx || stateAfterResizing.xAxisPx !== stateAfterResizing.xAxisPx;
    const undoCallback = async (undoRedoCommand: UndoRedoCommandForBlocks): Promise<boolean> => {
      try {
        const mappedBlockId = undoRedoCommand.getMappedBlockId(blockId);
        const { widthPx, heightPx, yAxisPx, xAxisPx } = stateBeforeResizing;
        await blockDimensionChangedHandlerWithoutUndoRedo(mappedBlockId, { widthPx, heightPx });
        if (wasPositionChanged) {
          await blockPositionChangedHandlerWithoutUndoRedo(mappedBlockId, { xAxisPx, yAxisPx });
        }
      } catch (error) {
        return false;
      }
      return true;
    };
    const redoCallback = async (undoRedoCommand: UndoRedoCommandForBlocks): Promise<boolean> => {
      try {
        const mappedBlockId = undoRedoCommand.getMappedBlockId(blockId);
        const { widthPx, heightPx, yAxisPx, xAxisPx } = stateAfterResizing;
        await blockDimensionChangedHandlerWithoutUndoRedo(mappedBlockId, { widthPx, heightPx });
        if (wasPositionChanged) {
          await blockPositionChangedHandlerWithoutUndoRedo(mappedBlockId, { xAxisPx, yAxisPx });
        }
      } catch (error) {
        return false;
      }
      return true;
    };
    undoRedoManager.pushUndoRedoCommands(new UndoRedoCommandForBlocks(blockId, undoCallback, redoCallback));
  };
};

function useBlockDimensionChangedHandlerForSocket(): BlockDimensionChangedHandlerType {
  const dispatch = useAppDispatch();
  const socketClient = useSocketClient();
  const { data } = useCurrentUser();
  const userId = data.id;
  const { checkDocumentLockStatus } = useDocumentLock();
  const { updateSaveStatus } = useContext(SaveStatusContext);

  const blockDimensionChangedCallback = (blockDimensionChangedSocketResponse: DefaultSocketResponseType) => {
    updateSaveStatus(blockDimensionChangedSocketResponse);
    checkDocumentLockStatus(blockDimensionChangedSocketResponse.errorCode);
  };

  return async (blockId: string, { widthPx, heightPx }: DimensionStateChangedType) => {
    await dispatch(
      saveConfigWithSocket({
        blockId,
        width: widthPx,
        height: heightPx,
        userId,
        eventType: BlockEvents.BLOCK_DIMENSION_CHANGED,
        socketClient,
        callback: blockDimensionChangedCallback,
      })
    ).unwrap();
  };
}

export function useBlockDimensionChangedHandlerWithoutUndoRedo(): BlockDimensionChangedHandlerType {
  const dispatch = useAppDispatch();

  const blockDimensionChangedHandlerForSocket = useBlockDimensionChangedHandlerForSocket();

  return async (blockId: string, { widthPx, heightPx }: DimensionStateChangedType): Promise<void> => {
    const payload = {
      blockId,
      width: widthPx,
      height: heightPx,
    };
    dispatch(updateGridDimensionConfig(payload));
    return await blockDimensionChangedHandlerForSocket(blockId, { widthPx, heightPx });
  };
}

export function useBlockDimensionChangedHandler() {
  const { setSaveStatus } = useContext(SaveStatusContext);
  const blockPositionChangedHandlerWithoutUndoRedo = useBlockPositionChangedHandlerWithoutUndoRedo();
  const blockDimensionChangedHandlerWithoutUndoRedo = useBlockDimensionChangedHandlerWithoutUndoRedo();

  const undoRedoDimensionChangeHandler = useUndoRedoDimensionChangedHandler(
    blockDimensionChangedHandlerWithoutUndoRedo,
    blockPositionChangedHandlerWithoutUndoRedo
  );

  return async (
    blockId: string,
    stateBeforeResizing: DimensionPositionStateChangedType,
    stateAfterResizing: DimensionPositionStateChangedType
  ): Promise<void> => {
    setSaveStatus(DocumentSaveStatus.SAVING);
    if (stateBeforeResizing.yAxisPx !== stateAfterResizing.yAxisPx || stateBeforeResizing.xAxisPx !== stateAfterResizing.xAxisPx) {
      const { xAxisPx, yAxisPx } = stateAfterResizing;
      await blockPositionChangedHandlerWithoutUndoRedo(blockId, { xAxisPx, yAxisPx });
    }

    const blockDimensionChangedSocketResponse = await blockDimensionChangedHandlerWithoutUndoRedo(blockId, stateAfterResizing);

    undoRedoDimensionChangeHandler(blockId, stateBeforeResizing, stateAfterResizing);
    return blockDimensionChangedSocketResponse;
  };
}
