import React, { useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { EditorConfig } from '../helpers/config';
import DocumentLockedModal from '../modals/DocumentLockedModal';
import Canvas from './Canvas';
import { SectionToolbar } from './SectionToolbar';
import { useDocumentLock } from '../modals/useDocumentLock';

interface EditorContentProps {
  documentId: string;
  setRef: (node: FroalaEditor | null) => void;
  editorConfig: EditorConfig;
  toolbarButtons?: any;
}

interface Section {
  id: number;
  ref: React.RefObject<HTMLDivElement>;
}

const EditorContent: React.FC<EditorContentProps> = ({ documentId, editorConfig }) => {
  const [sections, setSections] = useState<Section[]>([{ id: 1, ref: React.createRef<HTMLDivElement>() }]);
  const { isDocumentLocked } = useDocumentLock();

  const handleAddButtonOnClick = () => {
    const lastId = sections.length > 0 ? sections[sections.length - 1].id : 0;
    const newId = lastId + 1;
    const newSection = { id: newId, ref: React.createRef<HTMLDivElement>() };

    setSections((prevSections) => [...prevSections, newSection]);
  };

  const handleDeleteButtonOnClick = (canvasId: number) => {
    setSections((prevSections) => prevSections.filter((section) => section.id !== canvasId));
  };

  const enableDelete = sections.length > 1;

  return (
    <>
      {sections.map((section) => (
        <Canvas
          enableDelete={enableDelete}
          addButtonOnClick={handleAddButtonOnClick}
          canvasId={section.id}
          key={section.id}
          configOptions={editorConfig}
          deleteButtonOnClick={handleDeleteButtonOnClick}
          documentId={documentId}
          gridRef={section.ref}
        />
      ))}

      <SectionToolbar enableDelete={false} addButtonOnClick={handleAddButtonOnClick} />

      <DocumentLockedModal isDocumentLocked={isDocumentLocked} documentId={documentId} />
    </>
  );
};

export default EditorContent;
