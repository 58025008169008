import { Dimensions } from '../shared/interfaces';

export const dimensions: Dimensions = {
  width: 116,
  height: 56,
};

export const position = {
  x: 500,
  y: 300,
};

export const draggedOffset = {
  x: 130,
  y: 165,
};
